import { Injectable } from '@angular/core';
import { ClaimParty, ClaimsApiService, GetClaimPath, GetClaimResp, GetCsrClaimCountsPath, ReassignClaimsRqst } from '@xpo-ltl-2.0/sdk-claims';
import {
  ClaimSearchRecord,
  ElasticSearchApiService,
  NmfcItemSearchRecord,
  XrtAttributeFilter,
  XrtClaimSearchFilter,
  XrtClaimSearchResp,
  XrtClaimSearchRqst,
  XrtCustomerSearchFilter,
  XrtCustomerSearchResp,
  XrtCustomerSearchRqst,
  XrtNmfcItemSearchFilter,
  XrtNmfcItemSearchResp,
  XrtNmfcItemSearchRqst,
  XrtSearchQueryHeader,
  XrtSortExpression,
} from '@xpo-ltl/sdk-elasticsearch';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClaimsLookupFilters } from '../../classes/claims-lookup-filters';

class XrtArrayAttributeFilter extends XrtAttributeFilter {
  equals: any;
}

@Injectable({ providedIn: 'root' })
export class ClaimsService {
  private filteredNmfcOptionsSubject = new BehaviorSubject<NmfcItemSearchRecord[]>([]);
  public filteredNmfcOptions$ = this.filteredNmfcOptionsSubject.asObservable();

  private lastNmfcResponse: NmfcItemSearchRecord[];

  private claimsListSubject = new BehaviorSubject<ClaimSearchRecord[]>([]);
  public claimsList$ = this.claimsListSubject.asObservable();

  constructor(private claimsApiService: ClaimsApiService, private elasticSearchApiService: ElasticSearchApiService) { }

  public searchXrt(value: string): Observable<any> {
    return Observable.create(observer => {
      if (value && value.trim().length > 0) {
        let foundItem;

        if (this.lastNmfcResponse && this.lastNmfcResponse.length > 0) {
          foundItem = this.lastNmfcResponse.find(element => element.id === value);
        }
        if (foundItem) {
          this.filteredNmfcOptionsSubject.next([foundItem]);
          observer.next(foundItem);
          observer.complete();
        } else {
          const xrtSearchRequest = new XrtNmfcItemSearchRqst();
          const filter = new XrtNmfcItemSearchFilter();
          const header = new XrtSearchQueryHeader();
          filter.id = new XrtAttributeFilter();
          filter.id.startsWith = value;
          xrtSearchRequest.header = header;
          xrtSearchRequest.filter = filter;

          this.elasticSearchApiService
            .xrtNmfcItemSearch(xrtSearchRequest, {
              loadingOverlayEnabled: false,
            })
            .pipe(take(1))
            .subscribe((response: XrtNmfcItemSearchResp) => {
              const result = response.result;
              this.lastNmfcResponse = result;
              this.filteredNmfcOptionsSubject.next(result);
              observer.next(result.find(item => item.id === value));
              observer.complete();
            });
        }
      } else {
        if (this.filteredNmfcOptions$) {
          this.filteredNmfcOptionsSubject.next([]);
        }
        observer.complete();
      }
    });
  }

  /** XRT search for Claims based on some filters */
  public searchXrtClaims(filters: ClaimsLookupFilters, page: number, pageSize: number, sortExpressions: XrtSortExpression[]): Observable<XrtClaimSearchResp> {
    return new Observable(observer => {
      if (filters) {
        const xrtClaimsSearchRequest = new XrtClaimSearchRqst();
        const xrtFilters = new XrtClaimSearchFilter();
        const xrtHeader = new XrtSearchQueryHeader();
        xrtHeader.page = page;
        xrtHeader.pageSize = pageSize;
        let xrtFilter: XrtAttributeFilter;
        if (filters.proNbr) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.equals = filters.proNbr;
          xrtFilters.proNbr = xrtFilter;
        }
        if (filters.claimNbr) {
          const xrtArrayFilter = new XrtArrayAttributeFilter();
          xrtArrayFilter.equals = [];
          const claimNbrs = filters.claimNbr.split(',');
          _.each(claimNbrs, claimNbr => {
            xrtArrayFilter.equals.push(claimNbr.trim());
          });
          xrtFilters.claimId = xrtArrayFilter;
        }
        if (filters.claimantClaimNbr) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.equals = filters.claimantClaimNbr;
          xrtFilters.claimantRefNbr = xrtFilter;
        }
        if (filters.checkNbr) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.equals = filters.checkNbr;
          xrtFilters.checkNbr.push(xrtFilter);
        }
        if (filters.claimantName) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.query = filters.claimantName;
          xrtFilters.claimantName = xrtFilter;
        }
        if (filters.examinerName) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.query = filters.examinerName;
          xrtFilters.examinedBy = xrtFilter;
        }
        if (filters.currentApprover) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.query = filters.currentApprover;
          xrtFilters.assigneeEmployeeName = xrtFilter;
        }
        if (filters.claimStatusList) {
          const xrtArrayFilter = new XrtArrayAttributeFilter();
          xrtArrayFilter.equals = filters.claimStatusList;
          xrtFilters.internalStatusCd = xrtArrayFilter;
        }
        if (filters.filedDateFrom || filters.filedDateTo) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.min = filters.filedDateFrom ? dayjs(filters.filedDateFrom).format('MM/DD/YYYY') : undefined;
          xrtFilter.max = filters.filedDateTo ? dayjs(filters.filedDateTo).format('MM/DD/YYYY') : undefined;
          xrtFilters.filingDate = xrtFilter;
        }
        if (filters.pickupDateFrom || filters.pickupDateTo) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.min = filters.pickupDateFrom ? dayjs(filters.pickupDateFrom).format('MM/DD/YYYY') : undefined;
          xrtFilter.max = filters.pickupDateTo ? dayjs(filters.pickupDateTo).format('MM/DD/YYYY') : undefined;
          xrtFilters.pickupDate = xrtFilter;
        }
        if (filters.claimAmountFrom || filters.claimAmountTo) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.min = filters.claimAmountFrom;
          xrtFilter.max = filters.claimAmountTo;
          xrtFilters.claimedAmount = xrtFilter;
        }
        if (filters.payoutAmountFrom || filters.payoutAmountTo) {
          xrtFilter = new XrtAttributeFilter();
          xrtFilter.min = filters.payoutAmountFrom;
          xrtFilter.max = filters.payoutAmountTo;
          xrtFilters.totalPaymentAmount = xrtFilter;
        }

        // if no filters, get the latest 100 claims sorted by filed date in descending
        const isEmpty = Object.values(filters).every(x => !x);

        if (isEmpty && !sortExpressions.length) {
          const sortExpr = new XrtSortExpression();
          sortExpr.Column = 'filingDate';
          sortExpr.IsDescendingSort = true;
          xrtHeader.sortExpressions = [sortExpr];
        }

        if (sortExpressions && sortExpressions.length) {
          xrtHeader.sortExpressions = sortExpressions;
        }
        xrtClaimsSearchRequest.header = xrtHeader;
        xrtClaimsSearchRequest.filter = xrtFilters;

        this.elasticSearchApiService
          .xrtClaimSearch(xrtClaimsSearchRequest, {
            loadingOverlayEnabled: true,
          })
          .pipe(take(1))
          .subscribe((response: XrtClaimSearchResp) => {
            observer.next(response);
            observer.complete();
          });
      } else {
        this.claimsListSubject.next(undefined);
        observer.next(undefined);
        observer.complete();
      }
    });
  }

  public searchXrtCustomer(party: ClaimParty): Observable<XrtCustomerSearchResp> {
    const filter = new XrtCustomerSearchFilter();
    if (party.name1) {
      filter.name1 = new XrtAttributeFilter();
      filter.name1.startsWith = party.name1;
    }
    if (party.name2) {
      filter.name2 = new XrtAttributeFilter();
      filter.name2.startsWith = party.name2;
    }
    if (party.addr1) {
      filter.addressLine = new XrtAttributeFilter();
      filter.addressLine.startsWith = party.addr1;
    }
    if (party.cityName) {
      filter.cityName = new XrtAttributeFilter();
      filter.cityName.startsWith = party.cityName;
    }
    if (party.stateCd) {
      filter.stateCd = new XrtAttributeFilter();
      filter.stateCd.startsWith = party.stateCd;
    }
    if (party.postalCd) {
      filter.postalCd = new XrtAttributeFilter();
      filter.postalCd.startsWith = party.postalCd;
    }

    const request = new XrtCustomerSearchRqst();
    request.header = new XrtSearchQueryHeader();
    request.filter = filter;

    return this.elasticSearchApiService.xrtCustomerSearch(request);
  }

  getClaim(claimId): Observable<any> {
    return Observable.create(observer => {
      const claimRequest = new GetClaimPath();
      claimRequest.claimId = claimId;
      this.claimsApiService
        .getClaim(claimRequest)
        .pipe(take(1))
        .subscribe(
          (response: GetClaimResp) => {
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.next(undefined);
            observer.complete();
          }
        );
    });
  }

  getCSRClaimCounts(csrEmployeeId) {
    const csrClaimCountsRqst = new GetCsrClaimCountsPath();
    csrClaimCountsRqst.csrEmployeeId = csrEmployeeId;
    return this.claimsApiService.getCsrClaimCounts(csrClaimCountsRqst);
  }

  public reassignClaims(claimIds: string[], toEmployeeId: string, reason = ''): Observable<void> {
    const request = new ReassignClaimsRqst();
    request.claimIds = claimIds;
    request.reassignToEmployeeId = toEmployeeId;
    request.reassignReasonTxt = reason && reason.length !== 0 ? reason : undefined;

    return this.claimsApiService.reassignClaims(request);
  }
}
