import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Contact } from '@xpo-ltl-2.0/sdk-claims';
import Fuse from 'fuse.js';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClaimantContactFormNames, CommonRegex } from '../../enums';
import { xpoBadCharactersValidator } from '../../core/validators/non-use-characters.validators';

enum ContactSearch {
  Search = 'search',
}
@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsListComponent implements OnInit, OnDestroy {
  private fuseSearch: Fuse<Contact>;
  private readonly fuseSearchKeys: Array<keyof Contact> = ['firstName', 'lastName', 'email', 'phoneNbr', 'phoneExt', 'faxNbr'];
  private fuseSearchOptions: Fuse.FuseOptions<Contact>;

  private dataSourceSubject: BehaviorSubject<MatTableDataSource<Contact>> = new BehaviorSubject(undefined);
  public dataSource$ = this.dataSourceSubject.asObservable();
  private unsubscriber: Unsubscriber = new Unsubscriber();

  public formGroup: UntypedFormGroup;
  public contacts: Contact[];
  public selectedContact: Contact;
  public readonly ContactSearch = ContactSearch;
  public readonly columnsToDisplay = ['select', 'firstName', 'lastName', 'email', 'phoneNbr', 'phoneExt', 'faxNbr'];

  @ViewChildren(MatRadioButton) matchRadioButtons;

  /**
   * Creates a new instance, used for dependency injection, sets up fuse
   *
   * @param data data passed by the dialogService
   * @param matDialogRef the reference to the dialog
   * @param formBuilder used to build forms
   * @param phoneNumberPipe used to format phone numbers
   */
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private matDialogRef: MatDialogRef<ContactsListComponent>, private formBuilder: UntypedFormBuilder) {
    this.contacts = this.data && this.data.contacts ? this.data.contacts : undefined;
    this.fuseSearchOptions = {
      shouldSort: true,
      findAllMatches: true,
      tokenize: true,
      threshold: 0.0,
      location: 0,
      distance: 25,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: this.fuseSearchKeys,
    };
    this.fuseSearch = new Fuse(this.contacts as ReadonlyArray<Contact>, this.fuseSearchOptions);
  }

  /**
   * Runs on init, setup form and init watchers
   */
  ngOnInit(): void {
    this.createSearchForm();
    this.initWatchers();
  }

  /**
   * Runs on init, sets up data watchers
   */
  private initWatchers(): void {
    this.setupSearchWatcher();
  }

  /**
   * Runs on destroy, clean up ongoing observables
   */
  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.dataSourceSubject.unsubscribe();
  }

  /**
   * Create search form to filter by
   */
  private createSearchForm(): void {
    this.formGroup = this.formBuilder.group({
      [ContactSearch.Search]: [undefined, [xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS)]],
    });

    // Load the table with data
    if (this.contacts) {
      this.loadTable(this.contacts);
    }
  }

  /**
   * Cancel clicked, close dialog, return nothing
   */
  public handleCancelClicked(): void {
    this.matDialogRef.close();
  }

  /**
   * Match clicked, close dialog, return selected contact
   */
  public handleMatchClicked(): void {
    this.matDialogRef.close(this.selectedContact);
  }

  public handleSelectRow(row: Contact, index: number) {
    const radioButton = <MatRadioButton>this.matchRadioButtons.toArray()[index];
    radioButton.checked = true;
    this.handleSelectContact(row);
  }

  /**
   * Contact selected, set selection
   *
   * @param contact
   */
  public handleSelectContact(contact: Contact): void {
    this.selectedContact = contact;
  }

  /**
   * Puts the contact into the mat table
   *
   * @param contacts contact data to be loaded
   */
  private loadTable(contacts: Contact[]): void {
    this.dataSourceSubject.next(new MatTableDataSource(contacts));
  }

  /**
   * Subscribes to passed field to watch for changes and runs search
   *
   * @param controlName the form name to watch on
   */
  private setupSearchWatcher(): void {
    this.formGroup
      .get(ContactSearch.Search)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe(value => {
        if (value && this.fuseSearch && this.contacts && this.contacts.length) {
          const searchResults = this.fuseSearch.search(value) as Contact[];
          this.loadTable(searchResults);
        } else if (this.contacts && this.contacts.length) {
          // No value passed so return the whole list
          this.loadTable(this.contacts);
        }
      });
  }
}
