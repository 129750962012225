<app-notification></app-notification>
<div class="app-container">
  <div class="app-container-stretch-box">

    <xpo-shell class="app-container__nav-header">
      <xpo-header-app-name>Edge</xpo-header-app-name>
      <xpo-header-sub-app-name>Claims</xpo-header-sub-app-name>
      <xpo-header-navigation>
        <button routerLink="dashboard" routerLinkActive="xpo-selected">Claims Lookup</button>
      </xpo-header-navigation>
      
      <xpo-header-actions>

        <xpo-header-utilities-actions>
          <xpo-ltl-global-search style="width: auto !important; padding: 0 4px;border-right: 1px solid  #d8d8d8;" [globalSearchOptions]="globalSearchOptions"></xpo-ltl-global-search>
        </xpo-header-utilities-actions>

        <div class="app-container__roleSwitcher"
          *ngIf="constants?.displayMultipleRole">
          <button mat-icon-button matTooltip="Switch Roles" (click)="handleRoleSwitchClicked()">
            <mat-icon>account_circle</mat-icon>
          </button>
          <div class="app-container__roleSwitcher-currentRole">
              <span *ngIf="constants.userRole !== userRoleEnum.Csr ;else upperCase">
                  {{constants.userRole | titlecase}}
              </span>
              <ng-template #upperCase>
                  {{constants.userRole | uppercase}}</ng-template>
                </div>
        </div>
        <xpo-header-utilities-actions>
          <xpo-account-popover *ngIf="accountPopoverProfileConfig" [config]="accountPopoverProfileConfig">
          </xpo-account-popover>
        </xpo-header-utilities-actions>


        
      </xpo-header-actions>
     

      <app-navigation-bar *ngIf="showNavbar"></app-navigation-bar>
       <xpo-shell-sidebar-actions class="app-Container-sidebar-actions contentPowerBy">
        <div>
          <a href="#" id="jiraFeedbackTrigger">Feedback</a>
        </div>
        <div>
          <a href="#" id="releaseNotesClaims">Release Notes</a>
        </div>
        <div>
          <a href="#" id="trainingClaims">Training</a>
        </div>
      </xpo-shell-sidebar-actions> 
      <div #navBarPanelContainer class="app-container__content" [class.app-container__content-non-prod]="!isProduction"
        [class.app-container__content-no-navbar]="!(navigationBarService.isShown$ | async)">
        <div class="app-container__content-container">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer class="app-footer"></app-footer>
    </xpo-shell>
  </div>
</div>
