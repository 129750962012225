import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { GetClaimResp } from '@xpo-ltl-2.0/sdk-claims';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RouterParams, RouterUriComponents } from '../enums';
import { ClaimsRegistrationService } from '../services/claims-registration/claims-registration.service';
import { ClaimsService } from '../services/claims/claims.service';
import * as _ from 'lodash';

@Injectable()
export class ClaimRouteGuard implements CanActivate {
  constructor(private claimsService: ClaimsService, private router: Router, private claimsRegistrationService: ClaimsRegistrationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const claimId = (route.params[RouterParams.CLAIM_ID] as string).toUpperCase();
    if (!!claimId) {
      return this.claimsService.getClaim(claimId).pipe(
        map((claim: GetClaimResp) => {
          if (claim) {
            this.claimsRegistrationService.claim = claim;
            return true;
          } else {
            // if no claim found, always return to dashboard
            this.router.navigate([RouterUriComponents.DASHBOARD]);
            return false;
          }
        }),
        catchError(() => {
          this.router.navigate([RouterUriComponents.DASHBOARD]);
          return of(false);
        })
      );
    } else {
      // no claim, so make sure the form is reset
      this.claimsRegistrationService.reset();
    }
    return true;
  }
}
