import { AsyncValidatorFn, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, Validator } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Directive } from '@angular/core';
import { ServiceCenterCacheService } from '../services/service-center-cache.service';
import { first } from 'rxjs/internal/operators';

export function sicValidatorFunction(serviceCenterCacheService: ServiceCenterCacheService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!control || !control.value || control.value.length < 3) {
      return of(null);
    }

    return serviceCenterCacheService.request({ sicCd: control.value }).pipe(
      first(),
      map(
        response => {
          return response?.componentCd ? null : { invalidLocation: true };
        },
        errors => {
          return { invalidLocation: true };
        }
      ),
      catchError(error => {
        return of({ invalidLocation: true });
      })
    );
  };
}

@Directive({
  selector: '[sicValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: SicValidatorDirective, multi: true }],
})
export class SicValidatorDirective implements Validator {
  constructor(private serviceCenterCache: ServiceCenterCacheService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return sicValidatorFunction(this.serviceCenterCache)(control);
  }
}
