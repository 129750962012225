import { UnitOfWeightMeasureType } from '../enums';

const poundsPerKilogram = 0.453592;

export class UnitConversion {
  /**
   * Rounds the given value to the given number of decimal places. Defaults to two.
   */

  public static round(value: number, decimals: number = 2): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }

  /**
   * Converts the submitted value based on 'from' and 'to' params
   */

  public static convert(from: string, to: string, value: number): number {
    let result = value;

    switch (from) {
      case UnitOfWeightMeasureType.KGS: {
        switch (to) {
          case UnitOfWeightMeasureType.KGS: {
            break;
          }
          case UnitOfWeightMeasureType.LBS: {
            result = this.kilogramsToPounds(value);
            break;
          }
        }

        break;
      }
      case UnitOfWeightMeasureType.LBS: {
        switch (to) {
          case UnitOfWeightMeasureType.LBS: {
            break;
          }
          case UnitOfWeightMeasureType.KGS: {
            result = this.poundsToKilograms(value);
            break;
          }
        }
        break;
      }
    }

    return result;
  }

  /**
   * Converts the submitted value from pounds to kilograms
   */

  public static poundsToKilograms(value: number): number {
    return this.round(value * poundsPerKilogram);
  }

  /**
   * Converts the submitted value from kilograms to pounds
   */

  public static kilogramsToPounds(value: number): number {
    return this.round(value / poundsPerKilogram);
  }
}
