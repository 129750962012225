import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Commodity, UpsertClaimRqst } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd, CommodityClassCdHelper, CommodityClaimTypeCd, CommodityConditionTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import {
  ClaimsRegistrationFormNames,
  CommodityLineItemFormNames,
  CommodityLineItemsFormNames,
  PaymentState,
  UnitOfWeightMeasureType,
} from '../../../../enums';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import {
  claimAmountValidator,
  claimTypeRequiredFieldsValidator,
  minimumCommodityCountValidator,
} from '../../../../validators';
import { claimTypeGreaterThanZeroFieldValidator } from '../../../../validators/claim-type-greater-than-zero-field-validator.directive';
import { commodityClassNmfcCdValidator } from '../../../../validators/commodity-class-nmfc-cd-validator.directive';
import { CurrencyPipe } from '@angular/common';
import { FormUtils } from '../../../../classes/form-utils.class';
import { requireDamageOrShortageValidator } from '../../../../validators/claim-type-require-damage-or-shortage-validator.directive';
import { XpoLtlDynamicValidator } from '@xpo-ltl/ngx-ltl';
import { ClaimCommodityLineItemMaxLengths } from 'src/app/enums/FormMaxLengths/claim-commodity-line-item-max-lengths.enum';

export class RegistrationCommoditiesFormBuilder {
  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [CommodityLineItemsFormNames.LineItemClaimAmount]: [0],
        [CommodityLineItemsFormNames.TotalLiabilityAndFreight]: [0],
        [CommodityLineItemsFormNames.Commodities]: formBuilder.array(
          [],
          [
            minimumCommodityCountValidator(1),
            claimAmountValidator(
              claimsRegistrationService.claimsRegistrationFormGroup,
              claimsRegistrationService.claim
            ),
            requireDamageOrShortageValidator(),
          ]
        ),
      });
      observer.next(formGroup);
      observer.complete();
    });
  }

  static createCommodityLineItem(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService,
    currencyPipe: CurrencyPipe
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [CommodityLineItemFormNames.ClaimTypeCd]: [CommodityClaimTypeCd.DAMAGE, [Validators.required]],
        [CommodityLineItemFormNames.ConditionTypeCd]: [
          CommodityConditionTypeCd.NEW,
          [claimTypeRequiredFieldsValidator([CommodityClaimTypeCd.DAMAGE, CommodityClaimTypeCd.SHORTAGE])],
        ],
        [CommodityLineItemFormNames.DescriptionTxt]: [
          undefined,
          [
            Validators.maxLength(ClaimCommodityLineItemMaxLengths.Description),
            claimTypeRequiredFieldsValidator([
              CommodityClaimTypeCd.DAMAGE,
              CommodityClaimTypeCd.SHORTAGE,
              CommodityClaimTypeCd.MISCELLANEOUS,
              CommodityClaimTypeCd.CREDIT,
            ]),
          ],
        ],
        [CommodityLineItemFormNames.ClaimedAmount]: [0],
        [CommodityLineItemFormNames.ClaimedAmountDisplayOnly]: [currencyPipe.transform(0)],
        [CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmount]: [0],
        [CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmountDisplayOnly]: [currencyPipe.transform(0)],
        [CommodityLineItemFormNames.PricingAgreementDollarPerLbNoneInd]: [false],
        [CommodityLineItemFormNames.CommodityWeight]: [
          0,
          [
            claimTypeRequiredFieldsValidator(
              [CommodityClaimTypeCd.DAMAGE, CommodityClaimTypeCd.SHORTAGE],
              () =>
                !!claimsRegistrationService.claim &&
                claimsRegistrationService.paymentEditState === PaymentState.PendingApprovalFormReview &&
                !this.checkAdditionalCharges(formGroup)
            ),
          ],
        ],
        [CommodityLineItemFormNames.CommodityWeightUnitOfMeasure]: [UnitOfWeightMeasureType.LBS],
        [CommodityLineItemFormNames.PiecesCount]: [0],
        [CommodityLineItemFormNames.ModelOrPartNbr]: [undefined],
        [CommodityLineItemFormNames.ClassCd]: [undefined],
        [CommodityLineItemFormNames.FakClass]: [undefined],
        [CommodityLineItemFormNames.FakInd]: [false],
        [CommodityLineItemFormNames.NmfcItemNbr]: [undefined],
        [CommodityLineItemFormNames.NmfcItemNbrSetClass]: [undefined],
        [CommodityLineItemFormNames.SequenceNbr]: [undefined],
        [CommodityLineItemFormNames.LiabilityLimit]: [0],
        [CommodityLineItemFormNames.ListActionCd]: [undefined],
      });

      // if credit type, disable minimum validator:
      formGroup.get(CommodityLineItemFormNames.ClaimedAmount).setValidators(
        XpoLtlDynamicValidator(() => {
          return (
            FormUtils.getNestedValue(formGroup, CommodityLineItemFormNames.ClaimTypeCd) !== CommodityClaimTypeCd.CREDIT
          );
        }, Validators.min(0.01))
      );

      formGroup
        .get(CommodityLineItemFormNames.ClassCd)
        .setValidators([
          Validators.maxLength(ClaimCommodityLineItemMaxLengths.ClassCd),
          commodityClassNmfcCdValidator(formGroup.get(CommodityLineItemFormNames.NmfcItemNbrSetClass)),
          claimTypeRequiredFieldsValidator(
            [CommodityClaimTypeCd.DAMAGE, CommodityClaimTypeCd.SHORTAGE],
            () =>
              !!claimsRegistrationService.claim &&
              claimsRegistrationService.paymentEditState === PaymentState.PendingApprovalFormReview &&
              !this.checkAdditionalCharges(formGroup)
          ),
        ]);

      formGroup
        .get(CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmount)
        .setValidators([
          claimTypeGreaterThanZeroFieldValidator(
            [CommodityClaimTypeCd.DAMAGE, CommodityClaimTypeCd.SHORTAGE],
            () =>
              !!claimsRegistrationService.claim &&
              claimsRegistrationService.paymentEditState === PaymentState.PendingApprovalFormReview &&
              !FormUtils.getNestedValue(formGroup, CommodityLineItemFormNames.PricingAgreementDollarPerLbNoneInd)
          ),
        ]);

      observer.next(formGroup);
      observer.complete();
    });
  }

  static checkAdditionalCharges(formGroup: UntypedFormGroup): boolean {
    return (
      _.chain(formGroup.get(CommodityLineItemFormNames.DescriptionTxt).value)
        .toLower()
        .trim()
        .value() === 'additional charges'
    );
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      if (!formGroup.get(CommodityLineItemFormNames.ClaimTypeCd).value) {
        formGroup.get(CommodityLineItemFormNames.ClaimTypeCd).setValue(CommodityClaimTypeCd.DAMAGE);
      }
      if (!formGroup.get(CommodityLineItemFormNames.ConditionTypeCd).value) {
        formGroup.get(CommodityLineItemFormNames.ConditionTypeCd).setValue(CommodityConditionTypeCd.NEW);
      }
      if (!formGroup.get(CommodityLineItemFormNames.CommodityWeightUnitOfMeasure).value) {
        formGroup.get(CommodityLineItemFormNames.CommodityWeightUnitOfMeasure).setValue(UnitOfWeightMeasureType.LBS);
      }
      if (!formGroup.get(CommodityLineItemFormNames.ListActionCd).value) {
        formGroup.get(CommodityLineItemFormNames.ListActionCd).setValue(ActionCd.ADD);
      }
      if (!formGroup.get(CommodityLineItemFormNames.ClaimedAmountDisplayOnly).value) {
        formGroup.get(CommodityLineItemFormNames.ClaimedAmountDisplayOnly).setValue('$0.00');
      }
      if (!formGroup.get(CommodityLineItemFormNames.ClaimedAmount).value) {
        formGroup.get(CommodityLineItemFormNames.ClaimedAmount).setValue(0);
      }

      if (!formGroup.get(CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmountDisplayOnly).value) {
        formGroup.get(CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmountDisplayOnly).setValue('$0.00');
      }

      if (!formGroup.get(CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmount).value) {
        formGroup.get(CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmount).setValue(0);
      }

      if (!formGroup.get(CommodityLineItemFormNames.PricingAgreementDollarPerLbNoneInd).value) {
        formGroup.get(CommodityLineItemFormNames.PricingAgreementDollarPerLbNoneInd).setValue(false);
      }

      if (!formGroup.get(CommodityLineItemFormNames.LiabilityLimit).value) {
        formGroup.get(CommodityLineItemFormNames.LiabilityLimit).setValue(0);
      }

      formGroup.get(CommodityLineItemFormNames.ClaimedAmountDisplayOnly).markAsUntouched({ onlySelf: true });
    }
  }

  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any): UpsertClaimRqst {
    if (!upsertClaimRqst.commodity) {
      upsertClaimRqst.commodity = [];
    }

    upsertClaimRqst.claim.maxLiabilityAmount = _.round(
      +_.get(
        rawFormGroup,
        `${ClaimsRegistrationFormNames.Commodities}.${CommodityLineItemsFormNames.TotalLiabilityAndFreight}`,
        0
      ),
      2
    );

    const commoditiesFormArray: any[] = _.get(
      rawFormGroup,
      `${ClaimsRegistrationFormNames.Commodities}.${CommodityLineItemsFormNames.Commodities}`,
      undefined
    );
    if (commoditiesFormArray && commoditiesFormArray.length > 0) {
      commoditiesFormArray.forEach((commodityFormGroup) => {
        const commodity = new Commodity();
        commodity.claimTypeCd = _.get(commodityFormGroup, CommodityLineItemFormNames.ClaimTypeCd, undefined);
        commodity.conditionTypeCd = _.get(commodityFormGroup, CommodityLineItemFormNames.ConditionTypeCd, undefined);
        commodity.descriptionTxt = _.get(commodityFormGroup, CommodityLineItemFormNames.DescriptionTxt, undefined);
        commodity.claimedAmount = _.get(commodityFormGroup, CommodityLineItemFormNames.ClaimedAmount, undefined);
        commodity.modelOrPartNbr = _.get(commodityFormGroup, CommodityLineItemFormNames.ModelOrPartNbr, undefined);
        commodity.commodityWeightUnitOfMeasure = _.get(
          commodityFormGroup,
          CommodityLineItemFormNames.CommodityWeightUnitOfMeasure,
          undefined
        );
        commodity.piecesCount = _.get(commodityFormGroup, CommodityLineItemFormNames.PiecesCount, 0);
        commodity.commodityWeight = _.get(commodityFormGroup, CommodityLineItemFormNames.CommodityWeight, 0);
        commodity.classCd = CommodityClassCdHelper.toEnum(
          `Clss${_.get(commodityFormGroup, CommodityLineItemFormNames.ClassCd, undefined)}`
        );
        commodity.nmfcItemNbr = _.get(commodityFormGroup, CommodityLineItemFormNames.NmfcItemNbr, undefined);
        commodity.fakInd = !!_.get(commodityFormGroup, CommodityLineItemFormNames.FakClass, false);
        commodity.sequenceNbr = _.get(commodityFormGroup, CommodityLineItemFormNames.SequenceNbr, undefined);
        commodity.listActionCd = _.get(commodityFormGroup, CommodityLineItemFormNames.ListActionCd, undefined);
        commodity.prcingAgreementDollarPerLbAmount = _.get(
          commodityFormGroup,
          CommodityLineItemFormNames.PrcingAgreementDollarPerLbAmount,
          0
        );
        commodity.pricingAgreementDollarPerLbNoneInd = _.get(
          commodityFormGroup,
          CommodityLineItemFormNames.PricingAgreementDollarPerLbNoneInd,
          false
        );
        upsertClaimRqst.commodity.push(commodity);
      });
    }
    return upsertClaimRqst;
  }
}
