import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { RelatedShipment, UpsertClaimRqst } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd, ClaimRelatedShipmentTypeCd, ProStatusCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { FormUtils } from '../../../../classes/form-utils.class';
import { ErrorWarningValidationType } from '../../../../enums/error-warning-validation-type.enum';
import { ClaimsRegistrationFormNames, RelatedProFormNames, RelatedProsFormNames } from '../../../../enums/FormControlNames';
import { ClaimsForShipmentCacheService } from '../../../../services/claims-for-shipments-cache/claims-for-shipment-cache.service';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { availableProValidator } from '../../../../validators/available-pro-validator.directive';
import { duplicateClaimValidator } from '../../../../validators/duplicate-claim-validator.directive';
import { proValidator } from '../../../../validators/pro-validator.directive';
import { uniqueProValidator } from '../../../../validators/unique-pro-validator.directive';

export class RegistrationRelatedProsFormBuilder {
  public static create(formBuilder: UntypedFormBuilder): Observable<AbstractControl> {
    return Observable.create(observer => {
      const formGroup = formBuilder.group({
        [RelatedProsFormNames.RelatedPros]: formBuilder.array([]),
      });

      observer.next(formGroup);
      observer.complete();
    });
  }

  public static createRelatedProControls(
    formBuilder: UntypedFormBuilder,
    formatService: FormatValidationService,
    claimsRegistrationService: ClaimsRegistrationService,
    conditioningService: ConditioningService,
    claimsForShipmentCacheService: ClaimsForShipmentCacheService
  ): Observable<AbstractControl[]> {
    return Observable.create(observer => {
      const proCtrlObservables = [];

      for (let i = 0; i < 10; i++) {
        proCtrlObservables.push(this.createRelatedProFG(formBuilder, formatService, claimsRegistrationService, conditioningService, claimsForShipmentCacheService));
      }

      forkJoin(proCtrlObservables).subscribe(relatedProFGArray => {
        observer.next(relatedProFGArray);
        observer.complete();
      });
    });
  }

  public static createRelatedProFG(
    formBuilder: UntypedFormBuilder,
    formatService: FormatValidationService,
    claimsRegistrationService: ClaimsRegistrationService,
    conditioningService: ConditioningService,
    claimsForShipmentCacheService: ClaimsForShipmentCacheService,
    relatedPro?: RelatedShipment
  ): Observable<AbstractControl> {
    return Observable.create(observer => {
      const relatedProFG = formBuilder.group({
        [RelatedProFormNames.ClaimId]: [''],
        [RelatedProFormNames.ShipmentInstId]: [''],
        [RelatedProFormNames.ProNbr]: ['', [proValidator(formatService, conditioningService), uniqueProValidator(claimsRegistrationService, conditioningService)]],
        [RelatedProFormNames.OriginalProNumber]: [''],
        [RelatedProFormNames.PickupDate]: [''],
        [RelatedProFormNames.RelatedShipmenTypeCd]: [''],
        [RelatedProFormNames.ParentRelatedShipmentId]: [''],
        [RelatedProFormNames.ListActionCd]: [ActionCd.ADD],
        [RelatedProFormNames.AuditInfo]: [''],
        [RelatedProFormNames.ClaimEventId]: [''],
        [RelatedProFormNames.RecordVersionNbr]: [''],
        [RelatedProFormNames.CorrelationId]: [''],
        [RelatedProFormNames.ShipmentStatus]: [''],
      });

      relatedProFG
        .get(RelatedProFormNames.ShipmentInstId)
        .setAsyncValidators([duplicateClaimValidator(claimsForShipmentCacheService, relatedProFG.get(RelatedProFormNames.ProNbr), claimsRegistrationService, formatService)]);

      relatedProFG.get(RelatedProFormNames.ShipmentStatus).setValidators([
        availableProValidator(
          ErrorWarningValidationType.Error,
          () => {
            const shipmentStatus = FormUtils.getNestedValue(relatedProFG, RelatedProFormNames.ShipmentStatus);
            return FormUtils.getNestedValue(relatedProFG, RelatedProFormNames.ProNbr) && !shipmentStatus ? ProStatusCd.AVAILABLE : (shipmentStatus as string);
          },
          () => {
            if (!!claimsRegistrationService.shipment) {
              const billClassCd = _.get(claimsRegistrationService.shipment, 'shipment.billClassCd');
              return claimsRegistrationService.isNonRevenueBillClass(billClassCd);
            }
            return false;
          }
        ),
      ]);

      observer.next(relatedProFG);
      observer.complete();
    });
  }

  public static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.enable();
      formGroup.setValue({
        [RelatedProFormNames.ProNbr]: '',
        [RelatedProFormNames.PickupDate]: '',
        [RelatedProFormNames.ShipmentInstId]: '',
        [RelatedProFormNames.ShipmentStatus]: '',
        [RelatedProFormNames.ClaimId]: '',
        [RelatedProFormNames.RelatedShipmenTypeCd]: '',
        [RelatedProFormNames.OriginalProNumber]: '',
        [RelatedProFormNames.ParentRelatedShipmentId]: '',
        [RelatedProFormNames.AuditInfo]: '',
        [RelatedProFormNames.ClaimEventId]: '',
        [RelatedProFormNames.RecordVersionNbr]: '',
        [RelatedProFormNames.CorrelationId]: '',
        [RelatedProFormNames.ListActionCd]: ActionCd.ADD,
      });
    }
  }

  public static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any, claimsRegistrationService: ClaimsRegistrationService, conditioningService: ConditioningService): UpsertClaimRqst {
    if (!upsertClaimRqst.relatedShipments) {
      upsertClaimRqst.relatedShipments = [];
    }

    const formRelatedShipments = _.get(rawFormGroup, `${ClaimsRegistrationFormNames.RelatedPros}.${RelatedProsFormNames.RelatedPros}`, undefined);
    const relatedShipmentsFromClaim = _.get(claimsRegistrationService.claim, 'relatedShipments', []);
    const getMatchingFormShipment = (claimRelatedShipment: RelatedShipment) => {
      return _.find(formRelatedShipments, formRelatedShipment => {
        return _.get(formRelatedShipment, RelatedProFormNames.ShipmentInstId) === claimRelatedShipment.shipmentInstId;
      });
    };

    // Need to reconcile deleted related shipments from claim with current form
    // If not present, update shipment action cd to delete and add to upsert request
    // If deleted and reentered, update reentered shipment form group with claim shipment information

    _.forEach(relatedShipmentsFromClaim, (claimRelatedShipment: RelatedShipment) => {
      const matchingFormShipment = getMatchingFormShipment(claimRelatedShipment);
      if (!matchingFormShipment) {
        claimRelatedShipment.listActionCd = ActionCd.DELETE;
        upsertClaimRqst.relatedShipments.push(claimRelatedShipment as RelatedShipment);
      } else if (matchingFormShipment && matchingFormShipment.listActionCd === ActionCd.ADD) {
        _.assign(matchingFormShipment, claimRelatedShipment);
      }
    });

    formRelatedShipments.forEach(relatedShipment => {
      const newRelatedShipment = new RelatedShipment();
      const relatedProNbr = _.get(relatedShipment, RelatedProFormNames.ProNbr, undefined);
      const listActionCd = _.get(relatedShipment, RelatedProFormNames.ListActionCd, undefined);

      if (relatedProNbr && (listActionCd === ActionCd.ADD || listActionCd === ActionCd.NO_ACTION)) {
        newRelatedShipment.proNbr = relatedProNbr;
        newRelatedShipment.claimId = _.get(relatedShipment, RelatedProFormNames.ClaimId, undefined);
        newRelatedShipment.shipmentInstId = _.get(relatedShipment, RelatedProFormNames.ShipmentInstId, undefined);
        newRelatedShipment.pickupDate = _.get(relatedShipment, RelatedProFormNames.PickupDate, undefined);
        newRelatedShipment.relatedShipmenTypeCd = _.get(relatedShipment, RelatedProFormNames.RelatedShipmenTypeCd, ClaimRelatedShipmentTypeCd.MANUALLY_RELATED);
        newRelatedShipment.listActionCd = _.defaultTo(_.get(relatedShipment, RelatedProFormNames.ListActionCd), ActionCd.ADD) || ActionCd.ADD;
        newRelatedShipment.auditInfo = _.get(relatedShipment, RelatedProFormNames.AuditInfo, undefined);
        newRelatedShipment.claimEventId = _.get(relatedShipment, RelatedProFormNames.ClaimEventId, undefined);
        newRelatedShipment.recordVersionNbr = _.get(relatedShipment, RelatedProFormNames.RecordVersionNbr, undefined);
        newRelatedShipment.correlationId = _.get(relatedShipment, RelatedProFormNames.CorrelationId, undefined);

        upsertClaimRqst.relatedShipments.push(newRelatedShipment);
      }
    });

    return upsertClaimRqst;
  }
}
