import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ProStatusCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { ErrorWarningValidationType } from '../enums/error-warning-validation-type.enum';
import { ProValidationErrorWarningType } from '../enums/pro-validation-error-warning-type.enum';

export function availableProValidator(validationType: ErrorWarningValidationType, proStatusFn = () => '', isNonRevenue: Function): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control) {
      return null;
    }

    if (!control.value) {
      _.unset(control, `warnings[${ProValidationErrorWarningType.UnbilledPro}]`);
      return null;
    }

    if (validationType === ErrorWarningValidationType.Warning) {
      if ((isAvailablePro(proStatusFn()) || isAvailablePro(control.value)) && !isNonRevenue()) {
        _.set(control, `warnings`, { ...control['warnings'], [ProValidationErrorWarningType.UnbilledPro]: true });
      } else {
        _.unset(control, `warnings[${ProValidationErrorWarningType.UnbilledPro}]`);
      }
    } else if (validationType === ErrorWarningValidationType.Error && (isAvailablePro(proStatusFn()) || isAvailablePro(control.value))) {
      return { [ProValidationErrorWarningType.UnbilledPro]: true };
    }

    return null;
  };
}

function isAvailablePro(statusCd): boolean {
  const availableStatusCodes = [
    ProStatusCd.ASSIGNED_TO_EBILL,
    ProStatusCd.AVAILABLE,
    ProStatusCd.IN_USE_BY_EBILL,
    ProStatusCd.NO_STICKER_AVAILABLE,
    ProStatusCd.RECEIVED_ON_FILE_BUT_NOT_BILLED,
    ProStatusCd.RECEIVED_PURGED_MAYBE_ON_FBA,
  ];

  return _.some(availableStatusCodes, cd => cd === statusCd);
}
