import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { ClaimsApiService, ListExaminerClaimsQuery, ListExaminerClaimsResp } from '@xpo-ltl-2.0/sdk-claims';
import * as _ from 'lodash';
import { AssignedClaimsCacheParams } from '../../interfaces/assigned-claims-cache-params.interface';
import { ApiRequest } from '@xpo-ltl/data-api';
import { ListInfo } from '@xpo-ltl/sdk-common';

@Injectable()
export class ListExaminerClaimsCacheService extends XpoLtlBaseCacheService<AssignedClaimsCacheParams, ListExaminerClaimsResp> {
  constructor(private claimsApiService: ClaimsApiService) {
    super();
  }

  protected getKeyFromParams(params: AssignedClaimsCacheParams): string {
    return `${params.index}`;
  }

  protected requestData(params: AssignedClaimsCacheParams): Observable<ListExaminerClaimsResp> {
    const request = new ListExaminerClaimsQuery();
    _.assign(request, _.pick(params, _.keys(request)));
    request.examinerEmployeeId = params.employeeId;
    request.listInfo = new ListInfo();
    request.listInfo.startAt = params.startAt;
    request.listInfo.numberOfRows = params.numberOfRows;
    request.claimInternalStatusCd = params.claimInternalStatusCd;
    request.filterByUpdatesInd = params.filterByUpdatesInd;
    request.listInfo.sortFields = [{sortByFieldName: params.sortByFieldName.toString(), sortOrder: params.sortOrder.toString()}];

    return this.claimsApiService.listExaminerClaims(request, { ...ApiRequest.defaultDataOptions, toastOnError: false });
  }
}
