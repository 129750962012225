import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { ClaimParty } from '@xpo-ltl-2.0/sdk-claims';
import * as _ from 'lodash';
import { CountryPickerService, ICountry } from 'ngx-country-picker';
import { Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, startWith, take, takeUntil, debounceTime, filter } from 'rxjs/operators';
import { FormUtils } from '../../../../classes/form-utils.class';
import { AddressEntryFormNames } from '../../../../enums';
import { isUsOrCanada } from '../../../../validators/state-code-validator.directive';
import { AddressEntryFormBuilder } from './address-entry.form-builder';
import { XpoAngularUtilsService } from '../../../../core/services/xpo-angular-utils.service';
import { ClaimPartyMaxLengths } from 'src/app/enums/FormMaxLengths/claim-party-max-lengths.enum';
import { ErrorStateMatcher } from 'src/app/classes/error-state-matcher';

export interface AddressData extends ClaimParty {
  claimPartyId?: number; // set for Matched addresses
}

export interface AddressFormData {
  [AddressEntryFormNames.Name]: string;
  [AddressEntryFormNames.Attention]: string;
  [AddressEntryFormNames.Address1]: string;
  [AddressEntryFormNames.Address2]: string;
  [AddressEntryFormNames.City]: string;
  [AddressEntryFormNames.State]: string;
  [AddressEntryFormNames.Zip]: string;
  [AddressEntryFormNames.Country]: ICountry;
  [AddressEntryFormNames.CountryDisplayOnly]: string;
}

@Component({
  selector: 'app-address-entry',
  templateUrl: './address-entry.component.html',
  styleUrls: ['./address-entry.component.scss'],
})
export class AddressEntryComponent implements OnDestroy, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() editMode: boolean;
  @Input() isPayee: boolean;
  @Output() valueChanges: EventEmitter<void> = new EventEmitter<void>();

  private unsubscriber: Unsubscriber = new Unsubscriber();
  private skipNextZip = false;

  public countries$: Observable<ICountry[]>;
  private countryNames: string[] = [];
  public filteredCountryNames$: Observable<string[]>;

  public countryDisplayOnlyControl: UntypedFormControl;
  public postalCdControl: UntypedFormControl;
  private pristineForm: AddressFormData;

  public readonly AddressEntryFormNames = AddressEntryFormNames;
  public readonly ClaimPartyMaxLengths = ClaimPartyMaxLengths;
  public readonly preventChars = ['{', '}', '[', ']', '^', '~'];
  public readonly stateInputAllowedChars = /[a-zA-Z -]/;

  // Binds formGroup error to field
  public stateErrorStateMatcher = new ErrorStateMatcher(
    () => this.formGroup.hasError('maxStateCode') || this.formGroup.hasError('hasSpace')
  );

  // Reads the addess data from the passed FormData and returns it as AddressData
  public static toAddressDataFromFormData(rawGroup: AddressFormData): AddressData {
    const zip = _.toString(_.get(rawGroup, AddressEntryFormNames.Zip, '')).split('-');
    const icountry = _.get(rawGroup, AddressEntryFormNames.Country);
    const countryCd = _.get(icountry, 'cca2', '');

    const addressData = {
      name1: _.get(rawGroup, AddressEntryFormNames.Name),
      name2: _.get(rawGroup, AddressEntryFormNames.Attention, '').toUpperCase(),
      addr1: _.get(rawGroup, AddressEntryFormNames.Address1),
      addr2: _.get(rawGroup, AddressEntryFormNames.Address2, '').toUpperCase(),
      cityName: _.get(rawGroup, AddressEntryFormNames.City),
      stateCd: _.get(rawGroup, AddressEntryFormNames.State),
      countryCd: countryCd,
      postalCd: _.toString(_.nth(zip, 0)),
      postalCdExt: _.toString(_.nth(zip, 1)),
    };
    return addressData;
  }

  public static toAsEnteredAddressDataFromFormData(rawGroup: AddressFormData): AddressData {
    const zip = _.toString(_.get(rawGroup, AddressEntryFormNames.Zip, '')).split('-');

    const addressData = {
      name1: _.get(rawGroup, AddressEntryFormNames.Name),
      name2: _.get(rawGroup, AddressEntryFormNames.Attention, '').toUpperCase(),
      addr1: _.get(rawGroup, AddressEntryFormNames.Address1),
      addr2: _.get(rawGroup, AddressEntryFormNames.Address2, '').toUpperCase(),
      cityName: _.get(rawGroup, AddressEntryFormNames.City),
      stateCd: _.get(rawGroup, AddressEntryFormNames.State),
      countryCd: _.get(rawGroup, AddressEntryFormNames.Country) as any,
      postalCd: _.toString(_.nth(zip, 0)),
      postalCdExt: _.toString(_.nth(zip, 1)),
    };
    return addressData;
  }

  public get stateMaxLength(): number {
    // TODO - should not pass in the whole form group herre! need to refactor the function
    if (isUsOrCanada(this.formGroup)) {
      return 2;
    } else {
      return 50;
    }
  }

  constructor(
    private countryPicker: CountryPickerService,
    private formatValidationService: FormatValidationService,
    private xpoUtils: XpoAngularUtilsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.countries$ = this.countryPicker.getCountries();
    // get a list of all known country names for autocomplete
    this.countries$.pipe(take(1)).subscribe((countries) => {
      this.countryNames = _.map(countries, (country: ICountry) => {
        return _.get(country, 'name.common', '');
      });
      this.countryNames.sort();
      const index = this.countryNames.indexOf('United States');
      if (index !== -1) {
        const usa = this.countryNames[index];
        this.countryNames.splice(index, 1);
        this.countryNames = [usa, ...this.countryNames];
      }
    });
  }

  public ngOnDestroy() {
    this.unsubscriber.complete();
  }

  public ngAfterViewInit() {
    this.initWatchers();
  }

  // Return the Form contents as AddressData
  public toAddressData(): AddressData {
    const rawGroup: AddressFormData = this.formGroup.getRawValue();
    return AddressEntryComponent.toAddressDataFromFormData(rawGroup);
  }

  // matching currently returns "   " for zip2 in some cases. need to protect against accidentally setting zip2
  public getFullZip(zip1: string, zip2: string, xpoUtils: XpoAngularUtilsService): string {
    const zip1Formatted = zip1 ? zip1.trim() : zip1;
    const zip2Formatted = zip2 ? zip2.trim() : zip2;
    return _.toString(xpoUtils.concatenateZips(zip1Formatted, zip2Formatted));
  }

  // Sets the Form to the passed Address
  public setFromAddressData(address: AddressData) {
    this.findCountry(_.get(address, 'countryCd')).subscribe((icountry) => {
      const fullZip = this.getFullZip(_.get(address, 'postalCd'), _.get(address, 'postalCdExt'), this.xpoUtils);
      const stateOrCountrySubDivisionCd = _.get(address, 'stateCd', '') || _.get(address, 'countrySubdivisionNm', '');
      const formData: AddressFormData = {
        [AddressEntryFormNames.Name]:
          _.get(address, 'name1') === null ? this.pristineForm[AddressEntryFormNames.Name] : _.get(address, 'name1'),
        [AddressEntryFormNames.Attention]:
          _.get(address, 'name2') === null
            ? this.pristineForm[AddressEntryFormNames.Attention]
            : _.get(address, 'name2'),
        [AddressEntryFormNames.Address1]:
          _.get(address, 'addr1') === null
            ? this.pristineForm[AddressEntryFormNames.Address1]
            : _.get(address, 'addr1'),
        [AddressEntryFormNames.Address2]:
          _.get(address, 'addr2') === null
            ? this.pristineForm[AddressEntryFormNames.Address2]
            : _.get(address, 'addr2'),
        [AddressEntryFormNames.City]:
          _.get(address, 'cityName', '') === null
            ? this.pristineForm[AddressEntryFormNames.City]
            : _.get(address, 'cityName'),
        [AddressEntryFormNames.State]: stateOrCountrySubDivisionCd,
        [AddressEntryFormNames.Zip]: fullZip,
        [AddressEntryFormNames.Country]: icountry,
        [AddressEntryFormNames.CountryDisplayOnly]: _.get(icountry, 'name.common', ''),
      };

      this.setFromFormData(formData);
    });
  }

  // Set the Form to the passed FormData
  public setFromFormData(formData: AddressFormData) {
    this.pristineForm = formData;
    this.skipNextZip = true;
    FormUtils.setValue(this.formGroup, formData, { emitEvent: true });

    FormUtils.markAsTouchedAndDirty(this.formGroup);
    this.changeDetectorRef.markForCheck();
  }

  private initWatchers() {
    this.countryDisplayOnlyControl = this.formGroup.get(AddressEntryFormNames.CountryDisplayOnly) as UntypedFormControl;
    this.postalCdControl = this.formGroup.get(AddressEntryFormNames.Zip) as UntypedFormControl;

    // When the user types in to the Country control, filter the list of countries
    // to only include those that contain what the user is typing
    this.filteredCountryNames$ = this.countryDisplayOnlyControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const filterValue = _.toString(value).toLowerCase();
        return this.countryNames.filter((name) => name.toLowerCase().includes(filterValue));
      }),
      takeUntil(this.unsubscriber.done)
    );

    this.formGroup
      .get(AddressEntryFormNames.Zip)
      .valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        filter((h) => !!h),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => this.matchZipCode());

    // watch for changes to the address from the user
    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((formData: AddressFormData) => {
        // compare the pristine form with the new form. If there
        // are differences, then address was changed
        // comparison breaks due to formData having a missing field (if either Address 2 / Attention form field is disabled due to mutual exclusivity)
        // so must reinsert any missing fields (i.e. Attention or Address2) via returnEmptyAddressDataObject() vvv
        const cloneFormData = this.returnEmptyAddressDataObject();
        const clonePristineForm = this.returnEmptyAddressDataObject();

        // assign using a customizer to skip assigning undefined/null values from formData/this.pristineForm
        // (and thus avoid false-positive change triggering)
        const customizer = (objValue, srcValue) => {
          return _.isNil(srcValue) ? objValue : srcValue;
        };

        // use getRawValue to always get values including disabled fields
        _.assignWith(cloneFormData, this.formGroup.getRawValue(), customizer);
        _.assignWith(clonePristineForm, this.pristineForm, customizer);

        const changed = !_.isEqual(clonePristineForm, cloneFormData);

        if (changed) {
          this.pristineForm = formData;
          this.valueChanges.emit();
        }
      });
  }

  private returnEmptyAddressDataObject(): AddressFormData {
    return {
      [AddressEntryFormNames.Name]: '',
      [AddressEntryFormNames.Attention]: '',
      [AddressEntryFormNames.Address1]: '',
      [AddressEntryFormNames.Address2]: '',
      [AddressEntryFormNames.City]: '',
      [AddressEntryFormNames.State]: '',
      [AddressEntryFormNames.Zip]: '',
      [AddressEntryFormNames.Country]: undefined,
      [AddressEntryFormNames.CountryDisplayOnly]: '',
    } as AddressFormData;
  }

  // Reset form to initial values
  public reset() {
    if (this.formGroup) {
      AddressEntryFormBuilder.setDefaultValues(this.formGroup);
      this.pristineForm = this.formGroup.getRawValue();
      this.changeDetectorRef.markForCheck();
      this.formGroup.updateValueAndValidity();
    }
  }

  /**
   * Looks for the first country that matches the passed countryCd (name, abbreviation, etc.)
   * Returns undefined if not found.
   */
  protected findCountry(countryCd: string): Observable<ICountry> {
    if (!countryCd) {
      return of(undefined);
    } else {
      return this.countries$.pipe(
        map((countries: ICountry[]) => {
          const key = countryCd.toLowerCase();
          return _.find(countries, (country) => {
            const matched =
              country.name.common.toLowerCase() === key ||
              country.name.official.toLowerCase() === key ||
              country.cca2.toLowerCase() === key ||
              country.cca3.toLowerCase() === key ||
              _.get(country, 'xpocca2', '').toLowerCase() === key;
            return matched;
          });
        })
      );
    }
  }

  public setCountry(countryCd: string): void {
    this.findCountry(countryCd).subscribe((icountry) => {
      FormUtils.setValues(this.formGroup, {
        [AddressEntryFormNames.CountryDisplayOnly]: _.get(icountry, 'name.common'),
        [AddressEntryFormNames.Country]: icountry,
      });
      this.formGroup.get(AddressEntryFormNames.Zip).markAsTouched();
      this.formGroup.updateValueAndValidity();
    });
  }

  // When user leaves the country control, update the selected Country, the display name
  // for the country, and validate the Zip code
  public onCountryBlur() {
    timer(100).subscribe(() => {
      const countryInput = this.countryDisplayOnlyControl.value;
      const currentCountry = this.formGroup.get(AddressEntryFormNames.Country).value;
      this.findCountry(countryInput).subscribe((icountry) => {
        if (_.isEqual(currentCountry, icountry)) {
          FormUtils.updateDescendantControlsValueAndValidity(this.countryDisplayOnlyControl);
        } else {
          // user chose a new country
          FormUtils.setNestedValue(icountry, this.formGroup, AddressEntryFormNames.Country);
          this.postalCdControl.updateValueAndValidity();
          this.valueChanges.emit();
        }
        const countryName = _.get(icountry, 'name.common', '');
        FormUtils.setValue(this.countryDisplayOnlyControl, countryName);
        this.pristineForm = this.formGroup.getRawValue();
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  /**
   * Checks to see if the zipcode is US or Canadian and sets the country if they are.
   *
   * skipNextZip is a flag that can be set to true to skip the next matchZipCode, used when setting the entire form data at once
   */
  public matchZipCode(): void {
    if (!!this.skipNextZip) {
      this.skipNextZip = false;
      return;
    }
    if (this.formatValidationService.isValidUsZipCode(this.postalCdControl.value)) {
      this.setCountry('United States');
    } else if (this.formatValidationService.isValidCanadianPostalCode(this.postalCdControl.value)) {
      this.setCountry('Canada');
    }
  }
}
