import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Unsubscriber, XpoLtlDocType, XpoLtlDocumentService } from '@xpo-ltl/ngx-ltl';
import { Claim } from '@xpo-ltl-2.0/sdk-claims';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums';
import { ClaimsRegistrationService } from '../../services/claims-registration/claims-registration.service';
import { DocViewService } from '../../core/services/doc-view.service';
import { ConditioningService } from '@xpo-ltl/common-services';

@Component({
  selector: 'claims-document-selector',
  templateUrl: './claims-document-selector.html',
  styleUrls: ['./claims-document-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClaimsDocumentSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('documentSelect') documentSelect: MatSelect;


  private _claim: Claim;
  private _proNbr: string;

  @Input() tooltip = 'Select Documents to Display';
  @Input() matIcon = 'link';

  @Output() selectionChange = new EventEmitter<DocumentSearch[]>();

  private documentListSubject = new BehaviorSubject<DocumentSearch[]>([]);
  readonly documentList$ = this.documentListSubject.asObservable();

  readonly XpoLtlDocType = XpoLtlDocType;

  readonly defaultAll: DocumentSearch = {
    cdt: {
      corpCode: this.configManager.getSetting(ConfigManagerProperties.imageCorpCode),
      docClass: XpoLtlDocType.All,
      timestamp: `${new Date().valueOf()}`,
    },
    timestampISO: new Date().toISOString(),
    relevanceValue: 0,
  };

  badgeConfig;
  enableProSearch = false;
  private unsubscriber: Unsubscriber;

  constructor(private doccumentService: XpoLtlDocumentService, private conditioningService: ConditioningService ,private configManager: ConfigManagerService, private claimsRegistrationService: ClaimsRegistrationService, private docViewService: DocViewService) {
    this.unsubscriber = new Unsubscriber();

  }

  ngOnInit(): void {
    this.claimsRegistrationService.claim$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((claim) => {
      this._claim = claim?.claim;
      this.enableProSearch = !this._claim;

      this.fetchDocuments(this._claim);
    });

    this.claimsRegistrationService.pro$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((proNbr) => {
      this._proNbr = proNbr;
      this.fetchDocumentsByPro(this._proNbr);
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private fetchDocumentsByPro(proNbr: string) {
    if (!proNbr || !this.enableProSearch) {
      return;
    }
    this.doccumentService.listAvailableDocuments(proNbr)
      .pipe(take(1))
      .subscribe((resp) => {
        this.documentListSubject.next(resp.documentInfo);
        this.claimsRegistrationService.dmsDocList = resp.documentInfo;
        this.badgeConfig = {
          xpoBadgeHidden: resp.documentInfo?.length < 1,
          xpoBadge: resp.documentInfo?.length.toString(),
          xpoBadgeFontSize: '12px',
          xpoBadgeBackgroundColor: '#d50000',
          xpoBadgeColor: '#ffffff',
        };
      });
  }

  selectionChanged(selection: MatSelectChange) {
    if (selection.value.cdt.docClass === XpoLtlDocType.All) {
      // return all documents
      this.doccumentService.navigateToDocViewApp(
        this._proNbr || this.conditioningService.conditionProNumber(this.claimsRegistrationService.claim.claim.proNbr, 10) ,
        this.claimsRegistrationService.claim.claim.claimId,
        XpoLtlDocType.All,
        this.claimsRegistrationService.claim.claim.pickupDate,
        true
      );
    } else {
      // return only selected document
      this.doccumentService.navigateToDocViewApp(
        selection.value.cdt.timestamp,
        this.claimsRegistrationService.claim.claim.claimId,
        selection.value.cdt.docClass,
        this.claimsRegistrationService.claim.claim.pickupDate,
        selection.value.length > 0
      );
    }

    localStorage.setItem('doc_metadata', JSON.stringify(selection.value));

    // if we don't clear value here, we can't select the same document twice in a row
    this.documentSelect.value = undefined;
  }


  private fetchDocuments(claim: Claim) {
    if (!claim) {
      return
    }
    let claimId = claim.claimId;
    const pickupDate = new Date(claim.pickupDate);
    pickupDate.setUTCHours(0, 0, 0, 0);
    const claimRqst = this.docViewService.listAvailableDocuments(claimId, pickupDate);
    const proRqst = this.docViewService.listAvailableDocuments(claim.proNbr, pickupDate);

    combineLatest([claimRqst, proRqst])
      .pipe(take(1))
      .subscribe(([resp1, resp2]) => {
        const docList = [...resp1.documentInfo];
        resp2.documentInfo.forEach(newDoc => {
          let index = docList.findIndex((existingDoc) =>
            existingDoc.cdt.timestamp === newDoc.cdt.timestamp && newDoc.cdt.corpCode === existingDoc.cdt.corpCode && newDoc.cdt.docClass === existingDoc.cdt.docClass
          )
          if (index < 0) {
            docList.push(newDoc)
          }
        });
        this.claimsRegistrationService.dmsDocList = docList;
        this.documentListSubject.next(docList);
        this.badgeConfig = {
          xpoBadgeHidden: docList?.length < 1,
          xpoBadge: docList?.length.toString(),
          xpoBadgeFontSize: '12px',
          xpoBadgeBackgroundColor: '#d50000',
          xpoBadgeColor: '#ffffff',
        };
      });
  }

}
