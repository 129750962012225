import { AbstractControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UpsertClaimRqst } from '@xpo-ltl-2.0/sdk-claims';
import { Note } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd, ClaimNoteVisibilityCd, ClaimNoteTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { NotesFormNames } from '../../../../enums/FormControlNames/notes-form-names.enum';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { ClaimsRegistrationFormNames } from '../../../../enums/FormControlNames';
import { AppConstantsService } from '../../../../services/app-constants.service';

export class RegistrationNotesFormBuilder {
  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService,
    constanstService: AppConstantsService
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const notes: Note[] = _.get(claimsRegistrationService.claim, 'notes', []) as Note[];
      const claimId = _.get(claimsRegistrationService.claim, 'claim.claimId');

      const formGroup = formBuilder.group({
        [NotesFormNames.AuthorName]: [constanstService.user.userId],
        [NotesFormNames.NoteTxt]: ['', [Validators.maxLength(4000)]],
        [NotesFormNames.SubjectTxt]: [''],
        [NotesFormNames.NoteId]: [''],
        [NotesFormNames.TypeCd]: [''],
        [NotesFormNames.VisibilityCd]: [''],
        [NotesFormNames.ClaimEventId]: [''],
        [NotesFormNames.RecordVersionNbr]: [''],
        [NotesFormNames.CorrelationId]: [''],
        [NotesFormNames.ListActionCd]: [claimId ? ActionCd.UPDATE : ActionCd.ADD],
      });

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.get(NotesFormNames.ListActionCd).setValue(ActionCd.ADD);
      formGroup.get(NotesFormNames.NoteTxt).setValue('');
      formGroup.get(NotesFormNames.SubjectTxt).setValue('Registration Note');
      formGroup.get(NotesFormNames.NoteId).setValue(undefined);
      formGroup.get(NotesFormNames.TypeCd).setValue(ClaimNoteTypeCd.EXAMINER_USE);
      formGroup.get(NotesFormNames.VisibilityCd).setValue(ClaimNoteVisibilityCd.CLAIMS_DEPT);
      formGroup.get(NotesFormNames.ClaimEventId).setValue(undefined);
      formGroup.get(NotesFormNames.RecordVersionNbr).setValue(undefined);
      formGroup.get(NotesFormNames.CorrelationId).setValue(undefined);
    }
  }

  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any, appConstantsService): UpsertClaimRqst {
    if (!upsertClaimRqst.notes) {
      upsertClaimRqst.notes = [];
    }
    const notes = _.get(rawFormGroup, `${ClaimsRegistrationFormNames.Notes}`, []);

    if (Array.isArray(notes)) {
      notes.forEach((note: UntypedFormGroup) => {
        if (this.isPopulatedNote(note)) {
          const transformedNote = this.transformNote(note, appConstantsService);
          upsertClaimRqst.notes.push(transformedNote);
        }
      });
    } else {
      if (this.isPopulatedNote(notes)) {
        const transformedNote = this.transformNote(notes, appConstantsService);
        upsertClaimRqst.notes.push(transformedNote);
      }
    }

    return upsertClaimRqst;
  }

  static transformNote(noteFG: UntypedFormGroup, constantsService: AppConstantsService): Note {
    const newNote = new Note();

    newNote.listActionCd = _.get(noteFG, NotesFormNames.ListActionCd, undefined);
    newNote.authorName = _.get(noteFG, NotesFormNames.AuthorName, undefined) || constantsService.user.userId;
    newNote.noteTxt = _.get(noteFG, NotesFormNames.NoteTxt, undefined);
    newNote.noteId = _.get(noteFG, NotesFormNames.NoteId, undefined);
    newNote.typeCd = _.get(noteFG, NotesFormNames.TypeCd, undefined);
    newNote.visibilityCd = _.get(noteFG, NotesFormNames.VisibilityCd, undefined);
    newNote.claimEventId = _.get(noteFG, NotesFormNames.ClaimEventId, undefined);
    newNote.recordVersionNbr = _.get(noteFG, NotesFormNames.RecordVersionNbr, undefined);
    newNote.correlationId = _.get(noteFG, NotesFormNames.CorrelationId, undefined);
    newNote.subjectTxt = _.get(noteFG, NotesFormNames.SubjectTxt, undefined);

    return newNote;
  }

  private static isPopulatedNote(noteFG): boolean {
    return (
      !!_.get(noteFG, NotesFormNames.ListActionCd, undefined) && !!_.get(noteFG, NotesFormNames.NoteTxt, undefined)
    );
  }
}
