import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetClaimResp, AsEnteredParty, Payment, PaymentDetail } from '@xpo-ltl-2.0/sdk-claims';
import { Observable } from 'rxjs/internal/Observable';
import * as _ from 'lodash';
import {  Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Rebuttal } from '@xpo-ltl-2.0/sdk-claims';
import { RebuttalInternalStatusCd, PaymentStatusInternalCd, ClaimPartyTypeCd } from '@xpo-ltl/sdk-common';
import { CurrencyPipe } from '@angular/common';
import { ClaimDecisionReasons } from '../../components/registration/components/registration-claim-payment/claim-decision-reasons.class';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { AsEnteredPartyIndex } from '../../enums/as-entered-party-index.enum';
import { XpoAngularUtilsService } from '../../core/services/xpo-angular-utils.service';
import { CurrencyCdPipe } from '../../pipes/currency-cd.pipe';
import { RebuttalStatusInternalPipe } from '../../pipes/rebuttal-status-internal.pipe';
import { ClaimInternalStatusCdPipe } from '../../pipes/claim-internal-status-cd.pipe';

@Component({
  selector: 'app-print-claim',
  templateUrl: './print-claim.component.html',
  styleUrls: ['./print-claim.component.scss'],
  providers: [CurrencyPipe, CurrencyCdPipe, RebuttalStatusInternalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintClaimComponent implements OnInit, OnDestroy {
  public claim: GetClaimResp;
  public paymentHistory: PaymentDetail[];
  public lastPayment: PaymentDetail;
  public readonly ClaimDecisionReasons = ClaimDecisionReasons;
  private unsubscriber = new Unsubscriber();
  public claimExternalStatus: string;

  constructor(
    private dialogRef: MatDialogRef<PrintClaimComponent>,
    public xpoUtils: XpoAngularUtilsService,
    private currencyPipe: CurrencyPipe,
    private currencyCdPipe: CurrencyCdPipe,
    @Inject(MAT_DIALOG_DATA) private data: Observable<GetClaimResp>,
    private rebuttalStatusInternalPipe: RebuttalStatusInternalPipe,
    public claimInternalStatusCdPipe: ClaimInternalStatusCdPipe
  ) {}
  ngOnInit() {
    this.data
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged()
      )
      .subscribe(claim => {
        this.claim = claim;
        this.paymentHistory = _.sortBy(this.claim.payments, [(payment: PaymentDetail) => payment.payment.auditInfo.createdTimestamp]);

        // find the payment currently being processed, or the last one submitted
        this.lastPayment = _.find(this.paymentHistory, (paymentDetail: PaymentDetail) => paymentDetail.payment.statusInternalCd === PaymentStatusInternalCd.PAYMENT_REQUESTED);

        // if no active payments found, get the latest one
        if (!this.lastPayment) {
          this.lastPayment = _.last(this.paymentHistory);
        }
        this.claimExternalStatus = this.claimInternalStatusCdPipe.transform(<any>this.claim.claim.externalStatusCd);
      });
  }

  public getFullZip(zip1: string, zip2: string, xpoUtils: XpoAngularUtilsService): string {
    const formatZip = (zipCode: string): string => (zipCode ? zipCode.trim() : zipCode);
    return _.toString(xpoUtils.concatenateZips(formatZip(zip1), formatZip(zip2)));
  }

  public stateOrCountrySubDivisionCd(stateCd: string, countrySubdivisionNm: string): string {
    return stateCd || countrySubdivisionNm;
  }

  public onBehalf(asEnteredParty: AsEnteredParty[]): string {
    let enteredPartyIndex: number = _.findIndex(asEnteredParty, (party: AsEnteredParty) => _.get(party, 'partyTypeCd') === ClaimPartyTypeCd.CLAIMANT);
    enteredPartyIndex = enteredPartyIndex === -1 ? AsEnteredPartyIndex.Claimant : enteredPartyIndex;
    const enteredParty: AsEnteredParty = _.get(asEnteredParty, `asEnteredParty[${enteredPartyIndex}]`) as AsEnteredParty;
    return _.get(enteredParty, 'onBehalfOfName', '-');
  }

  public getLatestRebuttalStatus() {
    if (!_.get(this.claim, 'rebuttals', '').length) {
      return '';
    }

    const rebuttals: Rebuttal[] = _.get(this.claim, 'rebuttals', []);

    const sortedRebuttals = _.orderBy(rebuttals, 'receivedDate', 'desc');

    return this.rebuttalStatusInternalPipe.transform(_.get(sortedRebuttals[0], 'internalStatusCd', '') as RebuttalInternalStatusCd);
  }

  public paidAmount(payment: Payment): string {
    return _.has(payment, 'amount') ? `${this.currencyPipe.transform(payment.amount)} ${this.currencyCdPipe.transform(payment.currencyCd)}` : null;
  }
  public setPreviousPayoutAmount(claim: GetClaimResp) {
    const invalidPaymentStatuses = [PaymentStatusInternalCd.CHECK_VOIDED, PaymentStatusInternalCd.DECLINED, PaymentStatusInternalCd.PAYMENT_CANCELLED, PaymentStatusInternalCd.PAYMENT_REQUESTED];

    // add up all payments to get total payout
    const totalPreviousAmountsPaid = _.reduce(
      _.get(claim, 'payments'),
      (total, paymentDetails: PaymentDetail) => {
        const paymentAmount = _.every(invalidPaymentStatuses, status => paymentDetails.payment.statusInternalCd !== status) ? _.get(paymentDetails, 'payment.amount', 0) : 0;
        return total + paymentAmount;
      },
      0
    );
    return totalPreviousAmountsPaid;
  }
  public updateTotalPayout(claim: GetClaimResp) {
    const invalidPaymentStatuses = [PaymentStatusInternalCd.CHECK_VOIDED, PaymentStatusInternalCd.DECLINED, PaymentStatusInternalCd.PAYMENT_CANCELLED];

    // add up all payments to get total payout
    const totalPayout = _.reduce(
      _.get(claim, 'payments'),
      (total, paymentDetails: PaymentDetail) => {
        const paymentAmount = _.every(invalidPaymentStatuses, status => paymentDetails.payment.statusInternalCd !== status) ? _.get(paymentDetails, 'payment.amount', 0) : 0;
        return total + paymentAmount;
      },
      0
    );
    return totalPayout;
  }
  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
