import { Injectable } from '@angular/core';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { GetEmployeePath,  GetEmployeeResp,  GetInterfaceEmployeePath,  GetInterfaceEmployeeResp,  HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

export interface EmployeeDetailsCacheParams {
  employeeId: string;
}

@Injectable()
export class EmployeeDetailsCacheService extends XpoLtlBaseCacheService<EmployeeDetailsCacheParams, GetInterfaceEmployeeResp> {
  constructor(private hrService: HumanResourceApiService) {
    super();
  }

  protected getKeyFromParams(params: EmployeeDetailsCacheParams): string {
    return `${_.defaultTo(params.employeeId, '').toUpperCase()}`;
  }
  protected requestData(params: EmployeeDetailsCacheParams): Observable<GetInterfaceEmployeeResp> {
    const pathParams = new GetInterfaceEmployeePath();
    pathParams.employeeId = params.employeeId;


    return this.hrService.getInterfaceEmployee(pathParams, { toastOnError: false });
  }
}
