import { AbstractControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { AsEnteredParty, Party } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { AddressEntryFormNames, CommonRegex } from '../../../../enums';
import { disallowNumericsOnlyValidatorDirective, minLengthWarningValidator } from '../../../../validators';
import { stateCodeValidator } from '../../../../validators/state-code-validator.directive';
import { AddressData } from './address-entry.component';
import { xpoBadCharactersValidator } from '../../../../core/validators/non-use-characters.validators';
import { ClaimPartyMaxLengths } from 'src/app/enums/FormMaxLengths/claim-party-max-lengths.enum';
import { conditionalZipValidator } from 'src/app/directives/conditional-zip-validator';

export class AddressEntryFormBuilder {
  private static defaultValues;

  static create(formBuilder: UntypedFormBuilder, asEnteredParty = false): AbstractControl {
    const formGroup = formBuilder.group({
      [AddressEntryFormNames.Name]: [
        '',
        [
          Validators.required,
          Validators.maxLength(ClaimPartyMaxLengths.Name),
          xpoBadCharactersValidator(CommonRegex.NAME_FIELD_BAD_CHARACTERS),
          minLengthWarningValidator(2),
          disallowNumericsOnlyValidatorDirective(),
        ],
      ],
      [AddressEntryFormNames.Attention]: [
        '',
        [
          xpoBadCharactersValidator(CommonRegex.NAME_FIELD_BAD_CHARACTERS),
          Validators.maxLength(ClaimPartyMaxLengths.Attention),
        ],
      ],
      [AddressEntryFormNames.Address1]: [
        '',
        [
          Validators.required,
          Validators.maxLength(ClaimPartyMaxLengths.Address1),
          minLengthWarningValidator(2),
          xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        ],
      ],
      [AddressEntryFormNames.Address2]: [
        '',
        [Validators.maxLength(ClaimPartyMaxLengths.Address2), xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS)],
      ],
      [AddressEntryFormNames.City]: [
        '',
        [
          Validators.required,
          Validators.maxLength(ClaimPartyMaxLengths.City),
          xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        ],
      ],
      [AddressEntryFormNames.State]: ['', [Validators.required, xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS)]],
      [AddressEntryFormNames.Zip]: ['', [Validators.maxLength(ClaimPartyMaxLengths.Postal)]],
      [AddressEntryFormNames.Country]: [undefined, [Validators.required]],
      [AddressEntryFormNames.CountryDisplayOnly]: ['', [Validators.required]],
    });

    AddressEntryFormBuilder.defaultValues = formGroup.getRawValue();

    // additional validators
    formGroup
      .get(AddressEntryFormNames.Zip)
      .setValidators([
        Validators.required,
        xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
        conditionalZipValidator(formGroup.get(AddressEntryFormNames.Country)),
      ]);

    formGroup.setValidators(stateCodeValidator());

    return formGroup;
  }

  static setDefaultValues(formGroup: AbstractControl) {
    formGroup.setValue(AddressEntryFormBuilder.defaultValues);
  }

  static createAsEnteredPartyFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      [AddressEntryFormNames.Name]: [''],
      [AddressEntryFormNames.Attention]: [''],
      [AddressEntryFormNames.Address1]: [''],
      [AddressEntryFormNames.Address2]: [''],
      [AddressEntryFormNames.City]: [''],
      [AddressEntryFormNames.State]: [''],
      [AddressEntryFormNames.Zip]: [''],
      [AddressEntryFormNames.Zip2]: [''],
      [AddressEntryFormNames.Country]: [''],
    });
  }

  static updateAsEnteredPartyWithAddress(address: AddressData, origEnteredParty: AsEnteredParty): AsEnteredParty {
    let asEnteredParty: AsEnteredParty;

    address.postalCdExt = address.postalCdExt ? _.defaultTo(address.postalCdExt.trim(), undefined) : undefined;
    address.name2 = address.name2 ? _.defaultTo(address.name2.trim(), undefined) : undefined;
    address.addr2 = address.addr2 ? _.defaultTo(address.addr2.trim(), undefined) : undefined;

    if (_.isUndefined(origEnteredParty)) {
      // no exisintg party, so create a new one
      asEnteredParty = {
        ...new AsEnteredParty(),
        ...address,
        listActionCd: ActionCd.ADD,
      };
    } else {
      // update existing party if it was changed
      const shouldUpdateAddress = !_.isEqual(address, _.pick(origEnteredParty, _.keys(address)));
      asEnteredParty = {
        ...origEnteredParty,
        ...address,
        listActionCd: shouldUpdateAddress ? ActionCd.UPDATE : ActionCd.NO_ACTION,
      };
    }

    if (asEnteredParty.stateCd && asEnteredParty.stateCd.length > 2) {
      // not a state, so convert to subdivision name
      asEnteredParty.countrySubdivisionNm = asEnteredParty.stateCd;
      asEnteredParty.stateCd = undefined;
    }

    return asEnteredParty;
  }

  static addressToParty(address: AddressData, claimPartyId?: number): Party {
    const party = {
      ...new Party(),
      ...address,
      claimPartyId: claimPartyId,
    };

    if (party.stateCd && party.stateCd.length > 2) {
      // not a state, so convert to subdivision name
      party.countrySubdivisionNm = party.stateCd;
      party.stateCd = undefined;
    }

    return party;
  }

  static partyToAddress(party: Party): AddressData {
    const addressData = {
      name1: party.name1,
      name2: party.name2,
      addr1: party.addr1,
      addr2: party.addr2,
      cityName: party.cityName,
      stateCd: _.get(party, 'countrySubdivisionNm', party.stateCd),
      countryCd: party.countryCd,
      postalCd: party.postalCd,
      postalCdExt: party.postalCdExt,
      claimPartyId: party.claimPartyId,
    };

    return addressData;
  }
}
