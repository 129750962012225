import { Injectable } from '@angular/core';
import { ClaimsApiService, ListClaimIdsForShipmentsResp, ListClaimIdsForShipmentsRqst } from '@xpo-ltl-2.0/sdk-claims';
import { Observable, of, Subject } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { ClaimInternalStatusCd, ShipmentId } from '@xpo-ltl/sdk-common';
import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';

@Injectable({
  providedIn: 'root',
})
export class ClaimsForShipmentCacheService {
  private cache = new Map<string, Observable<string[]>>();

  private cacheUpdatedSubject = new Subject();
  public cacheUpdated$ = this.cacheUpdatedSubject.asObservable();

  constructor(private claimsApiService: ClaimsApiService, private formatService: FormatValidationService, private conditioningService: ConditioningService) {}

  public getClaimsForShipment(requestId: string): Observable<string[]> {
    if (!requestId) {
      return of([]);
    }

    if (this.formatService.isValidProNumber(`${requestId}`)) {
      requestId = this.conditioningService.conditionProNumber(`${requestId}`, 11);
    }

    return this.cache.has(`${requestId}`) ? this.cache.get(`${requestId}`) : this.loadClaimsForShipment(`${requestId}`);
  }

  private loadClaimsForShipment(requestId: string): Observable<string[]> {
    const subject = new Subject<string[]>();
    const request = new ListClaimIdsForShipmentsRqst();
    const shipmentId = new ShipmentId();

    if (this.formatService.isValidProNumber(requestId)) {
      shipmentId.proNumber = requestId;
    } else {
      shipmentId.shipmentInstId = requestId;
    }

    request.shipmentIds = [shipmentId];
    this.claimsApiService
      .listClaimIdsForShipments(request, { loadingOverlayEnabled: false })
      .pipe(
        finalize(() => {
          subject.complete();
          this.cacheUpdatedSubject.next();
        })
      )
      .subscribe(
        (resp: ListClaimIdsForShipmentsResp) => {
          const claims = resp.shipmentClaimIds.filter(scid => scid.internalStatusCd !== ClaimInternalStatusCd.CUSTOMER_CANCELLED).map(scid => scid.claimId);
          subject.next(claims);
        },
        err => {
          subject.next([]);
        }
      );

    const obs = subject.asObservable().pipe(shareReplay(1));
    this.cache.set(requestId, obs);
    return obs;
  }

  public reset(): void {
    this.cache.clear();
  }
}
