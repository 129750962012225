import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  XpoLtlDmsUtilsModule,
  XpoLtlDocumentService,
  XpoLtlPipeModule,
  XpoLtlUpdateShipmentStatusDialogService,
} from '@xpo-ltl/ngx-ltl';
import { CoreModule } from './core/core.module';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { SdkAccountsReceivableModule } from '@xpo-ltl/sdk-accountsreceivable';
import { ClaimsApiService } from '@xpo-ltl-2.0/sdk-claims';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { ShipmentApiService } from '@xpo-ltl/sdk-shipment';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { AngularSplitModule } from 'angular-split';
import { ClipboardModule } from 'ngx-clipboard';
import { CountryPickerModule } from 'ngx-country-picker';
// import { MaterialModule, SharedModule } from '../../../shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { DataDisplayGroupModule } from './components/data-display-group/data-display-group.module';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotificationComponent } from './components/notification/notification.component';
import { StatusBadgeModule } from './components/status-badge/status-badge.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { DirectivesModule } from './directives/directives.module';
import { ClaimApprovalGuard } from './guards/claim-approval-guard';
import { ClaimRouteGuard } from './guards/claim-route.guard';
import { PipesModule } from './pipes/pipes.module';
import { EmailTemplatesCacheService } from './services/email-templates-cache.service';
import { EmployeeDetailsCacheService } from './services/employee-details-cache.service';
import { ListAssignedClaimsPendingApprovalCacheService } from './services/list-assigned-claims-pending-approval-cache/list-assigned-claims-pending-approval-cache.service';
import { ListClaimsPendingApprovalCacheService } from './services/list-claims-pending-approval-cache/list-claims-pending-approval-cache.service';
import { ListActiveSubrogatedClaimsCacheService } from './services/list-active-subrogated-claims-cache/list-active-subrogated-claims-cache.service';
import { ListExaminerClaimsCacheService } from './services/list-examiner-claims-cache/list-examiner-claims-cache.service';
import { ListExaminerRebuttalsCacheService } from './services/list-examiner-rebuttals-cache/list-examiner-rebuttals-cache.service';
import { ServiceCenterCacheService } from './services/service-center-cache.service';
import { ClaimsRegistrationValidatorsModule, DisallowNumericsOnlyValidatorDirective } from './validators';
import { XpoBrowserGuardModule } from '@xpo-ltl/ngx-ltl';
import { LookupGuard } from './guards/lookup.guard';
import { ClaimHistoryGuard } from './guards/claim-history.guard';
import { ClaimHistoryService } from './services/claim-history.service';
import { XpoLtlRoleSwitcherModule } from '@xpo-ltl/ngx-ltl';
import { CustomBreakPointsProvider } from './classes/custom-breakpoints';
import { ListExaminerActiveClosedCacheService } from './services/list-examiner-active-closed-cache/list-examiner-active-closed-cache.service';
import {
  XpoShellModule,
} from '@xpo-ltl/ngx-ltl-core/shell';
import {
  XpoDialogModule,
} from '@xpo-ltl/ngx-ltl-core/dialog';
import {
  XpoConfirmDialogModule,
} from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo/ngx-core/error-page';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AppHammerConfig } from './classes/hammer.config';
import { ClaimsApprovalComponent } from './components/claims-approval/claims-approval.component';
import { RoleCheckGuard } from './guards/role-check-guard';
import { ElasticSearchApiService } from '@xpo-ltl/sdk-elasticsearch';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { XpoLtlGlobalSearchModule } from '@xpo-ltl/ngx-ltl-global-search';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { ClaimsDocumentSelectorComponent } from './components/navigation-bar/claims-document-selector.component';
import { NotesEmailsModule } from './components/notes-emails/notes-emails.module';
import { InfrastructureApiService } from '@xpo-ltl/sdk-infrastructure';
import { ClaimsShipmentSelectorComponent } from './components/navigation-bar/claims-shipment-selector.component';
import { MaterialModule } from './material.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    ClaimsApprovalComponent,
    DisallowNumericsOnlyValidatorDirective,
    NavigationBarComponent,
    NotAuthorizedComponent,
    NotificationComponent,
    ClaimsDocumentSelectorComponent,
    ClaimsShipmentSelectorComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AngularSplitModule.forRoot(),
    BrowserAnimationsModule,
    ClaimsRegistrationValidatorsModule,
    ClipboardModule,
    CountryPickerModule.forRoot(),
    DataDisplayGroupModule,
    NotesEmailsModule,
    DialogsModule,
    DirectivesModule,
    MaterialModule,
    XpoLtlPipeModule,
    PipesModule,
    SdkLoggingModule,
    SdkAccountsReceivableModule,
    StatusBadgeModule,
    CoreModule,
    XpoBrowserGuardModule,
    XpoLtlGlobalSearchModule,
    XpoLtlRoleSwitcherModule,
    XpoShellModule,
    XpoConfirmDialogModule,
    XpoDialogModule,
    XpoLtlDmsUtilsModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    // keep this last!
  ],
  exports: [ClaimsDocumentSelectorComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
    CustomBreakPointsProvider,
    ShipmentApiService,
    ShipmentOdsApiService,
    ClaimsApiService,
    DatePipe,
    ClaimHistoryService,
    ClaimRouteGuard,
    ClaimHistoryGuard,
    ClaimApprovalGuard,
    LookupGuard,
    DocumentManagementApiService,
    HumanResourceApiService,
    PricingApiService,
    ServiceCenterCacheService,
    ListAssignedClaimsPendingApprovalCacheService,
    ListClaimsPendingApprovalCacheService,
    ListActiveSubrogatedClaimsCacheService,
    ListExaminerClaimsCacheService,
    ListExaminerRebuttalsCacheService,
    ListExaminerActiveClosedCacheService,
    LocationApiService,
    EmployeeDetailsCacheService,
    EmailTemplatesCacheService,
    RoleCheckGuard,
    ElasticSearchApiService,
    XpoLtlAuthenticationService,
    XpoLtlUpdateShipmentStatusDialogService,
    XpoLtlDocumentService,
    InfrastructureApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
