import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import { GetServiceCenterDetailsBySicResp,  GetServiceCenterDetailsBySicRqst, LocationApiService } from '@xpo-ltl/sdk-location';

export interface ServiceCenterCacheParams {
  sicCd: string;
}

@Injectable()
export class ServiceCenterCacheService extends XpoLtlBaseCacheService<
  ServiceCenterCacheParams,
  GetServiceCenterDetailsBySicResp
> {
  constructor(private locationService: LocationApiService) {
    super();
    this.maxRetries = 1;
  }

  protected getKeyFromParams(params: ServiceCenterCacheParams): string {
    return params.sicCd.toUpperCase();
  }

  protected requestData(params: ServiceCenterCacheParams): Observable<GetServiceCenterDetailsBySicResp> {
    const request = new GetServiceCenterDetailsBySicRqst();
    request.sicCd = params.sicCd.toUpperCase();
    return this.locationService.getServiceCenterDetailsBySic(request, { toastOnError: false })
  }
}
