import { CurrencyPipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Unsubscriber, XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import {
  ClaimsApiService,
  CreateClaimEmailRqst,
  EmailInteraction,
  GetClaimResp,
  UpdateClaimEmailRqst,
  EmailAttachment,
} from '@xpo-ltl-2.0/sdk-claims';
import {
  ActionCd,
  Attachment,
  ClaimEmailTemplateTypeCd,
  ElectronicMessage,
  EmailDirectionTypeCd,
  DmsDocTypeCd,
} from '@xpo-ltl/sdk-common';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { SplitComponent } from 'angular-split';
import * as _ from 'lodash';
import { FilePickerDirective, ReadFile, ReadMode } from 'ngx-file-helpers';
import { BehaviorSubject, Observable, of, zip, Subject, merge } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../../classes/form-utils.class';
import { ClaimsRegistrationFormNames, CommodityLineItemsFormNames, RouterUriComponents } from '../../../enums';
import { EmailFormNames } from '../../../enums/FormControlNames/email-form-names.enum';
import { FormStatus } from '../../../enums/FormControlNames/form-status.enum';
import { AppConstantsService } from '../../../services/app-constants.service';
import { EmailTemplatesCacheService } from '../../../services/email-templates-cache.service';
import { AgeTypes } from '../age-types.enum';
import { DirectionTypes } from '../direction-types.enum';
import { EmailActionTypes } from '../email-action-types.enum';
import { EmailActionInterface } from '../email-action.interface';
import { EmailsConfig } from './emails-config';
import { NotificationService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { decode } from 'typescript-base64-arraybuffer';
import moment from 'moment';
import { xpoEmailValidator } from '../../../core/validators/email-validator';
import { DocumentSearchPipe } from '../../../core/pipes/document-search.pipe';
import { ClaimEmailMaxLengths } from 'src/app/enums/FormMaxLengths/claim-email-max-lengths.enum';
import { Employee, GetEmployeeResp, GetInterfaceEmployeeResp, InterfaceEmployee } from '@xpo-ltl-2.0/sdk-humanresource';
import { EmployeeDetailsCacheService } from '../../../services/employee-details-cache.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  html = '';

  ckConfig = {
    allowedContent: true,
    removeEmpty: false,
    removeFormatAttributes: '',
    removeFormatTags: '',
    extraAllowedContent: '*(*);*{*}',
    extraPlugins: 'colorbutton',
    removePlugins: 'elementspath,contextmenu,tabletools,tableselection',
    enterMode: 2,
    disableNativeSpellChecker: false,
    toolbarGroups: [
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] },
    ],

    removeButtons:
      'Source,Save,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,ImageButton,HiddenField,Button,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Table,Flash,Image,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,BGColor,ShowBlocks,Maximize,About,NewPage,Preview,Print,Copy,Paste,PasteText,PasteFromWord,Redo,Replace',
  };

  private uploadBadgeHiddenSubject = new BehaviorSubject<boolean>(true);
  private uploadBadgeSubject = new BehaviorSubject<string>('');

  uploadBadgeConfig = {
    xpoBadgeHidden: this.uploadBadgeHiddenSubject,
    xpoBadge: this.uploadBadgeSubject,
    xpoBadgeFontSize: '12px',
    xpoBadgeBackgroundColor: 'black',
    xpoBadgeColor: '#ffffff',
  };

  readonly ClaimEmailMaxLengths = ClaimEmailMaxLengths;
  readonly FormStatus = FormStatus;

  directionTypes = [DirectionTypes.IncomingAndOutgoing, DirectionTypes.Incoming, DirectionTypes.Outgoing];
  ageTypes = [AgeTypes.NewestToOldest, AgeTypes.OldestToNewest];

  private templatesSubject = new BehaviorSubject<string[]>(undefined);
  templates$ = this.templatesSubject.asObservable();

  private emailsSubject = new BehaviorSubject<EmailInteraction[]>([]);
  emails$ = this.emailsSubject.asObservable();

  private unreadEmailsCountSubject = new BehaviorSubject<number>(0);
  unreadEmailsCount$ = this.unreadEmailsCountSubject.asObservable();

  private fileList = new Array<ReadFile>();
  private attachmentsSubject = new BehaviorSubject<string>(undefined);
  attachments$ = this.attachmentsSubject.asObservable();

  private uploadingDownloadingFileSubject = new BehaviorSubject<boolean>(false);
  uploadingDownloadingFile$ = this.uploadingDownloadingFileSubject.asObservable();

  EmailFormNames = EmailFormNames;
  formGroup: UntypedFormGroup;

  private unsubscriber = new Unsubscriber();

  readMode = ReadMode.BinaryString;
  isForwardingEmail = false;

  private emptyEmailBodyValue: string;
  private isEmailBodyEmptySubject = new BehaviorSubject(true);
  isEmailBodyEmpty$ = this.isEmailBodyEmptySubject.asObservable();

  @ViewChild('splitPanel', { static: true })
  splitPanel: SplitComponent;

  @ViewChild('template', { static: true })
  template: MatSelect;

  @ViewChild('direction', { static: true })
  direction: MatSelect;

  @ViewChild('age', { static: true })
  age: MatSelect;

  @ViewChild('dms', { static: true })
  dms: MatSelect;

  @ViewChild(FilePickerDirective, { static: true })
  private filePicker;
  prevClaim: GetClaimResp;

  @Input()
  set tabChanged(value: boolean) {
    if (value) {
      setTimeout(() => {
        this.splitPanel.setVisibleAreaSizes([50, 50]);
      });
    }
  }
  @Input() claim: GetClaimResp;
  @Input() draftInd = false;

  @Input() claimsRegistrationFormGroup: UntypedFormGroup;
  @Input() private dmsDocList$: Observable<DocumentSearch[]> = of([]);
  private dmsDocListSubject = new Subject<DocumentSearch[]>();
  dmsDocumentList$: Observable<DocumentSearch[]> = merge(this.dmsDocList$, this.dmsDocListSubject.asObservable());

  @Input() config: EmailsConfig;
  @Input() isEscalatingOrDeclining: boolean;

  @Input() downloadDmsAttachmentFn: Function;
  @Input() checkClaimsRecordVersionNbrFn: Function = () => { };

  constructor(
    private claimsApiService: ClaimsApiService,
    private formBuilder: UntypedFormBuilder,
    public constants: AppConstantsService,
    public configManagerService: ConfigManagerService,
    private formatValidationService: FormatValidationService,
    private conditioningService: ConditioningService,
    private emailTemplateCacheService: EmailTemplatesCacheService,
    private employeeCacheService: EmployeeDetailsCacheService,
    private currencyPipe: CurrencyPipe,
    private documentSearchPipe: DocumentSearchPipe,
    private notificationService: NotificationService,
    private windowService: XpoLtlWindowService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    const templates = [];
    Object.values(ClaimEmailTemplateTypeCd).forEach((value) => {
      if (
        value !== ClaimEmailTemplateTypeCd.ESCALATION_APPROVAL &&
        value !== ClaimEmailTemplateTypeCd.CLAIM_ACKNOWLEDGEMENT
      ) {
        templates.push(value);
      }
    });
    this.templatesSubject.next(templates);

    this.formGroup = this.formBuilder.group({
      [EmailFormNames.Subject]: [_.get(this.config, 'subject', ''), Validators.required],
      [EmailFormNames.Recipient]: [
        _.get(this.config, 'recipient'),
        [Validators.required, xpoEmailValidator(this.formatValidationService)],
      ],
      [EmailFormNames.Cc]: [_.get(this.config, 'cc'), xpoEmailValidator(this.formatValidationService)],
      [EmailFormNames.Body]: [_.get(this.config, 'email.messageTxt', ''), Validators.required],
    });

    if (_.get(this.config, 'hideRecipient', false)) {
      this.formGroup.get(EmailFormNames.Recipient).disable();
    }

    if (_.get(this.config, 'email.emailAttachment', false)) {
      this.buildAttachments();
    }

    if (this.constants.isReadOnly) {
      this.formGroup.disable({ emitEvent: false });
      this.loadEmails();
      return;
    }

    this.dms.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe((value) => {
      this.updateBadge();
      this.updateAttachments();
    });
    this.updateBadge();
    this.updateAttachments();

    this.bodyControl.valueChanges
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(this.updateIsEmailBodyEmpty.bind(this));
    this.template.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe(this.setEmailTemplate.bind(this));

    this.loadEmails();

    const initialTemplate: ClaimEmailTemplateTypeCd = _.get(
      this.config,
      'useTemplate',
      ClaimEmailTemplateTypeCd.BLANK_TEMPLATE
    );
    // if email is not a draft
    const emailFromConfig = _.get(this.config, 'email');
    if (initialTemplate && !emailFromConfig) {
      this.template.value = initialTemplate;
      this.setEmailTemplate(initialTemplate);
    } else if (emailFromConfig && emailFromConfig.templateTypeCd === ClaimEmailTemplateTypeCd.POLICY_PAY) {
      this.employeeCacheService.request({ employeeId: this.claim.claim.examinedByEmployeeId }).subscribe((result) => {
        const bodyValue = this.replaceTemplateVariables(emailFromConfig.messageTxt, result);
        this.emptyEmailBodyValue = bodyValue;
        this.bodyControl.setValue(bodyValue);
      });
    }

    FormUtils.touchAllControls(this.formGroup);

    // create internal dmsDocList so we can emit values on this observable (for reseting on claim change)
    this.dmsDocumentList$ = merge(this.dmsDocList$, this.dmsDocListSubject.asObservable());
  }

  private handleClaimChanged() {
    this.clearFormFields();
    this.resetAttachments();
    this.isForwardingEmail = false;

    this.formGroup.patchValue({
      [EmailFormNames.Subject]: _.get(this.config, 'subject', ''),
      [EmailFormNames.Recipient]: _.get(this.config, 'recipient'),
      [EmailFormNames.Cc]: _.get(this.config, 'cc'),
      [EmailFormNames.Body]: _.get(this.config, 'email.messageTxt', ''),
    });

    if (_.get(this.config, 'hideRecipient', false)) {
      this.formGroup.get(EmailFormNames.Recipient).disable();
    }
    if (_.get(this.config, 'email.emailAttachment', false)) {
      this.buildAttachments();
    }

    this.updateBadge();
    this.updateAttachments();
    this.loadEmails();

    const initialTemplate: ClaimEmailTemplateTypeCd = _.get(
      this.config,
      'useTemplate',
      ClaimEmailTemplateTypeCd.BLANK_TEMPLATE
    );
    // if email is not a draft
    if (initialTemplate && !_.get(this.config, 'email')) {
      this.template.value = initialTemplate;
      this.setEmailTemplate(initialTemplate);
    }
    // set email body as not changed (for clear button functionality)
    this.isEmailBodyEmptySubject.next(true);
  }

  public onPaste(e: ClipboardEvent) {
    if (this.template && this.template.value === ClaimEmailTemplateTypeCd.ESCALATION_APPROVAL) {
      e.preventDefault();
      e.stopPropagation();
      const plainText = e.clipboardData.getData('text/plain');
      this.document.execCommand('insertText', false, plainText);
      return false;
    } else {
      return true;
    }
  }

  ngAfterViewInit() {
    if (!_.get(this.config, 'composeOnly', false)) {
      if (this.direction) {
        this.direction.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
          this.loadEmails();
        });
      }

      if (this.age) {
        this.age.valueChange.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
          this.loadEmails();
        });
      }
    }
  }

  ngOnChanges() {
    if (this.claim !== this.prevClaim) {
      const sameClaimId = _.get(this.claim, 'claim.claimId') === _.get(this.prevClaim, 'claim.claimId');
      if (!!this.prevClaim) {
        if (!sameClaimId) {
          this.handleClaimChanged();
        } else {
          // only refresh email list if we are still in the same claim
          this.loadEmails();
        }
      }
      this.prevClaim = this.claim;
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  public handleSendClicked(): void {
    this.sendEmail()
      .pipe(take(1))
      .subscribe(() => {
        this.config.email = undefined;
        this.handleClearAttachments();
        this.isForwardingEmail = false;
        this.checkClaimsRecordVersionNbrFn();
        this.setEmailTemplate(ClaimEmailTemplateTypeCd.BLANK_TEMPLATE);
        this.template.value = ClaimEmailTemplateTypeCd.BLANK_TEMPLATE;
      });
  }

  public handleClearClicked(): void {
    this.setEmailTemplate(this.template.value);
  }

  public sendEmail(): Observable<void> {
    return new Observable((observer) => {
      const emailObservable: Observable<void> =
        !!this.config.email && !this.isForwardingEmail
          ? // updating an existing email
          this.updateEmail()
          : // sending a new email
          this.sendNewEmail();

      emailObservable.pipe(take(1)).subscribe(
        () => {
          observer.next();
          observer.complete();
        },
        (error) => {
          observer.next();
          observer.complete();
        }
      );
    });
  }

  public updateEmail(): Observable<void> {
    return new Observable((observer) => {
      // find the email
      const emailToUpdate = {
        ...this.config.email,
        recipientEmailAddressTxt: this.recipientControl.value,
        ccEmailAddressTxt: _.get(this.ccControl, 'value', undefined),
        subjectTxt: this.subjectControl.value,
        messageTxt: this.bodyControl.value,
        directionTypeCd: EmailDirectionTypeCd.OUTBOUND,
        draftEmailInd: _.get(this.config, 'markAsDraft', false),
        listActionCd: ActionCd.UPDATE,
        emailAttachment: [..._.get(this.config, 'email.emailAttachment', []), ...this.buildEmailUpdateAttachments()],
      };

      const request = new UpdateClaimEmailRqst();
      request.email = emailToUpdate;
      this.claimsApiService.updateClaimEmail(request).subscribe((response) => {
        this.resetForm();
        observer.next();
        observer.complete();
      });
    });
  }

  public sendNewEmail(): Observable<void> {
    return new Observable((observer) => {
      if (this.constants.isReadOnly) {
        observer.next();
        observer.complete();
        return;
      }
      const email = new ElectronicMessage();
      email.cc = this.ccControl.value;
      email.fromName = this.constants.user.displayName;
      email.messageBody = this.bodyControl.value;
      email.sentDateTime = new Date();
      email.subject = this.subjectControl.value;
      email.to = this.recipientControl.value;

      const request = new CreateClaimEmailRqst();
      request.attachments = this.buildAttachments();
      request.claimId = _.get(this.claim, 'claim.claimId');
      request.directionTypeCd = EmailDirectionTypeCd.OUTBOUND;
      request.draftInd = _.get(this.config, 'markAsDraft', false);
      request.email = email;
      request.html = true;
      request.templateTypeCd = _.get(this.template, 'value', undefined);

      this.claimsApiService.createClaimEmail(request).subscribe((response) => {
        if (response) {
          if (!this.claim.emails) {
            this.claim.emails = [];
          }

          this.claim.emails.push(response.email);
          this.resetForm();
          this.notificationService.showSnackBarMessage('Email Sent Successfully', {
            durationInMillis: 3000,
            status: 'SUCCESS',
          });
        }

        observer.next();
        observer.complete();
      });
    });
  }

  public dumpForm() {
    console.log(FormUtils.getControlState(this.formGroup, 'EMAIL', true));
  }

  private resetForm() {
    this.fileList = new Array<ReadFile>();
    this.uploadBadgeHiddenSubject.next(true);
    this.uploadBadgeSubject.next(undefined);
    this.attachmentsSubject.next(undefined);
    this.uploadingDownloadingFileSubject.next(false);
    this.filePicker.reset();

    this.clearFormFields();

    this.loadEmails();
  }

  public startUpload(): void {
    this.uploadingDownloadingFileSubject.next(true);
  }

  public cancelUpload(): void {
    this.uploadingDownloadingFileSubject.next(false);
  }

  public onReadStart(fileCount: number): void {
    this.uploadingDownloadingFileSubject.next(true);
  }

  public onReadEnd(fileCount: number): void {
    this.uploadingDownloadingFileSubject.next(false);
  }

  public onFilePicked(file: ReadFile): void {
    this.fileList.push(file);
    this.updateBadge();
    this.updateAttachments();
    this.uploadingDownloadingFileSubject.next(false);
  }

  private get subjectControl(): AbstractControl {
    return this.formGroup.get(EmailFormNames.Subject);
  }

  private get recipientControl(): AbstractControl {
    return this.formGroup.get(EmailFormNames.Recipient);
  }

  private get ccControl(): AbstractControl {
    return this.formGroup.get(EmailFormNames.Cc);
  }

  public get bodyControl(): AbstractControl {
    return this.formGroup.get(EmailFormNames.Body);
  }

  public emailActionHandler($event: EmailActionInterface): void {
    switch ($event.action) {
      case EmailActionTypes.Reply:
        this.isForwardingEmail = false;
        this.reply(_.cloneDeep($event.email));
        break;
      case EmailActionTypes.ReplyAll:
        this.isForwardingEmail = false;
        this.replyAll(_.cloneDeep($event.email));
        break;
      case EmailActionTypes.Forward:
        this.isForwardingEmail = true;
        this.forward(_.cloneDeep($event.email));
        break;
    }
  }

  /**
   * Builds the body for a reply or forward email with the meta information for email chains
   *
   * @param email the email to fetch the fields from
   */
  private buildReplyBody(email: EmailInteraction): string {
    return `<br />
        <br />
        <b>From:</b> ${email.senderEmailAddressTxt}<br />
        <b>Sent:</b> ${moment(email.auditInfo.createdTimestamp).format('dddd, MMM D, YYYY HH:mm A')}<br />
        <b>To:</b> ${email.recipientEmailAddressTxt}<br />
        <b>Subject:</b> ${email.subjectTxt}<br />
        <br />
        ${email.messageTxt}`;
  }

  private reply(email: EmailInteraction): void {
    this.handleClearAttachments();
    if (!!this.config.email) {
      this.config.email = undefined;
    }
    this.subjectControl.setValue(`RE: ${email.subjectTxt}`);
    this.recipientControl.setValue(email.senderEmailAddressTxt);
    this.bodyControl.setValue(this.buildReplyBody(email));
  }

  private replyAll(email: EmailInteraction): void {
    this.handleClearAttachments();
    if (!!this.config.email) {
      this.config.email = undefined;
    }
    this.subjectControl.setValue(`RE: ${email.subjectTxt}`);
    this.recipientControl.setValue(`${email.senderEmailAddressTxt}; ${email.recipientEmailAddressTxt}`);
    this.ccControl.setValue(email.ccEmailAddressTxt);
    this.bodyControl.setValue(this.buildReplyBody(email));
  }

  private forward(email: EmailInteraction): void {
    this.fileList = [];
    this.dms.value = [];
    this.config.email = new EmailInteraction();
    this.config.email.emailAttachment = _.map(email.emailAttachment, (emailAttachment) =>
      this.mapToNewEmailAttachment(emailAttachment)
    );
    this.updateAttachments();
    this.updateBadge();
    this.subjectControl.setValue(`FWD: ${email.subjectTxt}`);
    this.bodyControl.setValue(this.buildReplyBody(email));
  }

  private mapToNewEmailAttachment(attachment: EmailAttachment): EmailAttachment {
    const emailAttachment = new EmailAttachment();
    emailAttachment.listActionCd = ActionCd.ADD;
    emailAttachment.dmsDocTypeCd = _.get(attachment, 'dmsDocTypeCd', DmsDocTypeCd.CLAT);
    emailAttachment.dmsUrl = attachment.dmsUrl;
    emailAttachment.attachmentNameTxt = attachment.attachmentNameTxt;
    emailAttachment.attachmentMimeType = attachment.attachmentMimeType;

    return emailAttachment;
  }

  private loadEmails(): void {
    if (this.config.composeOnly) {
      // don't load emails if we are only composing
      return;
    }
    const newEmails = _.reduce(
      _.get(this.claim, 'emails', []),
      (total, email: EmailInteraction) => {
        return email && !email.readInd ? total + 1 : total;
      },
      0
    );
    this.unreadEmailsCountSubject.next(newEmails);

    const filterFunc = (email: EmailInteraction) => {
      return (
        !_.get(this.direction, 'value') ||
        this.direction.value === DirectionTypes.IncomingAndOutgoing ||
        (this.direction.value === DirectionTypes.Incoming && email.directionTypeCd === EmailDirectionTypeCd.INBOUND) ||
        (this.direction.value === DirectionTypes.Outgoing && email.directionTypeCd === EmailDirectionTypeCd.OUTBOUND)
      );
    };

    const emails = _.filter(this.claim.emails, (email: EmailInteraction) =>
      this.draftInd ? email.draftEmailInd : !email.draftEmailInd
    );
    if (this.draftInd && emails.length > 0 && emails[0].templateTypeCd === ClaimEmailTemplateTypeCd.POLICY_PAY) {
      this.employeeCacheService.request({ employeeId: this.claim.claim.examinedByEmployeeId }).subscribe((result) => {
        const email = emails[0];
        email.messageTxt = this.replaceTemplateVariables(email.messageTxt, result);
      });
    }

    if (!this.age.value || this.age.value === AgeTypes.NewestToOldest) {
      this.emailsSubject.next(
        _.sortBy(_.filter(emails, filterFunc), (email: EmailInteraction) => email.auditInfo.createdTimestamp).reverse()
      );
    } else {
      this.emailsSubject.next(
        _.sortBy(_.filter(emails, filterFunc), (email: EmailInteraction) => email.auditInfo.createdTimestamp)
      );
    }
  }

  public downloadAttachmentsClicked(): void {
    this.uploadingDownloadingFileSubject.next(true);

    const attachments: EmailAttachment[] = this.buildAttachments();
    const dmsAttachments = _.filter(attachments, (attachment: EmailAttachment) => !!attachment.dmsUrl);
    const nonDmsAttachments = _.filter(attachments, (attachment: EmailAttachment) => !attachment.dmsUrl);

    // download existing email attachments
    if (!!_.size(dmsAttachments)) {
      this.downloadDmsAttachmentFn(dmsAttachments)
        .pipe(
          take(1),
          finalize(() => this.uploadingDownloadingFileSubject.next(false))
        )
        .subscribe(() => { });
    } else {
      // Ensure to turn off the spinner if we aren't going to actually download anything
      this.uploadingDownloadingFileSubject.next(false);
    }

    _.forEach(nonDmsAttachments, (attachment: EmailAttachment) => {
      this.windowService.generateDownloadFile(
        attachment.attachmentDataTxt.contentType,
        decode(attachment.attachmentDataTxt.base64Data) as Uint8Array,
        attachment.attachmentDataTxt.fileName
      );
    });
  }

  private buildAttachments(): Array<EmailAttachment> {
    const emailAttachments = new Array<EmailAttachment>();

    if (this.isForwardingEmail || this.isEscalatingOrDeclining) {
      emailAttachments.push(...this.getActiveEmailConfigAttachments());
    }

    _.get(this, 'fileList', []).forEach((readFile) => {
      const emailAttachment = new EmailAttachment();
      const attachment = new Attachment();
      attachment.contentType = readFile.underlyingFile.type;
      attachment.fileName = readFile.underlyingFile.name;
      attachment.base64Data = btoa(readFile.content);
      emailAttachment.attachmentDataTxt = attachment;
      emailAttachment.listActionCd = ActionCd.ADD;
      emailAttachment.dmsDocTypeCd = DmsDocTypeCd.CLAT;
      emailAttachments.push(emailAttachment);
    });

    _.get(this.dms, 'value', []).forEach((dmsDoc: DocumentSearch) => {
      const emailAttachment = new EmailAttachment();
      emailAttachment.listActionCd = ActionCd.ADD;
      emailAttachment.dmsDocTypeCd = dmsDoc.cdt.docClass as DmsDocTypeCd;
      emailAttachment.dmsUrl = dmsDoc.cdt.timestamp;
      emailAttachments.push(emailAttachment);
    });

    return emailAttachments;
  }

  private buildEmailUpdateAttachments(): Array<EmailAttachment> {
    const emailAttachments = new Array<EmailAttachment>();

    _.get(this, 'fileList', []).forEach((readFile) => {
      const attachment = new Attachment();
      attachment.contentType = readFile.underlyingFile.type;
      attachment.fileName = readFile.underlyingFile.name;
      attachment.base64Data = btoa(readFile.content);

      const emailAttachment = new EmailAttachment();
      emailAttachment.listActionCd = ActionCd.ADD;
      emailAttachment.attachmentMimeType = attachment.contentType;
      emailAttachment.attachmentNameTxt = attachment.fileName;

      emailAttachment.attachmentDataTxt = attachment;
      emailAttachments.push(emailAttachment);
    });

    _.get(this.dms, 'value', []).forEach((dmsDoc: DocumentSearch) => {
      const emailAttachment = new EmailAttachment();
      emailAttachment.listActionCd = ActionCd.ADD;
      emailAttachment.dmsDocTypeCd = dmsDoc.cdt.docClass as DmsDocTypeCd;
      emailAttachment.dmsUrl = dmsDoc.cdt.timestamp;

      emailAttachments.push(emailAttachment);
    });

    return emailAttachments;
  }

  private resetAttachments(): void {
    this.fileList = [];
    this.dmsDocListSubject.next([]);
    this.dms.value = [];
    this.fileList = new Array<ReadFile>();
    this.uploadBadgeHiddenSubject.next(true);
    this.uploadBadgeSubject.next(undefined);
    this.attachmentsSubject.next(undefined);
    this.uploadingDownloadingFileSubject.next(false);
    this.filePicker.reset();
    this.updateBadge();
    this.updateAttachments();
  }

  public handleClearAttachments(): void {
    this.fileList = [];
    this.dms.value = [];
    if (_.get(this.config, 'email.emailAttachment')) {
      this.config.email.emailAttachment = _.chain(
        _.get(this.config, 'email.emailAttachment', [])
          .filter((attachment: EmailAttachment) => attachment.listActionCd !== ActionCd.ADD)
          .map((existingAttachment: EmailAttachment) => {
            existingAttachment.listActionCd = ActionCd.DELETE;
            return existingAttachment;
          })
      ).value();
    }

    this.updateBadge();
    this.updateAttachments();
    this.filePicker.reset();
  }

  private clearFormFields(): void {
    FormUtils.untouchAllControls(this.formGroup.get(EmailFormNames.Subject));
    FormUtils.untouchAllControls(this.formGroup.get(EmailFormNames.Recipient));
    FormUtils.untouchAllControls(this.formGroup.get(EmailFormNames.Cc));
    FormUtils.untouchAllControls(this.formGroup.get(EmailFormNames.Body));

    this.formGroup.get(EmailFormNames.Subject).setValue('');
    this.formGroup.get(EmailFormNames.Recipient).setValue('');
    this.formGroup.get(EmailFormNames.Cc).setValue('');
    this.formGroup.get(EmailFormNames.Body).setValue('');

    this.template.value = undefined;
  }

  private replaceTemplateVariables(template: string, examiner: GetInterfaceEmployeeResp): string {
    console.log(examiner);
    console.log((examiner.interfaceEmployee))
    const employeeNameFunc = (employee: InterfaceEmployee): string => {
      if (employee) {
        return `${employee.preferredFirstName || employee.firstName} ${employee.middleName || ''} ${employee.lastName
          }`;
      }
      return '';
    };

    const getEmployeePhone = (employee: GetEmployeeResp): string => {
      let employeePhoneNbr = '';
      if (employee.employeePhones && employee.employeePhones.length) {
        const phoneIndex = employee.employeePhones.length - 1;
        const areaCd = employee.employeePhones[phoneIndex].phoneCountryCd;
        const phone = employee.employeePhones[phoneIndex].phone;
        employeePhoneNbr = `${areaCd}-${phone}`
      }

      return employeePhoneNbr;
    }

    const lastPayment = _.last(this.claim.payments);

    // grab the most up to date total claim amount. if form group unavailable, default to claim claimed amount.
    const totalClaimAmount = this.currencyPipe.transform(
      this.claimsRegistrationFormGroup
        ? FormUtils.getNestedValue(
          this.claimsRegistrationFormGroup,
          ClaimsRegistrationFormNames.Commodities,
          CommodityLineItemsFormNames.LineItemClaimAmount
        )
        : this.claim.claim.claimedAmount
    );

    const NA = 'n/a'; // displayed when the value is not found

    const maxLiabilityAmt = this.currencyPipe.transform(this.claim.claim.maxLiabilityAmount);
    const paymentAmount = this.currencyPipe.transform(_.get(lastPayment, 'payment.amount'));
    const totalPayoutAmount = this.currencyPipe.transform(_.get(this.config.templateData, 'totalPayoutAmount'));

    const proNumber = this.conditioningService.conditionProNumber(this.claim.claim.proNbr, 10);

    const proNbrAndPickupDate = _.get(this.claim.claim, 'pickupDate')
      ? `${proNumber}, ${this.claim.claim.pickupDate}`
      : `${proNumber}`;

    // data to use when rendering the template
    const templateData = {
      APPROVE_LINK: this.buildApprovalLink(),
      CHK_NBR: lastPayment?.payment?.checkNbr || NA,
      CLAIMANT_NAME: this.claim?.claimant?.name1 || NA,
      CLAIMANT_REF_NBR: this.claim?.claim?.claimantRefNbr || NA,
      CLM_AMOUNT: totalClaimAmount || NA,
      // CLM_ID: _.get(this.claim.claim, 'claimId', NA),
      CLM_ID: this.claim?.claim?.claimId || NA,
      CONSIGNEE: _.get(this.config.templateData, 'consignee', NA),
      DRAFT_NOTE: _.get(lastPayment, 'payment.draftNote', NA),
      EMAIL_NOTE: _.get(lastPayment, 'payment.emailNote', NA),
      // EXAMINER_CITY: examiner.employee?.cityName || NA,
      EXAMINER_CITY: NA,
      EXAMINER_COUNTRY: NA, // we don't know the Examiner's country here
      EXAMINER_NAME: employeeNameFunc(examiner.interfaceEmployee),
      // EXAMINER_PHONE: getEmployeePhone(examiner),
      EXAMINER_PHONE: examiner.interfaceEmployee.businessPhoneTxt || examiner.interfaceEmployee.dsrCellPhone || examiner.interfaceEmployee.businessPhone || NA ,
      // EXAMINER_ROLE: examiner.jobPosition?.jobDescription,
      EXAMINER_ROLE: examiner.interfaceEmployee.jobDescription || NA,
      // EXAMINER_STATE: (examiner.employee?.stateCd || NA),
      EXAMINER_STATE: NA,
      // EXAMINER_STREET: (examiner.employee?.address1 || NA),
      EXAMINER_STREET: ( NA),
      // EXAMINER_ZIP: examiner.employee?.zip || NA,
      EXAMINER_ZIP:  NA,
      FILING_DT: _.get(this.claim.claim, 'filingDate', NA),
      MAX_LBLTY_AMT: _.defaultTo(maxLiabilityAmt, NA),
      PMT_AMT: _.defaultTo(paymentAmount, NA),
      PRO_NBR_AND_PKUP_DT: proNbrAndPickupDate,
      PRO_NBR_TXT: proNumber,
      SHIPPER: _.get(this.config.templateData, 'shipper', NA),
      SHP_INST_ID: `${_.get(this.claim.claim, 'shipmentInstId', NA)}`,
      SHPM_DLVRY_DT: _.get(this.claim.claim, 'shipmentDeliveryDate', NA),
      TOTAL_PAYOUT_AMT: _.defaultTo(totalPayoutAmount, NA),
      CUR_DATE: moment(new Date()).format('MM/DD/YYYY'),
    };

    return this.render(template, templateData);
  }

  // Render the Template using the passed data values
  private render(template: string, data: { [key: string]: string }): string {
    const rendered = _.reduce(
      data,
      (templ, value, key) => {
        return _.replace(templ, new RegExp(`{{${key}}}`, 'g'), value);
      },
      template
    );
    return rendered;
  }

  private buildApprovalLink(): string {
    const location = this.document.location;
    const originURL = location.origin;
    let pathURL = `/${RouterUriComponents.CLAIMS_APPROVAL_PAGE}`;

    return `${originURL}${pathURL}/${this.claim.claim.claimId}`;
  }

  private updateBadge(): void {
    const emailConfigCount = this.getActiveEmailConfigAttachments().length;
    const fileCount = _.get(this.fileList, 'length', 0);
    const dmsCount = _.get(this.dms, 'value.length', 0);
    const totalCount = emailConfigCount + fileCount + dmsCount;
    this.uploadBadgeHiddenSubject.next(totalCount === 0);
    this.uploadBadgeSubject.next(`${totalCount}`);
  }

  private updateAttachments(): void {
    const activeEmailAttachments = this.getActiveEmailConfigAttachments();
    const files = _.map(activeEmailAttachments, (att) => att.attachmentNameTxt);
    // files.push(_.get(this, 'fileList', []).map((f) => f.name));
    this.fileList.forEach(f => {
      files.push(f.name)
    })
    files.push(_.get(this.dms, 'value', []).map((doc) => this.documentSearchPipe.transform(doc)));
    this.attachmentsSubject.next(_.flatten(files).join(', '));
  }

  private getActiveEmailConfigAttachments(): EmailAttachment[] {
    return _.chain(
      _.get(this.config, 'email.emailAttachment', []).filter(
        (attachment: EmailAttachment) => attachment.listActionCd !== ActionCd.DELETE
      )
    ).value();
  }

  private setEmailTemplate(templateTypeCd: ClaimEmailTemplateTypeCd) {
    zip(
      this.employeeCacheService.request({ employeeId: this.claim.claim.examinedByEmployeeId }),
      this.emailTemplateCacheService.request({ templateTypeCd })
    ).subscribe((results) => {
      const employeeresp = <GetInterfaceEmployeeResp>_.get(results, '[0]');
      const templateContent = _.get(results, '[1].claimEmailTemplates[0].bodyTxt');
      const bodyValue = this.replaceTemplateVariables(templateContent, employeeresp);
      this.emptyEmailBodyValue = bodyValue;
      this.bodyControl.setValue(bodyValue);
    });
  }

  private updateIsEmailBodyEmpty() {
    this.isEmailBodyEmptySubject.next(this.emptyEmailBodyValue === this.bodyControl.value);
  }
}
