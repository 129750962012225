import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormUtils } from '../classes/form-utils.class';
import { ClaimsForShipmentCacheService } from '../services/claims-for-shipments-cache/claims-for-shipment-cache.service';
import { ProValidationErrorWarningType } from '../enums/pro-validation-error-warning-type.enum';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { ClaimsRegistrationService } from '../services/claims-registration/claims-registration.service';

export function duplicateClaimValidator(
  claimsForShipmentCacheService: ClaimsForShipmentCacheService,
  proNbrCtrl: AbstractControl,
  claimsRegistrationService: ClaimsRegistrationService,
  formatValidationService: FormatValidationService
): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!control || !proNbrCtrl) {
      return of(null);
    }
    const claimId = _.get(claimsRegistrationService.claim, 'claim.claimId', undefined);
    let claimsForShipmentCacheRequestId;

    if ((!proNbrCtrl.value && !control.value) || !claimsForShipmentCacheService || control.disabled) {
      FormUtils.removeWarning(control, ProValidationErrorWarningType.DuplicateClaim);
      return of(null);
    }
    
    if (!!control.value) {
      claimsForShipmentCacheRequestId = control.value;
    } else {
      claimsForShipmentCacheRequestId = proNbrCtrl.value;
      
      if (!formatValidationService.isValidProNumber(claimsForShipmentCacheRequestId)) {
        FormUtils.removeWarning(control, ProValidationErrorWarningType.DuplicateClaim);
        return of(null);
      }
    }

    return claimsForShipmentCacheService.getClaimsForShipment(claimsForShipmentCacheRequestId).pipe(
      map(
        (claims: string[]) => {
          if (hasDuplicateClaim(claims, claimId)) {
            FormUtils.setWarning(control, ProValidationErrorWarningType.DuplicateClaim);
          } else {
            FormUtils.removeWarning(control, ProValidationErrorWarningType.DuplicateClaim);
          }
          return null;
        },
        errors => {
          FormUtils.removeWarning(control, ProValidationErrorWarningType.DuplicateClaim);
          return null;
        }
      ),
      catchError(() => {
        FormUtils.removeWarning(control, ProValidationErrorWarningType.DuplicateClaim);
        return of(null);
      })
    );
  };
}

function hasDuplicateClaim(claimsResp: string[], claimId: string): boolean {
  return !!_.size(_.filter(claimsResp, claimRespId => claimRespId !== claimId));
}
