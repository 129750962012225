import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import {
  ServiceCenterChargeabilityFormNames,
  ServiceCenterChargeabilityOtherSicsFormNames,
  ServiceCenterChargeabilityLoadAtSicsFormNames,
} from '../../enums/FormControlNames/service-center-chargeability-form-names.enum';
import { FormUtils } from '../../classes/form-utils.class';
import { Shipment } from '@xpo-ltl/sdk-shipmentods';
import { ChargeableServiceCenter, Claim, GetClaimResp } from '@xpo-ltl-2.0/sdk-claims';
import * as _ from 'lodash';
import { sicValidatorFunction } from '../../validators';
import { ServiceCenterCacheService } from '../../services/service-center-cache.service';
import { BehaviorSubject } from 'rxjs';
import { ActionCd, ClaimInternalStatusCd, ClaimChargeabilityReasonCdHelper, CurrencyCd, ClaimChargeabilityReasonCd, ClaimCurrencyCd } from '@xpo-ltl/sdk-common';
import { distinctUntilChanged } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ClaimNoteTypeCd } from '@xpo-ltl/sdk-common';
import { AppConstantsService } from '../../services/app-constants.service';
import { UserRole, ClaimEditState } from '../../enums';
import { serviceCentersTotalPercentageValidator } from '../../validators/service-centers-total-percentage-validator.directive';
import { UnitConversion } from '../../classes';
import { ServiceCenterChargeabilityService } from '../../services/service-center-chargeability/service-center-chargeability.service';
import { ErrorStateMatcher } from 'src/app/classes/error-state-matcher';

@Component({
  selector: 'app-service-center-chargeability',
  templateUrl: './service-center-chargeability.component.html',
  styleUrls: ['./service-center-chargeability.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCenterChargeabilityComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public ServiceCenterChargeabilityFormNames = ServiceCenterChargeabilityFormNames;
  public ServiceCenterChargeabilityOtherSicsFormNames = ServiceCenterChargeabilityOtherSicsFormNames;
  public ServiceCenterChargeabilityLoadAtSicsFormNames = ServiceCenterChargeabilityLoadAtSicsFormNames;

  public FormUtils = FormUtils;

  public readonly MaxNotesCharacters = 250;
  public readonly numbersExp = '[\\d\\.]+';

  private shipment: Shipment;
  private claim: GetClaimResp;
  private chargeableServiceCenters: ChargeableServiceCenter[];
  public loadAtSics: string[];
  private claimEditState: ClaimEditState;

  public approvedAmount: number;

  public readonly ClaimChargeabilityReasonCd = ClaimChargeabilityReasonCdHelper.values();

  private stateChangedSubject = new BehaviorSubject<boolean>(false);
  public stateChanged$ = this.stateChangedSubject.asObservable();

  private isReadonlySubject = new BehaviorSubject<boolean>(false);
  public isReadonly$ = this.isReadonlySubject.asObservable();

  private isSameOriginDestSicSubject = new BehaviorSubject<boolean>(false);
  public isSameOriginDestSic$ = this.isSameOriginDestSicSubject.asObservable();
  private set isSameOriginDestSic(val: boolean) {
    this.isSameOriginDestSicSubject.next(val);
  }
  private get isSameOriginDestSic(): boolean {
    return this.isSameOriginDestSicSubject.value;
  }

  private isOriginReadonlySubject = new BehaviorSubject<boolean>(true);
  public isOriginReadonly$ = this.isOriginReadonlySubject.asObservable();

  private isDestReadonlySubject = new BehaviorSubject<boolean>(true);
  public isDestReadonly$ = this.isDestReadonlySubject.asObservable();

  private initialFormValue;
  private unsubscriber: Unsubscriber = new Unsubscriber();

  public totalPercentageErrorStateMatcher = new ErrorStateMatcher(this.hasTotalPercentageError.bind(this));
  public loadAtSICsPanelOpen = true;

  constructor(
    private matDialogRef: MatDialogRef<ServiceCenterChargeabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private serviceCenterCache: ServiceCenterCacheService,
    private appConstantsService: AppConstantsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.shipment = _.get(data, 'shipment.shipment');
    this.claim = _.get(data, 'claim');
    this.chargeableServiceCenters = _.cloneDeep(_.get(data, 'chargeableServiceCenters', []));
    this.loadAtSics = _.get(data, 'loadAtSics');
    this.claimEditState = _.get(data, 'claimEditState');
  }

  ngOnInit() {
    this.initFormGroup();
    this.initialFormValue = this.formGroup.getRawValue();
    this.initWatchers();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }

  private initFormGroup() {
    const originSicFromShipment: string = _.get(this.shipment, 'originTerminalSicCd', undefined);
    const destSicFromShipment: string = _.get(this.shipment, 'destinationTerminalSicCd', undefined);
    const hasOriginDestSic: boolean = !!originSicFromShipment && !!destSicFromShipment;
    let originPctAloc: number, destPctAloc: number;
    let loadAtSicsPctAloc: number[];
    let reasonCode: string;

    const loadAtSicsAPICallFailed = !this.loadAtSics;
    if (!hasOriginDestSic || loadAtSicsAPICallFailed) {
      this.loadAtSics = [];
    }

    // initialize serviceCenterChargeabilityService
    const serviceCenterChargeabilityService = new ServiceCenterChargeabilityService(originSicFromShipment, destSicFromShipment, this.claim, this.chargeableServiceCenters, this.loadAtSics);
    this.loadAtSics = serviceCenterChargeabilityService.getFilteredLoadAtSics();

    this.isSameOriginDestSic = hasOriginDestSic && originSicFromShipment === destSicFromShipment;

    const [savedOriginPct, savedDestPct] = serviceCenterChargeabilityService.getCurrentlySavedOriginDestAlocationPct();
    const savedLoadAtSicsPctAloc = serviceCenterChargeabilityService.getCurrentlySavedLoadAtAlocationPct();

    if (this.isSameOriginDestSic) {
      originPctAloc = savedOriginPct || 100;
      destPctAloc = 0;
      // currently saved aloc. percentages for 'Load At SICs' (0 if the load at SIC is not present)
      loadAtSicsPctAloc = savedLoadAtSicsPctAloc.map(perc => perc || 0);
    } else {
      if (_.size(this.chargeableServiceCenters) > 0) {
        if (serviceCenterChargeabilityService.shouldKeepExistingChargeability()) {
          reasonCode = this.chargeableServiceCenters[0].reasonCd;
          // keep already saved chargeability
          originPctAloc = savedOriginPct || 0;
          destPctAloc = savedDestPct || 0;
          loadAtSicsPctAloc = savedLoadAtSicsPctAloc.map(perc => perc || 0);
        } else {
          if (loadAtSicsAPICallFailed || !hasOriginDestSic) {
            // mark all chargeabilities for deletion
            this.chargeableServiceCenters.forEach(chargeableServiceCenter => (chargeableServiceCenter.listActionCd = ActionCd.DELETE));

            originPctAloc = 0;
            destPctAloc = 0;
            loadAtSicsPctAloc = [];
          } else {
            // mark other service centers for deletion
            const otherServiceCenters = this.getOtherServiceCenters();
            otherServiceCenters.forEach(chargeableServiceCenter => (chargeableServiceCenter.listActionCd = ActionCd.DELETE));

            // apply equally distributed chargeability
            [originPctAloc, destPctAloc, loadAtSicsPctAloc] = serviceCenterChargeabilityService.getEquallyDistributedChargeability();
          }
        }
      } else {
        if (loadAtSicsAPICallFailed || !hasOriginDestSic) {
          originPctAloc = 0;
          destPctAloc = 0;
          loadAtSicsPctAloc = [];
        } else {
          // apply equally distributed chargeability
          [originPctAloc, destPctAloc, loadAtSicsPctAloc] = serviceCenterChargeabilityService.getEquallyDistributedChargeability();
        }
      }
    }

    this.approvedAmount = +_.get(this.claim.claim, 'approvedAmount', 0);
    const chargeabilityNote = this.claim.notes.find(n => n.typeCd === ClaimNoteTypeCd.SERVICE_CENTER_CHARGEABILITY);

    this.isOriginReadonlySubject.next(!!originSicFromShipment || !this.isEnabledForState());
    this.isDestReadonlySubject.next(!!destSicFromShipment || !this.isEnabledForState());
    this.isReadonlySubject.next(!this.isEnabledForState());

    this.formGroup = this.formBuilder.group({
      [ServiceCenterChargeabilityFormNames.OriginSic]: [originSicFromShipment || '', Validators.required, sicValidatorFunction(this.serviceCenterCache)],
      [ServiceCenterChargeabilityFormNames.OriginPctAloc]: [originPctAloc, [Validators.required, Validators.min(0), Validators.max(100)]],
      [ServiceCenterChargeabilityFormNames.OriginAmount]: [originPctAloc * this.approvedAmount * 0.01],
      [ServiceCenterChargeabilityFormNames.DestSic]: [this.isSameOriginDestSic ? '' : destSicFromShipment || ''],
      [ServiceCenterChargeabilityFormNames.DestPctAloc]: [destPctAloc],
      [ServiceCenterChargeabilityFormNames.DestAmount]: [destPctAloc * this.approvedAmount * 0.01],
      [ServiceCenterChargeabilityFormNames.Notes]: [chargeabilityNote ? chargeabilityNote.noteTxt : ''],
      [ServiceCenterChargeabilityFormNames.LoadAtSics]: this.buildLoadAtSicsFormArray(loadAtSicsPctAloc),
      [ServiceCenterChargeabilityFormNames.OtherSics]: this.formBuilder.array([]),
      [ServiceCenterChargeabilityFormNames.ReasonCode]: [reasonCode],
    });

    if (!reasonCode) {
      this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).disable();
    }

    if (!this.isSameOriginDestSic) {
      this.formGroup.get(ServiceCenterChargeabilityFormNames.DestSic).setValidators([Validators.required]);
      this.formGroup.get(ServiceCenterChargeabilityFormNames.DestSic).setAsyncValidators([sicValidatorFunction(this.serviceCenterCache)]);

      this.formGroup.get(ServiceCenterChargeabilityFormNames.DestPctAloc).setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
    }

    if (!hasOriginDestSic) {
      this.disableOriginDestinationSicFields();
    }

    this.formGroup.setValidators(serviceCentersTotalPercentageValidator());
    if (!this.isEnabledForState()) {
      this.formGroup.disable();
    }
    // touch origin, destination sic controls to trigger validation
    FormUtils.touchAllControls(this.originSicCtrl);
    FormUtils.touchAllControls(this.destSicCtrl);

    this.setOtherLocationsFg();
  }

  private buildLoadAtSicsFormArray(loadAtSicsPctAloc: number[]): UntypedFormArray {
    const formArray = [];
    this.loadAtSics.forEach((sicCD, i) => {
      const pctAloc = loadAtSicsPctAloc[i];

      formArray.push(
        this.formBuilder.group({
          [ServiceCenterChargeabilityLoadAtSicsFormNames.Sic]: [sicCD, null, sicValidatorFunction(this.serviceCenterCache)],
          [ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc]: [pctAloc],
          [ServiceCenterChargeabilityLoadAtSicsFormNames.Amount]: [this.approvedAmount * pctAloc * 0.01],
        })
      );
    });

    return this.formBuilder.array(formArray);
  }

  private disableOriginDestinationSicFields(): void {
    this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginSic).disable();
    this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginAmount).disable();
    this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginPctAloc).disable();
    this.formGroup.get(ServiceCenterChargeabilityFormNames.DestSic).disable();
    this.formGroup.get(ServiceCenterChargeabilityFormNames.DestAmount).disable();
    this.formGroup.get(ServiceCenterChargeabilityFormNames.DestPctAloc).disable();
  }

  private isEnabledForState(): boolean {
    // examiner not assigned to this claim shouldn't be able to edit chargeability
    if (this.appConstantsService.isExaminer) {
      const isAssignedExaminer = _.get(this.claim, 'claim.examinedByEmployeeId', undefined) === _.get(this.appConstantsService.user, 'employeeId');
      if (!isAssignedExaminer) {
        return false;
      }
    }

    const enabledStatuses = {
      [UserRole.Examiner]: [ClaimInternalStatusCd.UNSUBMITTED, ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW, ClaimInternalStatusCd.APPROVED, ClaimInternalStatusCd.DECLINED],
      [UserRole.Manager]: [ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW, ClaimInternalStatusCd.APPROVED, ClaimInternalStatusCd.DECLINED],
      [UserRole.Director]: [ClaimInternalStatusCd.SUBMITTED, ClaimInternalStatusCd.UNDER_REVIEW, ClaimInternalStatusCd.APPROVED, ClaimInternalStatusCd.DECLINED],
    };

    return (
      _.some(enabledStatuses[this.appConstantsService.userRole], (status: ClaimInternalStatusCd) => status === this.claim.claim.internalStatusCd) && this.claimEditState !== ClaimEditState.Payment
    );
  }

  private initWatchers() {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (!this.isEqualToInitialDefaultValues() && !this.isReasonCodeUpdated()) {
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).enable({ onlySelf: true, emitEvent: false });
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).setValidators(Validators.required);
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).markAsTouched();
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        } else if (this.isEqualToInitialDefaultValues() && this.isReasonCodeUpdated() && this.isInitialReasonCodeEmpty()) {
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).disable({ onlySelf: true, emitEvent: false });
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).reset(null, { onlySelf: true, emitEvent: false });
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).markAsPending();
        } else if (this.isNotesUpdated() || (this.isReasonCodeUpdated() && !this.isInitialReasonCodeEmpty())) {
          this.formGroup.markAsTouched();
        } else {
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).disable({ onlySelf: true, emitEvent: false });
          this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).clearValidators();
        }
      });

    this.formGroup.statusChanges
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  private isNotesUpdated() {
    return !_.isEqual(_.pick(this.initialFormValue, ['notes']), _.pick(this.formGroup.getRawValue(), ['notes']));
  }

  private isReasonCodeUpdated() {
    return (
      !!this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).value && !_.isEqual(_.pick(this.initialFormValue, ['reasonCode']), _.pick(this.formGroup.getRawValue(), ['reasonCode']))
    );
  }

  private isInitialReasonCodeEmpty() {
    return !_.pick(this.initialFormValue, ['reasonCode']).reasonCode;
  }

  /** compare to initially set default values of origin sic, dest sic, % allocations*/
  private isEqualToInitialDefaultValues(): boolean {
    const currentValue = this.formGroup.getRawValue();
    const roundValue = value => UnitConversion.round(_.toNumber(value), 2);

    const originSicKey = ServiceCenterChargeabilityFormNames.OriginSic;
    const destSicKey = ServiceCenterChargeabilityFormNames.DestSic;
    const originPctAlocKey = ServiceCenterChargeabilityFormNames.OriginPctAloc;
    const destPctAlocKey = ServiceCenterChargeabilityFormNames.DestPctAloc;
    const loadAtSicsKey = ServiceCenterChargeabilityFormNames.LoadAtSics;
    const loadAtSicPctAlocKey = ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc;

    currentValue[originSicKey] = _.toUpper(currentValue[originSicKey]);
    currentValue[destSicKey] = _.toUpper(currentValue[destSicKey]);

    currentValue[originPctAlocKey] = roundValue(currentValue[originPctAlocKey]);
    currentValue[destPctAlocKey] = roundValue(currentValue[destPctAlocKey]);
    currentValue[loadAtSicsKey].forEach(loadAtSicValue => (loadAtSicValue[loadAtSicPctAlocKey] = roundValue(loadAtSicValue[loadAtSicPctAlocKey])));

    const isEqual = _.isEqual(_.omit(this.initialFormValue, ['reasonCode', 'otherSics', 'notes']), _.omit(currentValue, ['reasonCode', 'otherSics', 'notes']));
    if (!isEqual) {
      return false;
    }

    // compare others (filtering to skip empty entries)
    const initialOtherSics = this.initialFormValue.otherSics.filter(s => !!s.sic);
    const currentOtherSics = currentValue.otherSics.filter(s => !!s.sic);
    if (_.size(initialOtherSics) !== _.size(currentOtherSics)) {
      return false;
    } else {
      let othersAreEqual = true;
      initialOtherSics.forEach((initialSic, i) => {
        const currentSic = currentOtherSics[i];
        if (initialSic.sic !== currentSic.sic || _.round(initialSic.pctAloc, 2) !== _.round(currentSic.pctAloc, 2)) {
          othersAreEqual = false;
        }
      });
      return othersAreEqual;
    }
  }

  private getOtherServiceCenters() {
    const originDestSics = [];

    if (_.get(this.shipment, 'originTerminalSicCd', undefined)) {
      originDestSics.push(this.shipment.originTerminalSicCd);
    }
    if (_.get(this.shipment, 'destinationTerminalSicCd', undefined)) {
      originDestSics.push(this.shipment.destinationTerminalSicCd);
    }
    const otherServiceCenters = this.chargeableServiceCenters
      .filter(x => originDestSics.indexOf(x.sicCd.toUpperCase()) === -1)
      .filter(x => this.loadAtSics.indexOf(x.sicCd.toUpperCase()) === -1)
      .filter(x => x.listActionCd !== ActionCd.DELETE);
    return otherServiceCenters;
  }

  private setOtherLocationsFg() {
    const otherServiceCenters = this.getOtherServiceCenters();
    if (otherServiceCenters && otherServiceCenters.length) {
      otherServiceCenters.forEach(sc => {
        const otherLocFG = this.formBuilder.group({
          [ServiceCenterChargeabilityOtherSicsFormNames.Sic]: [sc.sicCd, null, sicValidatorFunction(this.serviceCenterCache)],
          [ServiceCenterChargeabilityOtherSicsFormNames.PctAloc]: [sc.chargePercentage],
          [ServiceCenterChargeabilityOtherSicsFormNames.Amount]: [this.approvedAmount * sc.chargePercentage * 0.01],
        });
        this.otherLocs.push(otherLocFG);
      });
    } else {
      this.otherLocs.push(
        this.formBuilder.group({
          [ServiceCenterChargeabilityOtherSicsFormNames.Sic]: ['', null, sicValidatorFunction(this.serviceCenterCache)],
          [ServiceCenterChargeabilityOtherSicsFormNames.PctAloc]: [''],
          [ServiceCenterChargeabilityOtherSicsFormNames.Amount]: [0],
        })
      );
    }
  }

  public addAnother() {
    const formArray = this.otherLocs;
    formArray.push(
      this.formBuilder.group({
        [ServiceCenterChargeabilityOtherSicsFormNames.Sic]: ['', null, sicValidatorFunction(this.serviceCenterCache)],
        [ServiceCenterChargeabilityOtherSicsFormNames.PctAloc]: [''],
        [ServiceCenterChargeabilityOtherSicsFormNames.Amount]: [0],
      })
    );
  }

  public validateOriginSic() {
    this.checkForDuplicateSic();
  }

  public validateOriginPctAloc() {
    this.originAmountCtrl.setValue(+_.get(this.originPctAlocCtrl, 'value', 0) * this.approvedAmount * 0.01);
  }

  public validateDestSic() {
    this.checkForDuplicateSic();
  }

  public validateDestPctAloc() {
    this.destAmountCtrl.setValue(+_.get(this.destPctAlocCtrl, 'value', 0) * this.approvedAmount * 0.01);
  }

  public validateOtherSic(i: number) {
    this.checkForDuplicateSic();

    const otherSic = this.otherLocs.at(i).get(ServiceCenterChargeabilityOtherSicsFormNames.Sic);
    const otherPctAloc = this.otherLocs.at(i).get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc);

    if (!otherSic.value && !otherPctAloc.value) {
      this.removeError(otherSic, 'required');
      otherSic.updateValueAndValidity();
      this.removeError(otherPctAloc, 'required');
      otherPctAloc.updateValueAndValidity();
    } else {
      if (!otherSic.value) {
        otherSic.setErrors({ required: true });
        otherSic.markAsTouched();
      }
      if (!otherPctAloc.value) {
        otherPctAloc.setErrors({ required: true });
        otherPctAloc.markAsTouched();
      }
    }
  }

  public validateOtherPctAloc(i: number) {
    const otherPctAloc = this.otherLocs.at(i).get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc);
    const otherAmountCtrl = this.otherLocs.at(i).get(ServiceCenterChargeabilityOtherSicsFormNames.Amount);

    otherAmountCtrl.setValue(+_.get(otherPctAloc, 'value', 0) * this.approvedAmount * 0.01);
    this.validateOtherSic(i);
  }

  public validateLoadAtPctAloc(i: number) {
    const loadAtPctAloc = this.loadAtLocs.at(i).get(ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc);
    const loadAtAmountCtrl = this.loadAtLocs.at(i).get(ServiceCenterChargeabilityLoadAtSicsFormNames.Amount);

    loadAtAmountCtrl.setValue(+_.get(loadAtPctAloc, 'value', 0) * this.approvedAmount * 0.01);
  }

  private checkForDuplicateSic() {
    const sicCodes = [];

    if (!_.isEmpty(this.originSicCtrl.value)) {
      sicCodes.push(this.originSicCtrl.value.toUpperCase());
    }
    if (!_.isEmpty(this.destSicCtrl.value)) {
      sicCodes.push(this.destSicCtrl.value.toUpperCase());
    }
    if (this.loadAtLocs && this.loadAtLocs.controls && this.loadAtLocs.controls.length > 0) {
      this.loadAtLocs.controls.forEach(loadAtLoc => {
        if (!_.isEmpty(loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).value)) {
          sicCodes.push(loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).value.toUpperCase());
        }
      });
    }
    if (this.otherLocs && this.otherLocs.controls && this.otherLocs.controls.length > 0) {
      this.otherLocs.controls.forEach(otherLoc => {
        if (!_.isEmpty(otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).value)) {
          sicCodes.push(otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).value.toUpperCase());
        }
      });
    }

    if (!_.isEmpty(this.originSicCtrl.value)) {
      if (sicCodes.filter(x => x === this.originSicCtrl.value.toUpperCase()).length > 1) {
        this.originSicCtrl.setErrors({ notUnique: true });
      } else {
        this.removeError(this.originSicCtrl, 'notUnique');
        this.originSicCtrl.updateValueAndValidity({ onlySelf: true });
      }
    }

    if (!_.isEmpty(this.destSicCtrl.value) && !this.isSameOriginDestSic) {
      if (sicCodes.filter(x => x === this.destSicCtrl.value.toUpperCase()).length > 1) {
        this.destSicCtrl.setErrors({ notUnique: true });
      } else {
        this.removeError(this.destSicCtrl, 'notUnique');
        this.destSicCtrl.updateValueAndValidity({ onlySelf: true });
      }
    }

    if (this.loadAtLocs && this.loadAtLocs.controls && this.loadAtLocs.controls.length > 0) {
      this.loadAtLocs.controls.forEach(loadAtLoc => {
        if (!_.isEmpty(loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).value)) {
          if (sicCodes.filter(x => x === loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).value.toUpperCase()).length > 1) {
            loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).setErrors({ notUnique: true });
          } else {
            this.removeError(loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic), 'notUnique');
            loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic).updateValueAndValidity({ onlySelf: true });
          }
        }
      });
    }
    if (this.otherLocs && this.otherLocs.controls && this.otherLocs.controls.length > 0) {
      this.otherLocs.controls.forEach(otherLoc => {
        if (!_.isEmpty(otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).value)) {
          if (sicCodes.filter(x => x === otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).value.toUpperCase()).length > 1) {
            otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).setErrors({ notUnique: true });
          } else {
            this.removeError(otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic), 'notUnique');
            otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).updateValueAndValidity({ onlySelf: true });
          }
        }
      });
    }
  }

  private get originSicCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginSic);
  }

  private get originPctAlocCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginPctAloc);
  }

  private get originAmountCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.OriginAmount);
  }

  private get destSicCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.DestSic);
  }

  private get destPctAlocCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.DestPctAloc);
  }

  private get destAmountCtrl(): AbstractControl {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.DestAmount);
  }

  private removeError(ctrl: AbstractControl, key: string) {
    if (ctrl.hasError(key)) {
      ctrl.errors[key] = null;
    }
  }

  public get otherLocs(): UntypedFormArray {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.OtherSics) as UntypedFormArray;
  }

  public get loadAtLocs(): UntypedFormArray {
    return this.formGroup.get(ServiceCenterChargeabilityFormNames.LoadAtSics) as UntypedFormArray;
  }

  public cancelClicked() {
    this.matDialogRef.close();
  }

  public saveClicked() {
    if (!this.formGroup.valid) {
      return;
    }
    const newChargeabilities = this.chargeableServiceCenters || [];

    this.addOrUpdateChargeability(newChargeabilities, this.originSicCtrl, this.originPctAlocCtrl, this.originAmountCtrl);
    if (!this.isSameOriginDestSic) {
      this.addOrUpdateChargeability(newChargeabilities, this.destSicCtrl, this.destPctAlocCtrl, this.destAmountCtrl);
    }
    if (this.loadAtLocs && this.loadAtLocs.controls && this.loadAtLocs.controls.length > 0) {
      this.loadAtLocs.controls.forEach(loadAtLoc => {
        this.addOrUpdateChargeability(
          newChargeabilities,
          loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Sic),
          loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.PctAloc),
          loadAtLoc.get(ServiceCenterChargeabilityLoadAtSicsFormNames.Amount)
        );
      });
    }
    const otherSicsCDsInForm: string[] = [];
    if (this.otherLocs && this.otherLocs.controls && this.otherLocs.controls.length > 0) {
      this.otherLocs.controls.forEach(otherLoc => {
        const sicValue = otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic).value.toUpperCase();
        if (sicValue) {
          otherSicsCDsInForm.push(sicValue);
        }
        this.addOrUpdateChargeability(
          newChargeabilities,
          otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Sic),
          otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.PctAloc),
          otherLoc.get(ServiceCenterChargeabilityOtherSicsFormNames.Amount)
        );
      });
    }
    if (this.isReasonCodeUpdated()) {
      this.addReasonCodeToChargeabilityCenters(newChargeabilities);
    }
    this.checkForOthersDeletion(otherSicsCDsInForm);
    this.matDialogRef.close({ serviceCenters: newChargeabilities, note: this.formGroup.get(ServiceCenterChargeabilityFormNames.Notes).value });
  }

  private checkForOthersDeletion(newChargeabilitiesOtherSicsCDs: string[]) {
    const otherServiceCenters = this.getOtherServiceCenters();
    otherServiceCenters.forEach(existingOtherServiceCenter => {
      if (!newChargeabilitiesOtherSicsCDs.includes(existingOtherServiceCenter.sicCd.toUpperCase())) {
        existingOtherServiceCenter.listActionCd = ActionCd.DELETE;
      }
    });
  }

  private addReasonCodeToChargeabilityCenters(chargeabilityCenters: ChargeableServiceCenter[]) {
    const reasonCd = this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).value;
    chargeabilityCenters.forEach((chargeabilityCenter: ChargeableServiceCenter) => {
      chargeabilityCenter.reasonCd = reasonCd;
      if (chargeabilityCenter.listActionCd !== ActionCd.ADD && chargeabilityCenter.listActionCd !== ActionCd.UPDATE) {
        chargeabilityCenter.listActionCd = ActionCd.UPDATE;
      }
    });
  }

  private addOrUpdateChargeability(chargeabilities: ChargeableServiceCenter[], sicCtrl: AbstractControl, pctAlocCtrl: AbstractControl, chargeAmountCtrl: AbstractControl) {
    const index = chargeabilities.findIndex(x => x.sicCd.toUpperCase() === sicCtrl.value.toUpperCase());
    const roundValue = value => UnitConversion.round(_.toNumber(value), 2);

    if (index < 0) {
      if (sicCtrl.value && pctAlocCtrl.value) {
        const newChargeability = new ChargeableServiceCenter();
        newChargeability.sicCd = sicCtrl.value.toUpperCase();
        newChargeability.chargePercentage = roundValue(pctAlocCtrl.value);
        newChargeability.claimId = _.get(this.claim, 'claim.claimId');
        newChargeability.currencyCd = _.get(this.claim, 'claim.currencyCd', ClaimCurrencyCd.US_DOLLARS);
        newChargeability.chargeAmount = _.defaultTo(_.toInteger(chargeAmountCtrl.value), 0);
        newChargeability.listActionCd = ActionCd.ADD;
        newChargeability.reasonCd = this.formGroup.get(ServiceCenterChargeabilityFormNames.ReasonCode).value;
        chargeabilities.push(newChargeability);
      }
    } else {
      const oldPercent = roundValue(chargeabilities[index].chargePercentage);
      const newPercent = roundValue(pctAlocCtrl.value);
      // if it was marked for deletion but then its same percentage is reentered manually, we should keep this entry
      if (chargeabilities[index].listActionCd === ActionCd.DELETE && oldPercent === newPercent) {
        chargeabilities[index].listActionCd = ActionCd.NO_ACTION;
      } else if (oldPercent !== newPercent) {
        // update all entries which percentage was changed manually (marked for deletion ones included)
        chargeabilities[index].chargePercentage = newPercent;
        chargeabilities[index].listActionCd = ActionCd.UPDATE;
      }
    }
  }

  private hasTotalPercentageError(): boolean {
    return this.formGroup.hasError('max') || this.formGroup.hasError('not100');
  }
}
