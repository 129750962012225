import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  ChargeableServiceCenter,
  ClaimPartyMatch,
  Contact,
  EmailInteraction,
  GetClaimResp,
  PaymentDetail,
} from '@xpo-ltl-2.0/sdk-claims';
import { ClaimEmailTemplateTypeCd } from '@xpo-ltl/sdk-common';
import { EmployeeName } from '@xpo-ltl/sdk-humanresource';
import { GetOdsShipmentResp, OdsShipment } from '@xpo-ltl/sdk-shipmentods';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmCancelData, LocalCommodity, SelectCommoditiesData } from '../../classes';
import { EmailsConfig } from '../../components/notes-emails/emails/emails-config';
import { ClaimHistoryListComponent } from '../../dialogs/claim-history/claim-history-list.component';
import { ConfirmCancelComponent } from '../../dialogs/confirm-cancel/confirm-cancel.component';
import { ContactsListComponent } from '../../dialogs/contacts-list/contacts-list.component';
import { DeclinationEmailComponent } from '../../dialogs/declination-email/declination-email.component';
import { EscalationEmailComponent } from '../../dialogs/escalation-email/escalation-email.component';
import { MatchPartiesComponent } from '../../dialogs/match-parties/match-parties.component';
import { PaymentHistoryComponent } from '../../dialogs/payment-history/payment-history.component';
import { ReassignClaimsComponent } from '../../dialogs/reassign-claims/reassign-claims.component';
import { RecycledProsComponent } from '../../dialogs/recycled-pros/recycled-pros.component';
import { SelectCommoditiesComponent } from '../../dialogs/select-commodities/select-commodities.component';
import { ServiceCenterChargeabilityComponent } from '../../dialogs/service-center-chargeability/service-center-chargeability.component';
import { DeclinationEmailResults, ClaimEditState } from '../../enums';
import { EscalationEmailResults } from '../../enums/escalation-email-results.enum';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { CurrencyPipe } from '@angular/common';
import { PrintClaimComponent } from '../../dialogs/print-claim/print-claim.component';
import { ClaimHistoryDialogInterface } from '../../interfaces/claim-history-dialog.interface';
import {
  RegistrationReassignClaimComponent,
  RegistrationReassignClaimResult,
} from '../../dialogs/registration-reassign-claim/registration-reassign-claim.component';

export interface EscalationEmailData {
  claim: GetClaimResp;
  shipper: string;
  consignee: string;
  totalPayoutAmount: number;
}

@Injectable({ providedIn: 'root' })
export class ClaimsDialogService {
  private shipmentDetailsDialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog) {}

  showCancelRegisteringClaimDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Cancel Registration?',
      '<p>If you cancel this claim all information you have entered will be lost.',
      'Continue Registering',
      'Cancel Registration'
    );
  }

  showCancelPendingApprovalDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Cancel Pending Approval?',
      '<p>If you choose to continue any unsaved changes will be lost. How do you wish to Proceed?</p>',
      'Go Back',
      'Cancel Approval'
    );
  }

  showCancelReviewDialog(): Observable<boolean> {
    return this.showConfirmCancelDialog(
      'Cancel Review?',
      '<p>If you choose to cancel reviewing any unsaved changes will be lost.',
      'Continue Editing',
      'Cancel Review'
    );
  }

  closeShipmentDetailsDialog() {
    if (this.shipmentDetailsDialogRef) {
      this.shipmentDetailsDialogRef.close();
    }
  }
  showShipmentDetailsDialog(shipment): Observable<void> {
    const subject = new Subject<void>();

    // this.shipmentDetailsDialogRef = this.dialog.open(ShipmentDetailsComponent, {
    //   maxWidth: '1200px',
    //   height: '98%',
    //   panelClass: 'shipment-details__dialog-panel',
    //   disableClose: true,
    //   hasBackdrop: false,
    //   data: {
    //     shipment: shipment,
    //   },
    // });
    // this.shipmentDetailsDialogRef.afterClosed().subscribe(() => {
    //   subject.next();
    //   subject.complete();
    //   this.shipmentDetailsDialogRef = null;
    // });

    return subject.asObservable();
  }

  showSelectCommoditiesDialog(commodities: LocalCommodity[]): Observable<LocalCommodity[]> {
    const subject = new Subject<LocalCommodity[]>();
    const data = new SelectCommoditiesData(
      'Select Shipment Commodities',
      'Cancel',
      'Add Selected To Claim',
      commodities
    );
    const dialogRef = this.dialog.open(SelectCommoditiesComponent, { data });

    dialogRef.afterClosed().subscribe((selectedCommodities: LocalCommodity[]) => {
      subject.next(selectedCommodities);
      subject.complete();
    });

    return subject.asObservable();
  }

  showMatchContactsDialog(contacts: Contact[]): Observable<Contact> {
    const subject = new Subject<Contact>();
    const data = { contacts: contacts };
    const dialogRef = this.dialog.open(ContactsListComponent, { data: data, disableClose: true });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((selectedContact: Contact) => {
        subject.next(selectedContact);
        subject.complete();
      });

    return subject.asObservable();
  }

  showMatchPartiesDialog(asEnteredParty, partyMatches: ClaimPartyMatch[]): Observable<any> {
    const subject = new Subject<any[]>();
    const data = { asEnteredParty: asEnteredParty, partyMatches: partyMatches };
    const dialogRef = this.dialog.open(MatchPartiesComponent, { data: data, disableClose: true });

    dialogRef.afterClosed().subscribe((selectedParty) => {
      subject.next(selectedParty);
      subject.complete();
    });

    return subject.asObservable();
  }

  showConfirmCancelDialog(
    title: string,
    message: string,
    cancelButtonText: string,
    confirmButtonText: string
  ): Observable<boolean> {
    const subject = new Subject<boolean>();

    const data = new ConfirmCancelData(title, message, cancelButtonText, confirmButtonText);
    const dialogRef = this.dialog.open(ConfirmCancelComponent, { data: data, disableClose: true });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((results: boolean) => {
        subject.next(results);
        subject.complete();
      });

    return subject.asObservable();
  }

  showRecycledProsDialog(shipments: OdsShipment[]): Observable<string> {
    const subject = new Subject<any>();
    const dialogRef = this.dialog.open(RecycledProsComponent, {
      data: shipments,
      width: '1000px',
      height: '540px',
      panelClass: 'recycled-pros__dialog-panel',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((shipmentInstId: string) => {
      subject.next(shipmentInstId);
      subject.complete();
    });

    return subject.asObservable();
  }

  showPaymentHistoryDialog(payments: PaymentDetail[]): Observable<void> {
    const subject = new Subject<void>();

    const dialogRef = this.dialog.open(PaymentHistoryComponent, {
      width: '960px',
      height: '420px',
      panelClass: 'payment-history-panel',
      disableClose: true,
      hasBackdrop: true,
      data: payments,
    });
    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });

    return subject.asObservable();
  }

  showServiceCenterChargeabilityDialog(
    shipment: GetOdsShipmentResp,
    claim: GetClaimResp,
    chargeableServiceCenters: ChargeableServiceCenter[],
    loadAtSics: string[],
    claimEditState: ClaimEditState
  ): Observable<{ serviceCenters: ChargeableServiceCenter[]; note: string }> {
    const subject = new Subject<{ serviceCenters: ChargeableServiceCenter[]; note: string }>();

    const dialogRef = this.dialog.open(ServiceCenterChargeabilityComponent, {
      data: { shipment, claim, chargeableServiceCenters, loadAtSics, claimEditState },
    });
    dialogRef.afterClosed().subscribe((results: { serviceCenters: ChargeableServiceCenter[]; note: string }) => {
      subject.next(results);
      subject.complete();
    });

    return subject.asObservable();
  }

  showClaimHistoryDialog(data: ClaimHistoryDialogInterface) {
    const subject = new Subject();
    const dialogRef = this.dialog.open(ClaimHistoryListComponent, {
      maxWidth: '90vw',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });

    return subject.asObservable();
  }
  showPrintClaimDialog(data: Observable<GetClaimResp>) {
    const subject = new Subject();
    const dialogRef = this.dialog.open(PrintClaimComponent, { data: data });

    dialogRef.afterClosed().subscribe(() => {
      subject.next(true);
      subject.complete();
    });

    return subject.asObservable();
  }

  showReassignClaimsDialog(): Observable<EmployeeName> {
    const subject = new Subject<EmployeeName>();

    const dialogRef = this.dialog.open(ReassignClaimsComponent, {
      disableClose: true,
      data: undefined,
    });
    dialogRef.afterClosed().subscribe((results: EmployeeName) => {
      subject.next(results);
    });

    return subject.asObservable();
  }

  showRegistrationReassignClaimDialog(names: EmployeeName[]): Observable<RegistrationReassignClaimResult> {
    const subject = new Subject<RegistrationReassignClaimResult>();
    const dialogRef = this.dialog.open(RegistrationReassignClaimComponent, {
      disableClose: true,
      data: {
        employeeNames: names,
      },
    });
    dialogRef.afterClosed().subscribe(
      (results: RegistrationReassignClaimResult): void => {
        subject.next(results);
        subject.complete();
      }
    );
    return subject.asObservable();
  }

  showDeclinationEmailDialog(
    recipient: string,
    claim: GetClaimResp,
    claimsRegistrationFormGroup: UntypedFormGroup,
    dmsDocListObs: Observable<DocumentSearch[]>,
    downloadDMSAttachmentFn: Function
  ): Observable<DeclinationEmailResults> {
    const claimantRefNbr = _.get(claim, 'claim.claimantRefNbr', undefined);
    const subject = new Subject<DeclinationEmailResults>();
    const dialogRef = this.dialog.open(DeclinationEmailComponent, {
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'declination-email-panel',
      data: {
        claim,
        claimsRegistrationFormGroup,
        dmsDocListObs,
        downloadDMSAttachmentFn,
        emailsConfig: {
          composeOnly: true,
          recipient,
          // subject: `Claim Payment - Declined - ${claim.claim.claimId}`,
          subject: `XPO Logistics Claim: ${claim.claim.claimId} ${
            claimantRefNbr ? 'Your reference number: ' + claimantRefNbr : ''
          }`,
          useTemplate: ClaimEmailTemplateTypeCd.CLEAR_DR_DECLINATION,
        },
      },
    });
    dialogRef.afterClosed().subscribe((results: DeclinationEmailResults) => {
      subject.next(results);
      subject.complete();
    });

    return subject.asObservable();
  }

  showEscalationEmailDialog(
    emailData: EscalationEmailData,
    claimsRegistrationFormGroup: UntypedFormGroup,
    dmsDocListObs: Observable<DocumentSearch[]>,
    downloadDMSAttachmentFn: Function,
    currencyPipe: CurrencyPipe,
    existingEmail?: EmailInteraction
  ): Observable<EscalationEmailResults> {
    const subject = new Subject<EscalationEmailResults>();

    let emailsConfig: EmailsConfig = {
      composeOnly: true,
      hideRecipient: true,
      hideTemplate: true,
      markAsDraft: true,
    };

    if (existingEmail) {
      emailsConfig = {
        ...emailsConfig,
        subject: existingEmail.subjectTxt,
        recipient: existingEmail.recipientEmailAddressTxt,
        cc: existingEmail.ccEmailAddressTxt,
        email: existingEmail,
      };
    } else {
      const claimId = _.get(emailData, 'claim.claim.claimId', '');
      const payeeName = _.get(emailData, 'claim.payee.name1', '');
      const payoutAmount = _.get(emailData, 'totalPayoutAmount', 0);

      emailsConfig = {
        ...emailsConfig,
        subject: `Claim Escalation: ${claimId} - ${payeeName} - ${currencyPipe.transform(payoutAmount)}`,
        useTemplate: ClaimEmailTemplateTypeCd.ESCALATION_APPROVAL,
        templateData: {
          shipper: _.get(emailData, 'shipper'),
          consignee: _.get(emailData, 'consignee'),
          totalPayoutAmount: _.get(emailData, 'totalPayoutAmount'),
        },
      };
    }

    const dialogRef = this.dialog.open(EscalationEmailComponent, {
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'escalation-email-panel',
      data: {
        claim: _.get(emailData, 'claim'),
        claimsRegistrationFormGroup,
        dmsDocListObs,
        downloadDMSAttachmentFn,
        emailsConfig,
      },
    });
    dialogRef.afterClosed().subscribe((results: EscalationEmailResults) => {
      subject.next(results);
      subject.complete();
    });

    return subject.asObservable();
  }
}
