import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { XpoAngularUtilsService } from '../../core/services/xpo-angular-utils.service';
import { xpoBadCharactersValidator } from '../../core/validators/non-use-characters.validators';
import { ClaimParty, ClaimPartyMatch, ClaimsApiService, MatchClaimPartyRqst, Party } from '@xpo-ltl-2.0/sdk-claims';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RegistrationClaimantFormBuilder } from '../../components/registration/components/registration-claimant/registration-claimant.form-builder';
import { AddressEntryFormNames, CommonRegex } from '../../enums';
import { ClaimPartyMaxLengths } from 'src/app/enums/FormMaxLengths/claim-party-max-lengths.enum';

@Component({
  selector: 'app-match-parties',
  templateUrl: './match-parties.component.html',
  styleUrls: ['./match-parties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchPartiesComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public AddressEntryFormNames = AddressEntryFormNames;

  private dataSourceSubject: BehaviorSubject<MatTableDataSource<Party>> = new BehaviorSubject(undefined);
  public dataSource$ = this.dataSourceSubject.asObservable();

  public readonly columnsToDisplay = ['select', 'name1', 'name2', 'fullAddress', 'pctRelevance'];
  public selectedParty = undefined;

  private searchingSubject = new BehaviorSubject<boolean>(false);
  public searching$ = this.searchingSubject.asObservable();

  @ViewChildren(MatRadioButton) matchRadioButtons;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialogRef: MatDialogRef<MatchPartiesComponent>,
    private formBuilder: UntypedFormBuilder,
    private claimsService: ClaimsApiService,
    private xpoUtils: XpoAngularUtilsService
  ) {
    // TODO modify this after api in place
    this.dataSourceSubject.next(new MatTableDataSource<Party>(undefined));
  }

  ngOnInit() {
    this.createSearchForm();
  }

  ngOnDestroy(): void {
    this.dataSourceSubject.unsubscribe();
  }

  public selectRow(row: any, index: number) {
    this.selectedParty = row;
    const radioButton = <MatRadioButton>this.matchRadioButtons.toArray()[index];
    radioButton.checked = true;
  }

  private createSearchForm() {
    const address = _.get(this.data, 'asEnteredParty.Address');

    this.formGroup = this.formBuilder.group({
      [AddressEntryFormNames.Name]: [_.get(address, 'Name'), [Validators.required, xpoBadCharactersValidator(CommonRegex.NAME_FIELD_BAD_CHARACTERS)]],
      [AddressEntryFormNames.Attention]: [_.get(address, 'Attention')],
      [AddressEntryFormNames.Address1]: [_.get(address, 'Address1'), [Validators.required, xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS), Validators.maxLength(ClaimPartyMaxLengths.Address1)]],
      [AddressEntryFormNames.Address2]: [_.get(address, 'Address2'), [xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS), Validators.maxLength(ClaimPartyMaxLengths.Address2)]],
      [AddressEntryFormNames.City]: [_.get(address, 'City'), [Validators.required, Validators.maxLength(ClaimPartyMaxLengths.City), xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS)]],
      [AddressEntryFormNames.State]: [_.get(address, 'State'), [Validators.required, Validators.maxLength(ClaimPartyMaxLengths.State), xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS)]],
      [AddressEntryFormNames.Zip]: [_.get(address, 'Zip'), [Validators.required, xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS), Validators.maxLength(ClaimPartyMaxLengths.Postal)]],
    });

    const partyMatches = this.data && this.data.partyMatches ? this.data.partyMatches : undefined;
    if (partyMatches) {
      this.loadPartyMatches(partyMatches);
    }
  }

  public cancelClicked(): void {
    this.matDialogRef.close();
  }

  public handleMatchClicked(): void {
    this.matDialogRef.close(this.selectedParty);
  }

  public handleSearchClicked() {
    if (this.formGroup.valid) {
      this.selectedParty = undefined;
      this.dataSourceSubject.next(undefined);
      this.searchingSubject.next(true);

      const request = new MatchClaimPartyRqst();
      request.claimParty = this.toClaimParty();
      this.claimsService
        .matchClaimParty(request, { loadingOverlayEnabled: true, loadingOverlayMessage: 'Searching...' })
        .pipe(take(1))
        .subscribe(
          response => {
            if (response && response.matchedParties && !!response.matchedParties.length) {
              const parties = response.matchedParties.map(matchedParty => {
                const party = _.clone(matchedParty.party);
                const displayPostalCd = this.xpoUtils.concatenateZips(party.postalCd, party.postalCdExt);
                const displayAddr = !!_.toString(party.addr2).trim() ? `${party.addr1}, ${party.addr2}` : party.addr1;
                party['fullAddress'] = `${displayAddr}, ${party.cityName}, ${party.stateCd} ${displayPostalCd}`;
                party['pctRelevance'] = matchedParty.accuracyPercentage;
                return party;
              });
              this.dataSourceSubject.next(new MatTableDataSource(parties));
            }
            this.formGroup.markAsPristine();
          },
          error => {},
          () => {
            this.searchingSubject.next(false);
          }
        );
    }
  }

  public selectedListItem(party) {
    this.selectedParty = party;
  }

  private toClaimParty(): ClaimParty {
    const party = new ClaimParty();
    party.name1 = this.formGroup.get(AddressEntryFormNames.Name).value || undefined;
    party.name2 = this.formGroup.get(AddressEntryFormNames.Attention).value || '';
    party.addr1 = this.formGroup.get(AddressEntryFormNames.Address1).value || undefined;
    party.addr2 = this.formGroup.get(AddressEntryFormNames.Address2).value || '';
    party.cityName = this.formGroup.get(AddressEntryFormNames.City).value || undefined;
    party.stateCd = this.formGroup.get(AddressEntryFormNames.State).value || undefined;
    party.postalCd = this.formGroup.get(AddressEntryFormNames.Zip).value || undefined;
    party.countryCd = 'US';
    return party;
  }

  private loadPartyMatches(partyMatches: ClaimPartyMatch[]) {
    const parties = partyMatches.map(partyMatch => {
      const party = _.clone(partyMatch.party);
      const displayAddr = !!_.toString(party.addr2).trim() ? `${party.addr1}, ${party.addr2}` : party.addr1;

      party['fullAddress'] = (_.get(party, 'postalCdExt')
      ? _.get(party, 'postalCdExt', '').trim()
      : '')
        ? `${displayAddr}, ${party.cityName}, ${party.stateCd} ${party.postalCd}-${party.postalCdExt}`
        : `${displayAddr}, ${party.cityName}, ${party.stateCd} ${party.postalCd}`;
      party['pctRelevance'] = partyMatch.accuracyPercentage;
      return party;
    });
    this.dataSourceSubject.next(new MatTableDataSource(parties));
  }
}
