import { Injectable, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, Subscriber, of } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {
  ClaimsApiService,
  GetClaimHistoryByEventIdPath,
  GetClaimHistoryByEventIdResp as ClaimHistoryResp,
  ClaimSnapshot,
  InterfaceEmployee,
  PartySnapshot,
  CommoditySnapshot,
  RelatedShipmentSnapshot,
  RebuttalSnapshot,
  PaymentSnapshot,
  ContactSnapshot,
  Commodity,
  PaymentDetail,
  ListClaimEventsPath,
  ListClaimEventsResp,
  BusinessEvent,
  GetClaimHistoryByEventIdResp,
} from '@xpo-ltl-2.0/sdk-claims';
import { AppConstantsService } from './app-constants.service';
import { NavigationBarLabelType, UnitOfWeightMeasureType } from '../enums';
import { ClaimHistoryAction } from '../enums/claim-history-action';
import { UnitConversion } from '../classes';
import { CommodityClaimTypeCd, ActionCd } from '@xpo-ltl/sdk-common';
import { NotificationService } from '@xpo-ltl/data-api';
import { take, map, publishReplay, refCount } from 'rxjs/operators';
import { ClaimEventTypeCdPipe } from '../pipes/claim-event-type-cd.pipe';
import { Moment } from 'moment';
import { RebuttalStatusInternalPipe } from '../pipes/rebuttal-status-internal.pipe';
import { EmployeeNamePipe } from '../pipes/employee-name.pipe';
import { ClaimInternalStatusCdPipe } from '../pipes/claim-internal-status-cd.pipe';
import { PaymentStatusInternalPipe } from '../pipes/payment-status-internal.pipe';


export interface CommodityTotals {
  totalWeight: number;
  totalLiability: number;
  totalClaimAmount: number;
}
export interface ClaimHistoryFields {
  claimSnapshot: ClaimSnapshot;
  claimantSnapshot: PartySnapshot;
  contactSnapshot: ContactSnapshot;
  payeeSnapshot: PartySnapshot;
  commoditySnapshots: CommoditySnapshot[];
  rebuttalSnapshots: RebuttalSnapshot[];
  relatedShipmentSnapshots: RelatedShipmentSnapshot[];
  paymentSnapshots: PaymentSnapshot[];
  paymentDetails: PaymentDetail[];
  commodityTotals: CommodityTotals;
  updatedByEmpl: InterfaceEmployee;
  tooltips: ClaimHistoryTooltips;
}
export interface ClaimHistoryTooltips {
  claim: {
    internalStatusCd: string;
    externalStatusCd: string;
    receivedDate: string;
    pickupDate: string;
    filingMethodCd: string;
    proNbr: string;
    claimantRefNbr: string;
    filedByEmployeeDtl: string;
    currAssigneeEmployeeDtl: string;
    examinedByEmployeeDtl: string;
    typeCd: string;
    pendingCustomerResponseInd: string;
    pendingSicResponseInd: string;
    pendingInspectionInd: string;
    inLitigationInd: string;
    rebuttalInd: string;
    decisionCategoryCd: string;
    decisionSubCategoryCd: string;
  };
  indicator: {
    damageTypeCd: string;
    invoiceProvidedInd: string;
    possibleLateFilingInd: string;
    potentialDrpInd: string;
    updatedBy: string;
  };
  claimant: {
    name1: string;
    addr1: string;
    addr2: string;
    cityName: string;
    stateCd: string;
    postalCd: string;
    countryCd: string;
  };
  payee: {
    name1: string;
    addr1: string;
    addr2: string;
    cityName: string;
    stateCd: string;
    postalCd: string;
    countryCd: string;
  };
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNbr: string;
    phoneExt: string;
    faxNbr: string;
    updatedBy: string;
  };
  rebuttal: {
    internalStatusCd: string;
    filingDate: string;
    claimedAmount: string;
    reasonCd: string;
    noteTxt: string;
  }[];
  commodity: {
    claimTypeCd: string;
    conditionTypeCd: string;
    piecesCount: string;
    descriptionTxt: string;
    modelOrPartNbr: string;
    commodityWeight: string;
    classCd: string;
    nmfcItemNbr: string;
    prcingAgreementDollarPerLbAmount: string;
    pricingAgreementDollarPerLbNoneInd: string;
    claimedAmount: string;
  }[];
  relatedShipment: {
    proNbr: string;
  }[];
  payment: {
    statusInternalCd: string;
    additionalPaymentReasonCd: string;
    emailNote: string;
    draftNote: string;
  }[];
  updatedBy: string;
}
export interface ClaimHistoryListEvent {
  date: Date;
  typCd: string;
  employeeName: string;
  eventId: number;
  events: BusinessEvent[];
}
@Injectable({ providedIn: 'root' })
export class ClaimHistoryService {
  // If set to true, will display additional output when setting observables
  public debug = false;

  public readonly NavigationBarLabelType = NavigationBarLabelType;
  // private eventPathParams = new GetClaimHistoryByEventIdPath();

  // Used to get the value path out of a diff in buildTooltips for add case
  // Match[1] = everything prior to Diff; Match[2] = everything after Diff
  private subDiffRegex = new RegExp(/([a-zA-Z]+?)Diff(\.(?:.+\.)*[a-zA-Z0-9]+)/);

  private historySubject = new BehaviorSubject<ClaimHistoryFields>(undefined);
  public history$ = this.historySubject.asObservable();
  public get history(): ClaimHistoryFields {
    return this.historySubject.value;
  }

  private cachedListEventsClaimId: string;
  private cachedListEvents: ClaimHistoryListEvent[];
  private cachedListEventsObservable: Observable<ClaimHistoryListEvent[]>;

  constructor(
    private claimsApiService: ClaimsApiService,
    public appConstantsService: AppConstantsService,
    private datePipe: DatePipe,
    private employeeNamePipe: EmployeeNamePipe,
    private rebuttalStatusInternalPipe: RebuttalStatusInternalPipe,
    private claimInternalStatusCdPipe: ClaimInternalStatusCdPipe,
    private paymentStatusInternalPipe: PaymentStatusInternalPipe,
    private claimEventTypeCdPipe: ClaimEventTypeCdPipe,
    private notificationService: NotificationService
  ) { }

  public getClaimHistoryById(eventId: number): Observable<GetClaimHistoryByEventIdResp> {
    if (!eventId) {
      throw new Error('Event ID is required');
    }
    const eventPathParams = new GetClaimHistoryByEventIdPath();
    eventPathParams.eventId = eventId;
    return this.claimsApiService
      .getClaimHistoryByEventId(eventPathParams);

  }

  /**
   * Calls the API and fills out history$ for depedent classes use
   *
   * @param eventId
   */
  public getClaimHistory(eventId: number): Observable<ClaimHistoryFields> {
    if (!eventId) {
      throw new Error('Event ID is required');
    }
    return new Observable<ClaimHistoryFields>(
      (observer: Subscriber<ClaimHistoryFields>): void => {
        const eventPathParams = new GetClaimHistoryByEventIdPath();
        eventPathParams.eventId = eventId;
        this.claimsApiService
          .getClaimHistoryByEventId(eventPathParams)
          .pipe(take(1))
          .subscribe(
            (response: ClaimHistoryResp) => {
              const bus = <ClaimHistoryFields>{};
              bus.claimSnapshot = _.get(response, 'claimSnapshot');
              bus.rebuttalSnapshots = _.reverse(_.get(response, 'rebuttalsSnapshot', [])); // Todo ensure index 0 is most recent
              bus.claimantSnapshot = _.get(response, 'claimantSnapshot');
              bus.contactSnapshot = _.get(response, 'claimantContactSnapshot');
              bus.payeeSnapshot = _.get(response, 'payeeSnapshot');
              bus.commoditySnapshots = _.get(response, 'commoditySnapshot', [] as CommoditySnapshot[]);
              bus.commodityTotals = this.calculateCommodityTotals(bus.commoditySnapshots);
              bus.relatedShipmentSnapshots = _.get(response, 'relatedShipmentsSnapshot', []);
              bus.paymentSnapshots = _.reverse(_.get(response, 'paymentsSnapshot', [])); // Todo ensure ndex 0 is most recent
              bus.paymentDetails = _.reverse(_.get(response, 'payments', [])); // Todo ensure index 0 is most recent
              bus.updatedByEmpl = _.get(response, 'updatedByEmployeeDtl');
              bus.tooltips = this.buildTooltips(response);

              if (this.debug) {
                // Debug helpers
                console.log('response', response);
                console.log('history', bus);
              }

              this.historySubject.next(bus);

              observer.next(this.history);
            },
            err => {
              console.log(err);
              this.notificationService.showSnackBarMessage('No history event found.', { durationInMillis: 5000, status: 'ERROR' });
              observer.error(undefined);
            },
            () => observer.complete()
          );
      }
    );
  }

  /**
   * Gets the claim history events from the api and stores them in the ClaimHistoryListEvent format
   * Also caches the last call to reduce api calls when reviewing history on a claim
   *
   * @param claimId the claim to get the claim history events from
   * @param ignoreCache disregard the cache, don't even set it
   */
  public getEvents(claimId: string, ignoreCache = false): Observable<ClaimHistoryListEvent[]> {
    if (!claimId) {
      // Must always have a claim Id
      throw new Error('Claim ID is required');
    }

    // If we're using the cache, check if what we want is what we have
    if (!ignoreCache) {
      if (this.cachedListEventsClaimId === claimId) {
        // Return currently stored events
        // or if undefined
        // Return the observable which will return the events (always tied with claimId)
        if (!!this.cachedListEvents) {
          return of(this.cachedListEvents);
        } else if (!!this.cachedListEventsObservable) {
          return this.cachedListEventsObservable;
        }
      }
      this.cachedListEventsClaimId = claimId; // Set the claimId so the observable can be cached
    }

    const eventPathParams = new ListClaimEventsPath();
    eventPathParams.claimId = claimId;
    this.cachedListEventsObservable = this.claimsApiService.listClaimEvents(eventPathParams).pipe(
      map(
        (response: ListClaimEventsResp): ClaimHistoryListEvent[] => {
          this.cachedListEventsObservable = undefined; // Since the response has come back, don't pass this anymore
          const events = response.claimEvents;
          const eventGroups = _.groupBy(events, 'eventId');
          const eventBus = [];
          _.each(eventGroups, eventGroup => {
            const lastEvent = _.last(eventGroup);
            const eventObject = <ClaimHistoryListEvent>{};
            eventObject.date = new Date(+lastEvent.auditInfo.createdTimestamp);
            eventObject.employeeName = this.employeeNamePipe.transform(lastEvent.createdByEmployeeDtl);
            eventObject.eventId = lastEvent.eventId;
            eventObject.typCd = _.reduce(eventGroup, (result, curGroup) => result + this.claimEventTypeCdPipe.transform(curGroup.typCd) + ', ', '').slice(0, -2);
            eventObject.events = eventGroup;
            eventBus.push(eventObject);
          });
          // If we're not ignoring, override the cache
          if (!ignoreCache) {
            this.cachedListEventsClaimId = claimId;
            this.cachedListEvents = eventBus;
          }
          return eventBus;
        }
      ),
      publishReplay(1), // Used for caching
      refCount() // Also used for caching
    );
    return this.cachedListEventsObservable;
  }

  /**
   * Clears the cached fields related to getClaimListEvents()
   * Useful to check if new events have been made
   */
  public clearEventsCache(): void {
    this.cachedListEventsClaimId = undefined;
    this.cachedListEventsObservable = undefined;
    this.cachedListEvents = undefined;
  }

  /**
   * For each field that claim history cares about, build the tooltip using the tooltip function
   *
   * @param claimHistoryResponse the response from the getClaimHistoryByEventId api
   */
  private buildTooltips(claimHistoryResponse: ClaimHistoryResp): ClaimHistoryTooltips {
    const {
      claimSnapshot,
      claimantSnapshot,
      claimantContactSnapshot,
      payeeSnapshot,
      updatedByEmployeeDtl,
      rebuttalsSnapshot,
      commoditySnapshot,
      relatedShipmentsSnapshot,
      paymentsSnapshot,
    } = claimHistoryResponse;

    const tooltips = <ClaimHistoryTooltips>{
      claim: {
        internalStatusCd: this.buildTooltip(claimSnapshot, 'claimDiff.internalStatusCd', this.claimInternalStatusCdPipe),
        externalStatusCd: this.buildTooltip(claimSnapshot, 'claimDiff.externalStatusCd', this.claimInternalStatusCdPipe),
        receivedDate: this.buildTooltip(claimSnapshot, 'claimDiff.receivedDate'),
        pickupDate: this.buildTooltip(claimSnapshot, 'claimDiff.pickupDate'),
        filingMethodCd: this.buildTooltip(claimSnapshot, 'claimDiff.filingMethodCd'),
        proNbr: this.buildTooltip(claimSnapshot, 'claimDiff.proNbr'),
        claimantRefNbr: this.buildTooltip(claimSnapshot, 'claimDiff.claimantRefNbr'),
        filedByEmployeeDtl: this.buildTooltip(claimSnapshot, 'claimDiff.filedByEmployeeDtl', this.employeeNamePipe),
        currAssigneeEmployeeDtl: this.buildTooltip(claimSnapshot, 'claimDiff.currAssigneeEmployeeDtl', this.employeeNamePipe),
        examinedByEmployeeDtl: this.buildTooltip(claimSnapshot, 'claimDiff.examinedByEmployeeDtl', this.employeeNamePipe),
        typeCd: this.buildTooltip(claimSnapshot, 'claimDiff.typeCd'),
        pendingCustomerResponseInd: this.buildTooltip(claimSnapshot, 'claimDiff.pendingCustomerResponseInd'),
        pendingSicResponseInd: this.buildTooltip(claimSnapshot, 'claimDiff.pendingSicResponseInd'),
        pendingInspectionInd: this.buildTooltip(claimSnapshot, 'claimDiff.pendingInspectionInd'),
        inLitigationInd: this.buildTooltip(claimSnapshot, 'claimDiff.inLitigationInd'),
        rebuttalInd: this.buildTooltip(claimSnapshot, 'claimDiff.rebuttalInd'),
        decisionCategoryCd: this.buildTooltip(claimSnapshot, 'claimDiff.decisionCategoryCd'),
        decisionSubCategoryCd: this.buildTooltip(claimSnapshot, 'claimDiff.decisionSubCategoryCd'),
      },
      rebuttal: _.map(_.reverse(rebuttalsSnapshot), snapshot => ({
        // Todo ensure 0 index is latest
        internalStatusCd: this.buildTooltip(snapshot, 'rebuttalDiff.internalStatusCd', this.rebuttalStatusInternalPipe),
        filingDate: this.buildTooltip(snapshot, 'rebuttalDiff.filingDate'),
        claimedAmount: this.buildTooltip(snapshot, 'rebuttalDiff.claimedAmount'),
        reasonCd: this.buildTooltip(snapshot, 'rebuttalDiff.reasonCd'),
        noteTxt: this.buildTooltip(snapshot, 'rebuttalDiff.noteTxt'),
      })),
      indicator: {
        damageTypeCd: this.buildTooltip(claimSnapshot, 'claimDiff.damageTypeCd'),
        invoiceProvidedInd: this.buildTooltip(claimSnapshot, 'claimDiff.invoiceProvidedInd'),
        possibleLateFilingInd: this.buildTooltip(claimSnapshot, 'claimDiff.possibleLateFilingInd'),
        potentialDrpInd: this.buildTooltip(claimSnapshot, 'claimDiff.potentialDrpInd'),
      },
      claimant: {
        name1: this.buildTooltip(claimantSnapshot, 'partyDiff.name1'),
        addr1: this.buildTooltip(claimantSnapshot, 'partyDiff.addr1'),
        addr2: this.buildTooltip(claimantSnapshot, 'partyDiff.addr2'),
        cityName: this.buildTooltip(claimantSnapshot, 'partyDiff.cityName'),
        stateCd: this.buildTooltip(claimantSnapshot, 'partyDiff.stateCd'),
        postalCd: this.buildTooltip(claimantSnapshot, 'partyDiff.postalCd'),
        countryCd: this.buildTooltip(claimantSnapshot, 'partyDiff.countryCd'),
      },
      contact: {
        firstName: this.buildTooltip(claimantContactSnapshot, 'contactDiff.firstName'),
        lastName: this.buildTooltip(claimantContactSnapshot, 'contactDiff.lastName'),
        email: this.buildTooltip(claimantContactSnapshot, 'contactDiff.email'),
        phoneNbr: this.buildTooltip(claimantContactSnapshot, 'contactDiff.phoneNbr'),
        phoneExt: this.buildTooltip(claimantContactSnapshot, 'contactDiff.phoneExt'),
        faxNbr: this.buildTooltip(claimantContactSnapshot, 'contactDiff.faxNbr'),
      },
      payee: {
        name1: this.buildTooltip(payeeSnapshot, 'partyDiff.name1'),
        addr1: this.buildTooltip(payeeSnapshot, 'partyDiff.addr1'),
        addr2: this.buildTooltip(payeeSnapshot, 'partyDiff.addr2'),
        cityName: this.buildTooltip(payeeSnapshot, 'partyDiff.cityName'),
        stateCd: this.buildTooltip(payeeSnapshot, 'partyDiff.stateCd'),
        postalCd: this.buildTooltip(payeeSnapshot, 'partyDiff.postalCd'),
        countryCd: this.buildTooltip(payeeSnapshot, 'partyDiff.countryCd'),
      },
      commodity: _.map(commoditySnapshot, snapshot => ({
        claimTypeCd: this.buildTooltip(snapshot, 'commodityDiff.claimTypeCd'),
        conditionTypeCd: this.buildTooltip(snapshot, 'commodityDiff.conditionTypeCd'),
        piecesCount: this.buildTooltip(snapshot, 'commodityDiff.piecesCount'),
        descriptionTxt: this.buildTooltip(snapshot, 'commodityDiff.descriptionTxt'),
        modelOrPartNbr: this.buildTooltip(snapshot, 'commodityDiff.modelOrPartNbr'),
        commodityWeight: this.buildTooltip(snapshot, 'commodityDiff.commodityWeight'),
        classCd: this.buildTooltip(snapshot, 'commodityDiff.classCd'),
        nmfcItemNbr: this.buildTooltip(snapshot, 'commodityDiff.nmfcItemNbr'),
        prcingAgreementDollarPerLbAmount: this.buildTooltip(snapshot, 'commodityDiff.prcingAgreementDollarPerLbAmount'),
        pricingAgreementDollarPerLbNoneInd: this.buildTooltip(snapshot, 'commodityDiff.pricingAgreementDollarPerLbNoneInd'),
        claimedAmount: this.buildTooltip(snapshot, 'commodityDiff.claimedAmount'),
      })),
      relatedShipment: _.map(relatedShipmentsSnapshot, snapshot => ({
        proNbr: this.buildTooltip(snapshot, 'relatedShipmentDiff.proNbr'),
      })),
      payment: _.map(_.reverse(paymentsSnapshot), snapshot => ({
        // Todo ensure 0 index is latest
        statusInternalCd: this.buildTooltip(snapshot, 'paymentDiff.statusInternalCd', this.paymentStatusInternalPipe),
        additionalPaymentReasonCd: this.buildTooltip(snapshot, 'paymentDiff.additionalPaymentReasonCd'),
        emailNote: this.buildTooltip(snapshot, 'paymentDiff.emailNote'),
        draftNote: this.buildTooltip(snapshot, 'paymentDiff.draftnote'),
      })),
      updatedBy: `Updated by: ${this.employeeNamePipe.transform(updatedByEmployeeDtl) || ''}, ${this.datePipe.transform(_.get(claimSnapshot, 'historyCreatedTimestamp'), 'MM/dd/yyyy, HH:mm a')}`,
    };

    return tooltips;
  }

  /**
   * Builds the string to be displayed on tooltips showing the previous value
   *
   * @param path path to the previous value from the ClaimHitoryFields
   */
  private buildTooltip(snapshot: object, path: string, pipe?: PipeTransform): string {
    const preValue = _.get(snapshot, path);
    let output: string;
    if (!preValue) {
      // If there previously was no value
      // And the snapshot is currently set to add
      const action = _.get(snapshot, 'historyActionCd');
      if (action === (ClaimHistoryAction.Add as string)) {
        // Check to see if there are values here, because they weren't here previously
        const matches = this.subDiffRegex.exec(path);
        if (!!_.get(snapshot, matches[1] + matches[2], false)) {
          // Report them as added
          return 'Added';
        }
      }
      return undefined;
    } else if (preValue === 'true') {
      output = 'Checked';
    } else if (preValue === 'false') {
      output = 'Unchecked';
    } else if (!!pipe) {
      output = pipe.transform(preValue);
    }
    return `Previous: ${output}`;
  }

  /**
   * Constructs the CommodityTotals object through helper methods given the list of commoditySnapshots
   *
   * @param snapshots array of all the commodity snapshots off the API response
   */
  private calculateCommodityTotals(snapshots: CommoditySnapshot[]): CommodityTotals {
    return <CommodityTotals>{
      totalWeight: this.calculateTotalWeight(snapshots),
      totalLiability: this.calculateTotalLiability(snapshots),
      totalClaimAmount: this.calculateTotalClaimAmount(snapshots),
    };
  }

  /**
   * For each commodity, if the commodity isn't going to be deleted, if not, total its weight after converting it to lbs
   *
   * @param snapshots array of all the commodity snapshots off the API response
   */
  private calculateTotalWeight(snapshots: CommoditySnapshot[]): number {
    return snapshots.reduce((total: number, snapshot: CommoditySnapshot): number => {
      const commodity = snapshot.commodity; // Alias
      // If not deleting, get the weight
      let weight = snapshot.commodity.listActionCd !== ActionCd.DELETE && !!commodity.commodityWeight ? commodity.commodityWeight : 0;
      // If the weight is more than zero, and in KGS, convert it to lbs
      if (commodity.commodityWeightUnitOfMeasure === UnitOfWeightMeasureType.KGS && weight > 0) {
        weight = UnitConversion.convert(UnitOfWeightMeasureType.KGS, UnitOfWeightMeasureType.LBS, weight);
      }
      return total + weight; // Sum
    }, 0);
  }

  /**
   * Calculates the liability from a single commodity
   *
   * @param commodity a single commodity from a commodity API
   */
  public calculateLiability(commodity: Commodity): number {
    if (commodity.prcingAgreementDollarPerLbAmount === 0) {
      return +commodity.claimedAmount;
    } else if (commodity.claimTypeCd === CommodityClaimTypeCd.FREIGHT) {
      return +commodity.prcingAgreementDollarPerLbAmount;
    }
    return +commodity.commodityWeight * +commodity.prcingAgreementDollarPerLbAmount;
  }

  /**
   * For each commodity, if the commodity isn't going to be deleted, sum it's total liability via calculateLiability()
   *
   * @param snapshots array of all the commodity snapshots off the API response
   */
  private calculateTotalLiability(snapshots: CommoditySnapshot[]): number {
    const totalLiability = snapshots.reduce(
      (total: number, snapshot: CommoditySnapshot): number => total + (snapshot.commodity.listActionCd !== ActionCd.DELETE ? this.calculateLiability(snapshot.commodity) : 0),
      0
    );
    return UnitConversion.round(totalLiability);
  }

  /**
   * For each commodity, if the commodity isn't going to be deleted, sum the total claimedAmount
   *
   * @param snapshots array of all the commodity snapshots off the API reponse
   */
  private calculateTotalClaimAmount(snapshots: CommoditySnapshot[]): number {
    return snapshots.reduce((total: number, snapshot: CommoditySnapshot): number => (snapshot.commodity.listActionCd !== ActionCd.DELETE ? total + snapshot.commodity.claimedAmount : 0), 0);
  }

  /**
   * Returns the date on which the migration has taken place
   */
  public getMigrationDate(): Moment {
    return moment.tz('2019-05-01 23:59', 'America/Los_Angeles'); // May 1 2019
  }
}
