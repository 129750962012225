import * as _ from 'lodash';

/**
 * Helper for enums
 */
export class EnumHelper<T> {
  protected _values: string[] = [];
  protected _keys: string[] = [];
  private enumList: T;

  public get values(): string[] {
    return this._values;
  }

  public get keys(): string[] {
    return this._keys;
  }

  constructor(enumList: T) {
    _.each(enumList as Object, (val, key) => {
      this._values.push(val as any);
      this._keys.push(key);
    });

    this.enumList = enumList;
  }

  public toEnum(key: string): T {
    return (_.find(this.values, value => this.enumList[key] === value) as any) as T;
  }

  public getKey(value: string): keyof T {
    return _.find(this.keys, key => this.enumList[key] === value) as keyof T;
  }
}
