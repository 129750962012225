import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Note } from '@xpo-ltl-2.0/sdk-claims';
import { ClaimNoteVisibilityCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notes-detail',
  templateUrl: './notes-detail.component.html',
  styleUrls: ['./notes-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'notes-detail',
  },
})
export class NotesDetailComponent implements OnInit {
  ClaimNoteVisibilityCd = ClaimNoteVisibilityCd;

  @Input()
  note: Note;

  private authorNameSubject = new BehaviorSubject<string>(undefined);
  authorName$ = this.authorNameSubject.asObservable();

  constructor() {}

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.authorNameSubject.next(
      `${_.get(this.note, 'createByEmployeeDtl.firstName', '')} ${_.get(
        this.note,
        'createByEmployeeDtl.middleName',
        ''
      )} ${_.get(this.note, 'createByEmployeeDtl.lastName', '')} - ${_.get(
        this.note,
        'createByEmployeeDtl.jobDescription',
        ''
      )}`
    );
  }
}
