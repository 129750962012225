import { AbstractControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UpsertClaimRqst, AsEnteredParty } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd, ClaimPartyTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ClaimantPayeeFormNames, ClaimsRegistrationFormNames } from '../../../../enums/FormControlNames';
import { ClaimsRegistrationService } from '../../../../services/claims-registration/claims-registration.service';
import { AddressEntryComponent } from '../address-entry/address-entry.component';
import { AddressEntryFormBuilder } from '../address-entry/address-entry.form-builder';
import { AsEnteredPartyIndex } from '../../../../enums';
import { XpoAngularUtilsService } from '../../../../core/services/xpo-angular-utils.service';
import { NO_MATCH_CLAIM_PARTY_ID } from '../../../../classes/noMatchClaim';

export class RegistrationPayeeFormBuilder {
  private static defaultValues;

  static create(
    formBuilder: UntypedFormBuilder,
    claimsRegistrationService: ClaimsRegistrationService,
    utils: XpoAngularUtilsService
  ): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [ClaimantPayeeFormNames.SameAs]: [undefined],
        [ClaimantPayeeFormNames.ListActionCd]: [ActionCd.NO_ACTION],
        [ClaimantPayeeFormNames.MatchedClaimPartyId]: [undefined, Validators.required],
      });

      formGroup.addControl(ClaimantPayeeFormNames.Address, AddressEntryFormBuilder.create(formBuilder));
      formGroup.addControl(
        ClaimantPayeeFormNames.AsEnteredAddress,
        AddressEntryFormBuilder.createAsEnteredPartyFormGroup(formBuilder)
      );

      RegistrationPayeeFormBuilder.defaultValues = formGroup.getRawValue();

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.setValue(RegistrationPayeeFormBuilder.defaultValues);
    }
  }

  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any): UpsertClaimRqst {
    const getValue = (field: string, defaultValue: any = undefined) =>
      _.get(rawFormGroup, `${ClaimsRegistrationFormNames.AsEnteredPayee}.${field}`, defaultValue);
    const sameAsSelected = !!getValue(ClaimantPayeeFormNames.SameAs);

    // Store the address in the AsEntered for Payee
    const addressGroup = getValue(ClaimantPayeeFormNames.Address);
    const address = AddressEntryComponent.toAddressDataFromFormData(addressGroup);

    const asEnteredAddressGroup = getValue(ClaimantPayeeFormNames.AsEnteredAddress);
    const asEnteredAddress = AddressEntryComponent.toAsEnteredAddressDataFromFormData(asEnteredAddressGroup);
    let enteredPartyIndex: number = _.findIndex(
      upsertClaimRqst.asEnteredParty,
      (party: AsEnteredParty) => _.get(party, 'partyTypeCd') === ClaimPartyTypeCd.PAYEE
    );
    enteredPartyIndex = enteredPartyIndex === -1 ? AsEnteredPartyIndex.Payee : enteredPartyIndex;
    const asEnteredParty = AddressEntryFormBuilder.updateAsEnteredPartyWithAddress(
      asEnteredAddress,
      upsertClaimRqst.asEnteredParty[enteredPartyIndex]
    );

    asEnteredParty.partyTypeCd = ClaimPartyTypeCd.PAYEE;
    upsertClaimRqst.asEnteredParty[enteredPartyIndex] = asEnteredParty;

    // If the Payee is marked as 'Same as Claimaint', set the payeeSameAsClaimantInd to true on the CreateClaimRqst and leave
    // the PayeeParty empty (the Payee will be set to either the matched Claimaint or the newly created AsEnteredClaimant within the API).
    if (sameAsSelected) {
      upsertClaimRqst.claim.payeeSameAsClaimantInd = true;
    } else {
      upsertClaimRqst.claim.payeeSameAsClaimantInd = false;
      // Set the Payee Party.  If no claimPartyId supplied, it will create a new party from this data
      const partyId = getValue(ClaimantPayeeFormNames.MatchedClaimPartyId);
      const claimPartyId = partyId !== NO_MATCH_CLAIM_PARTY_ID ? partyId : undefined;
      upsertClaimRqst.payee = AddressEntryFormBuilder.addressToParty(address, claimPartyId);

      upsertClaimRqst.payee.autoAprvlExcludeInd = false;
      upsertClaimRqst.payee.doNotMatchInd = false;
    }

    return upsertClaimRqst;
  }
}
