import { AbstractControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { UpsertClaimRqst, AsEnteredParty } from '@xpo-ltl-2.0/sdk-claims';
import { ActionCd, ClaimantSameAsCd, ClaimPartyTypeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import {
  ClaimantCopyFrom,
  ClaimantPayeeFormNames,
  ClaimsRegistrationFormNames,
  CommonRegex,
  AsEnteredPartyIndex,
} from '../../../../enums';
import { AddressEntryComponent } from '../address-entry/address-entry.component';
import { AddressEntryFormBuilder } from '../address-entry/address-entry.form-builder';
import { RegistrationContactFormBuilder } from '../registration-contact/registration-contact.form-builder';
import { xpoBadCharactersValidator } from '../../../../core/validators/non-use-characters.validators';
import { NO_MATCH_CLAIM_PARTY_ID } from '../../../../classes/noMatchClaim';
import { ClaimPartyMaxLengths } from 'src/app/enums/FormMaxLengths/claim-party-max-lengths.enum';

export class RegistrationClaimantFormBuilder {
  private static defaultValues;

  static create(formBuilder: UntypedFormBuilder): Observable<AbstractControl> {
    return new Observable((observer) => {
      const formGroup = formBuilder.group({
        [ClaimantPayeeFormNames.SameAs]: [''],
        [ClaimantPayeeFormNames.OnBehalfOfSelection]: [''],
        [ClaimantPayeeFormNames.OnBehalf]: [
          '',
          [
            Validators.maxLength(ClaimPartyMaxLengths.OnBehalfOf),
            xpoBadCharactersValidator(CommonRegex.BAD_CHARACTERS),
          ],
        ],
        [ClaimantPayeeFormNames.ListActionCd]: [ActionCd.NO_ACTION], // no save on readonly claim
        [ClaimantPayeeFormNames.MatchedClaimPartyId]: [undefined, Validators.required],
      });
      formGroup.addControl(
        ClaimantPayeeFormNames.AsEnteredAddress,
        AddressEntryFormBuilder.createAsEnteredPartyFormGroup(formBuilder)
      );
      formGroup.addControl(ClaimantPayeeFormNames.Address, AddressEntryFormBuilder.create(formBuilder));
      formGroup.addControl(ClaimantPayeeFormNames.Contact, RegistrationContactFormBuilder.create(formBuilder));

      RegistrationClaimantFormBuilder.defaultValues = formGroup.getRawValue();

      observer.next(formGroup);
      observer.complete();
    });
  }

  static setDefaultValues(formGroup: UntypedFormGroup): void {
    if (formGroup) {
      formGroup.get(ClaimantPayeeFormNames.ListActionCd).setValue(ActionCd.ADD);
    }
  }

  // return the ClaimantSameAsCd value for the passed ClaimantCopyFrom
  static toClaimantSameAsCd(sameAs: ClaimantCopyFrom): ClaimantSameAsCd {
    switch (sameAs) {
      case ClaimantCopyFrom.BILL_TO:
        return ClaimantSameAsCd.BILL_TO;
      case ClaimantCopyFrom.CONSIGNEE:
        return ClaimantSameAsCd.CONSIGNEE;
      case ClaimantCopyFrom.SHIPPER:
        return ClaimantSameAsCd.SHIPPER;
      default:
        return undefined;
    }
  }

  static transform(upsertClaimRqst: UpsertClaimRqst, rawFormGroup: any): UpsertClaimRqst {
    const getValue = (field: string, defaultValue: any = undefined) =>
      _.get(rawFormGroup, `${ClaimsRegistrationFormNames.AsEnteredClaimant}.${field}`, defaultValue);

    // Is the Claimant the SameAs an Party in the shipment?
    const sameAsCd = getValue(ClaimantPayeeFormNames.SameAs);
    upsertClaimRqst.claim.claimantSameAsCd = RegistrationClaimantFormBuilder.toClaimantSameAsCd(sameAsCd);

    // Update address
    const addressGroup = getValue(ClaimantPayeeFormNames.Address);
    const address = AddressEntryComponent.toAddressDataFromFormData(addressGroup);

    const asEnteredAddressGroup = getValue(ClaimantPayeeFormNames.AsEnteredAddress);
    const asEnteredAddress = AddressEntryComponent.toAsEnteredAddressDataFromFormData(asEnteredAddressGroup);
    let enteredPartyIndex: number = _.findIndex(
      upsertClaimRqst.asEnteredParty,
      (party: AsEnteredParty) => _.get(party, 'partyTypeCd') === ClaimPartyTypeCd.CLAIMANT
    );
    enteredPartyIndex = enteredPartyIndex === -1 ? AsEnteredPartyIndex.Claimant : enteredPartyIndex;
    const asEnteredParty = AddressEntryFormBuilder.updateAsEnteredPartyWithAddress(
      asEnteredAddress,
      upsertClaimRqst.asEnteredParty[enteredPartyIndex]
    );

    asEnteredParty.partyTypeCd = ClaimPartyTypeCd.CLAIMANT;
    asEnteredParty.onBehalfOfName = getValue(ClaimantPayeeFormNames.OnBehalf);
    upsertClaimRqst.asEnteredParty[enteredPartyIndex] = asEnteredParty;

    // Set the Claimant Party.  If no claimPartyId supplied, it will create a new party from this data
    const partyId = getValue(ClaimantPayeeFormNames.MatchedClaimPartyId);
    const claimPartyId = partyId !== NO_MATCH_CLAIM_PARTY_ID ? partyId : undefined;
    upsertClaimRqst.claimant = AddressEntryFormBuilder.addressToParty(address, claimPartyId);

    upsertClaimRqst.claimant.autoAprvlExcludeInd = false;
    upsertClaimRqst.claimant.doNotMatchInd = false;

    upsertClaimRqst = RegistrationContactFormBuilder.transform(upsertClaimRqst, rawFormGroup);

    return upsertClaimRqst;
  }
}
